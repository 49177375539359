// NewTicketModal.js
import React from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';

const NewTicketModal = ({ open, onClose }) => (
  <Modal open={open} onClose={onClose}>
    <Box
      bgcolor="background.paper"
      p={3}
      borderRadius={2}
      width={400}
      mx="auto"
      mt="10vh"
      sx={{ outline: 'none' }}
    >
      <Typography variant="h6" color="text.primary" mb={2}>
        New Ticket
      </Typography>
      <TextField fullWidth label="Customer Email" variant="outlined" margin="dense" />
      <TextField fullWidth label="Ticket Type" variant="outlined" margin="dense" />
      <TextField fullWidth label="Priority Status" variant="outlined" margin="dense" />
      <TextField fullWidth label="Ticket Body" variant="outlined" margin="dense" multiline rows={4} />
      <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }} onClick={onClose}>
        Create Ticket
      </Button>
    </Box>
  </Modal>
);

export default NewTicketModal;
