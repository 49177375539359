import React, { useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import axios from 'axios';
import Layout from './Layout';
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Container,
  Grid,
  TextField,
  Typography,
  IconButton,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ImageList,
  ImageListItem,
  Tooltip,
  alpha,
  Chip,
  Fade,
  Zoom,
  Stack
} from '@mui/material';

import {
  Save as SaveIcon,
  Delete as DeleteIcon,
  PhotoCamera as PhotoCameraIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Add as AddIcon,
  Inventory as InventoryIcon
} from '@mui/icons-material';

const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  padding: theme.spacing(4),
  background: `linear-gradient(135deg, ${alpha('#0A192F', 0.97)} 0%, ${alpha('#112240', 0.97)} 100%)`,
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    backgroundImage: 'radial-gradient(circle at 50% 0, rgba(249, 115, 22, 0.15), transparent 75%)',
    pointerEvents: 'none',
  }
}));

const ProductCard = styled(Card)(({ theme }) => ({
  backgroundColor: alpha('#112240', 0.8),
  backdropFilter: 'blur(12px)',
  borderRadius: 20,
  border: '1px solid rgba(255, 255, 255, 0.08)',
  transition: 'all 0.3s ease-in-out',
  overflow: 'visible',
  position: 'relative',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: `0 20px 40px ${alpha('#000', 0.3)}`,
    '& .image-overlay': {
      opacity: 1
    }
  }
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 240,
  borderRadius: '20px 20px 0 0',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)',
    opacity: 0.5,
    transition: 'opacity 0.3s ease-in-out',
    zIndex: 1
  }
}));

const ImageOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  zIndex: 2,
  cursor: 'pointer'
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 12,
    backgroundColor: alpha(theme.palette.background.paper, 0.6),
    '& fieldset': {
      borderColor: alpha(theme.palette.primary.main, 0.3),
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    }
  }
}));

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState({});
  const [editValues, setEditValues] = useState({});
  const [selectedImages, setSelectedImages] = useState({});
  const [uploading, setUploading] = useState({});
  const [deleteDialog, setDeleteDialog] = useState({ open: false, productId: null });
  const [imagePreview, setImagePreview] = useState({ open: false, images: [] });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const token = new URLSearchParams(window.location.search).get('token');
        const sellerId = token ? jwtDecode(token).user.id : '';
        const response = await axios.get(`https://dojo.stratusport.co/torki/marketplace/produtos.php?seller_id=${sellerId}`);
        setProducts(response.data);
      } catch (error) {
        console.error('Erro ao carregar produtos:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  const handleEditToggle = (productId) => {
    setEditMode(prev => ({
      ...prev,
      [productId]: !prev[productId]
    }));
    if (!editValues[productId]) {
      const product = products.find(p => p.product_id === productId);
      setEditValues(prev => ({
        ...prev,
        [productId]: {
          name: product.name,
          description: product.description,
          price: product.price
        }
      }));
    }
  };

  const handleFieldChange = (productId, field, value) => {
    setEditValues(prev => ({
      ...prev,
      [productId]: {
        ...prev[productId],
        [field]: value
      }
    }));
  };

  const handleSave = async (productId) => {
    try {
      await axios.put(`https://dojo.stratusport.co/torki/marketplace/produtos.php`, {
        product_id: productId,
        ...editValues[productId]
      });
      
      setProducts(prevProducts => prevProducts.map(product => 
        product.product_id === productId 
          ? { ...product, ...editValues[productId] }
          : product
      ));
      
      handleEditToggle(productId);
    } catch (error) {
      console.error('Erro ao salvar alterações:', error);
    }
  };

  const handleDelete = async (productId) => {
    try {
      await axios.delete(`https://dojo.stratusport.co/torki/marketplace/produtos.php`, {
        data: { id: productId }
      });
      
      setProducts(prevProducts => 
        prevProducts.filter(product => product.product_id !== productId)
      );
      
      setDeleteDialog({ open: false, productId: null });
    } catch (error) {
      console.error('Erro ao excluir produto:', error);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress sx={{ color: 'primary.main' }} />
      </Box>
    );
  }

  const handleImageUpload = async (productId, file) => {
    if (file) {
      try {
        const token = new URLSearchParams(window.location.search).get('token');
        console.log('Starting image upload for product:', productId); // Log productId
  
        // Converte a imagem para base64 e redimensiona se necessário
        const base64Image = await resizeImageToBase64(file);
  
        console.log('Product ID:', productId);
        console.log('Base64 image size (characters):', base64Image.length); // Tamanho do string base64
  
        if (!selectedImages[productId]) selectedImages[productId] = [];
  
        if (selectedImages[productId].length < 3) {
          setSelectedImages(prev => ({
            ...prev,
            [productId]: [...(prev[productId] || []), base64Image]
          }));
  
          // Envia a imagem base64 para o servidor
          const response = await axios.post(
            'https://dojo.stratusport.co/torki/marketplace/upload_image_base64.php',
            { product_id: productId, image: base64Image },
            { headers: { 'Content-Type': 'application/json' } } // Define Content-Type explicitamente
          );
  
          console.log('Upload response:', response.data); // Verifica a resposta do servidor
  
          // Atualiza a lista de produtos após upload bem-sucedido
          const updatedProducts = await axios.get(
            `https://dojo.stratusport.co/torki/marketplace/produtos.php?seller_id=${jwtDecode(token).user.id}`
          );
          setProducts(updatedProducts.data);
        } else {
          alert("Limite de 3 imagens por produto atingido.");
        }
      } catch (error) {
        console.error('Erro ao fazer upload da imagem:', error);
      }
    }
  };
  
  const resizeImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
  
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_WIDTH = 800; // Limita a largura máxima para 800px
          const scaleSize = MAX_WIDTH / img.width;
          canvas.width = MAX_WIDTH;
          canvas.height = img.height * scaleSize;
  
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
          const base64Image = canvas.toDataURL("image/jpeg", 0.5); // Qualidade 50%
          resolve(base64Image);
        };
        img.onerror = (err) => reject(err);
      };
      reader.onerror = (err) => reject(err);
    });
  };
  
  
  

  return (
    <Layout>
    <StyledContainer maxWidth="xl">
      <Fade in timeout={800}>
        <Box>
          <Box sx={{ mb: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <InventoryIcon sx={{ color: 'primary.main', fontSize: 40 }} />
              <Typography variant="h4" sx={{ color: '#fff', fontWeight: 600 }}>
                Seus Produtos
              </Typography>
            </Stack>
            
          </Box>

          <Grid container spacing={4}>
            {products.map((product) => (
              <Grid item xs={12} md={6} lg={4} key={product.product_id}>
                <Zoom in timeout={500}>
                  <ProductCard elevation={0}>
                    <ImageContainer>
                      <Box
                        component="img"
                        src={product.images?.[0]?.url || '/api/placeholder/400/320'}
                        alt={product.name}
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover'
                        }}
                      />
                      <ImageOverlay
                        className="image-overlay"
                        onClick={() => setImagePreview({ 
                          open: true, 
                          images: product.images?.map(img => img.url) || [] 
                        })}
                      >
                        <Stack spacing={2} alignItems="center">
                          <PhotoCameraIcon sx={{ fontSize: 40, color: 'primary.main' }} />
                          <Typography variant="body2" sx={{ color: '#fff' }}>
                            Ver todas as fotos
                          </Typography>
                        </Stack>
                      </ImageOverlay>
                    </ImageContainer>

                    <Box sx={{ p: 3 }}>
                      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        {editMode[product.product_id] ? (
                          <StyledTextField
                            fullWidth
                            value={editValues[product.product_id]?.name || ''}
                            onChange={(e) => handleFieldChange(product.product_id, 'name', e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ mr: 1 }}
                          />
                        ) : (
                          <Typography variant="h5" sx={{ color: '#fff', fontWeight: 600 }}>
                            {product.name}
                          </Typography>
                        )}
                        <IconButton
                          onClick={() => handleEditToggle(product.product_id)}
                          sx={{
                            color: editMode[product.product_id] ? 'primary.main' : '#fff',
                            '&:hover': {
                              bgcolor: alpha('#fff', 0.1)
                            }
                          }}
                        >
                          {editMode[product.product_id] ? <CloseIcon /> : <EditIcon />}
                        </IconButton>
                        <Button
              variant="outlined"
              component="label"
              startIcon={<PhotoCameraIcon />}
              sx={{ color: 'primary.main', borderColor: 'primary.main' }}
            >
              Upload Imagem
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={(e) => {
                  if (e.target.files[0]) {
                    handleImageUpload(product.product_id, e.target.files[0]);
                  }
                }}
              />
            </Button>

                      </Box>

                      {editMode[product.product_id] ? (
                        <Stack spacing={3}>
                          <StyledTextField
                            fullWidth
                            multiline
                            rows={3}
                            value={editValues[product.product_id]?.description || ''}
                            onChange={(e) => handleFieldChange(product.product_id, 'description', e.target.value)}
                            variant="outlined"
                            size="small"
                          />
                          <StyledTextField
                            fullWidth
                            type="number"
                            value={editValues[product.product_id]?.price || ''}
                            onChange={(e) => handleFieldChange(product.product_id, 'price', e.target.value)}
                            variant="outlined"
                            size="small"
                            InputProps={{
                              startAdornment: 'R$',
                            }}
                          />
                        </Stack>
                      ) : (
                        <>
                          <Typography sx={{ color: alpha('#fff', 0.7), mb: 2, minHeight: 60 }}>
                            {product.description}
                          </Typography>
                          <Typography variant="h5" sx={{ color: 'primary.main', fontWeight: 600, mb: 3 }}>
                            R$ {Number(product.price).toFixed(2)}
                          </Typography>
                        </>
                      )}

                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                        {editMode[product.product_id] ? (
                          <Button
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={() => handleSave(product.product_id)}
                            sx={{
                              bgcolor: 'primary.main',
                              '&:hover': {
                                bgcolor: alpha('#F97316', 0.9)
                              }
                            }}
                          >
                            Salvar
                          </Button>
                        ) : (
                          <Chip
                            label={`${product.images?.length || 0} imagens`}
                            variant="outlined"
                            sx={{
                              color: '#fff',
                              borderColor: alpha('#fff', 0.3)
                            }}
                          />
                        )}
                        <IconButton
                          onClick={() => setDeleteDialog({ open: true, productId: product.product_id })}
                          sx={{
                            color: '#ef4444',
                            '&:hover': {
                              bgcolor: alpha('#ef4444', 0.1)
                            }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </ProductCard>
                </Zoom>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Fade>

      <Dialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({ open: false, productId: null })}
        PaperProps={{
          sx: {
            bgcolor: '#112240',
            borderRadius: 3,
            border: '1px solid rgba(255, 255, 255, 0.08)'
          }
        }}
      >
        <DialogTitle sx={{ color: '#fff' }}>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <Typography sx={{ color: alpha('#fff', 0.7) }}>
            Tem certeza que deseja excluir este produto? Esta ação não pode ser desfeita.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setDeleteDialog({ open: false, productId: null })}
            sx={{ color: alpha('#fff', 0.7) }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(deleteDialog.productId)}
            sx={{
              bgcolor: '#ef4444',
              '&:hover': {
                bgcolor: alpha('#ef4444', 0.9)
              }
            }}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={imagePreview.open}
        onClose={() => setImagePreview({ open: false, images: [] })}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            bgcolor: '#112240',
            borderRadius: 3,
            border: '1px solid rgba(255, 255, 255, 0.08)'
          }
        }}
      >
        <DialogTitle sx={{ color: '#fff' }}>Visualizar Imagens</DialogTitle>
        <DialogContent>
          <ImageList cols={3} gap={8}>
            {imagePreview.images.map((img, index) => (
              <ImageListItem key={index}>
                <Box
                  component="img"
                  src={img}
                  alt={`Product image ${index + 1}`}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: 2,
                    boxShadow: `0 4px 12px ${alpha('#000', 0.3)}`
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setImagePreview({ open: false, images: [] })}
            sx={{ color: alpha('#fff', 0.7) }}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
    </Layout>
  );
};

export default ProductList;
