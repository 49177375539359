import React, { useState } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  Card,
  CardContent,
  Grid,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon, Search as SearchIcon } from '@mui/icons-material';

const steps = ['Cliente', 'Veículo', 'Serviço', 'Agendamento', 'Prestador', 'Itens', 'Revisão'];

const ServiceBudget = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    cliente: {
      user_id: '',
      nome: '',
      telefone: '',
      premium: false
    },
    servico: {
      tipo: '',
      descricao: '',
      valor: 0,
      status: 'pendente',
      urgencia: 'baixa',
      statusPagamento: 'pendente'
    },
    veiculo: {
      modelo: '',
      ano: '',
      placa: '',
      cor: ''
    },
    agendamento: {
      data: '',
      hora: '',
      duracao: ''
    },
    prestador: {
      nome: '',
      cpfCnpj: '',
      email: ''
    },
    itens: []
  });
  const [searchDialog, setSearchDialog] = useState(false);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleInputChange = (section, field, value) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  const handleAddItem = () => {
    setFormData(prev => ({
      ...prev,
      itens: [...prev.itens, {
        nome: '',
        quantidade: 1,
        precoUnitario: 0,
        precoTotal: 0
      }]
    }));
  };

  const handleRemoveItem = (index) => {
    setFormData(prev => ({
      ...prev,
      itens: prev.itens.filter((_, i) => i !== index)
    }));
  };

  const handleItemChange = (index, field, value) => {
    setFormData(prev => {
      const newItens = [...prev.itens];
      newItens[index] = {
        ...newItens[index],
        [field]: value
      };
      if (field === 'quantidade' || field === 'precoUnitario') {
        newItens[index].precoTotal = newItens[index].quantidade * newItens[index].precoUnitario;
      }
      return { ...prev, itens: newItens };
    });
  };

  const handleSearchCustomer = () => {
    setSearchDialog(true);
  };

  const getCurrentDate = () => {
    const now = new Date();
    return now.toISOString().split('T')[0];
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nome do Cliente"
                    value={formData.cliente.nome}
                    onChange={(e) => handleInputChange('cliente', 'nome', e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleSearchCustomer}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Telefone"
                    value={formData.cliente.telefone}
                    onChange={(e) => handleInputChange('cliente', 'telefone', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.cliente.premium}
                        onChange={(e) => handleInputChange('cliente', 'premium', e.target.checked)}
                      />
                    }
                    label="Cliente Premium"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );

      case 1:
        return (
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Modelo"
                    value={formData.veiculo.modelo}
                    onChange={(e) => handleInputChange('veiculo', 'modelo', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Ano"
                    value={formData.veiculo.ano}
                    onChange={(e) => handleInputChange('veiculo', 'ano', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Placa"
                    value={formData.veiculo.placa}
                    onChange={(e) => handleInputChange('veiculo', 'placa', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Cor"
                    value={formData.veiculo.cor}
                    onChange={(e) => handleInputChange('veiculo', 'cor', e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );

      case 2:
        return (
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Select
                    fullWidth
                    value={formData.servico.tipo}
                    onChange={(e) => handleInputChange('servico', 'tipo', e.target.value)}
                    label="Tipo de Serviço"
                  >
                    <MenuItem value="manutencao">Manutenção</MenuItem>
                    <MenuItem value="revisao">Revisão</MenuItem>
                    <MenuItem value="reparo">Reparo</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    fullWidth
                    value={formData.servico.urgencia}
                    onChange={(e) => handleInputChange('servico', 'urgencia', e.target.value)}
                    label="Urgência"
                  >
                    <MenuItem value="baixa">Baixa</MenuItem>
                    <MenuItem value="media">Média</MenuItem>
                    <MenuItem value="alta">Alta</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Descrição"
                    value={formData.servico.descricao}
                    onChange={(e) => handleInputChange('servico', 'descricao', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Valor"
                    value={formData.servico.valor}
                    onChange={(e) => handleInputChange('servico', 'valor', parseFloat(e.target.value))}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );

      case 3:
        return (
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    type="date"
                    label="Data"
                    value={formData.agendamento.data}
                    onChange={(e) => handleInputChange('agendamento', 'data', e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: getCurrentDate()
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    type="time"
                    label="Hora"
                    value={formData.agendamento.hora}
                    onChange={(e) => handleInputChange('agendamento', 'hora', e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Select
                    fullWidth
                    value={formData.agendamento.duracao}
                    onChange={(e) => handleInputChange('agendamento', 'duracao', e.target.value)}
                    label="Duração"
                  >
                    <MenuItem value="30min">30 minutos</MenuItem>
                    <MenuItem value="1h">1 hora</MenuItem>
                    <MenuItem value="2h">2 horas</MenuItem>
                    <MenuItem value="3h">3 horas</MenuItem>
                    <MenuItem value="4h">4 horas</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );

      case 4:
        return (
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nome do Prestador"
                    value={formData.prestador.nome}
                    onChange={(e) => handleInputChange('prestador', 'nome', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="CPF/CNPJ"
                    value={formData.prestador.cpfCnpj}
                    onChange={(e) => handleInputChange('prestador', 'cpfCnpj', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="E-mail"
                    type="email"
                    value={formData.prestador.email}
                    onChange={(e) => handleInputChange('prestador', 'email', e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );

      case 5:
        return (
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleAddItem}
                  >
                    Adicionar Item
                  </Button>
                </Grid>
                {formData.itens.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Card variant="outlined">
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <TextField
                              fullWidth
                              label="Nome do Item"
                              value={item.nome}
                              onChange={(e) => handleItemChange(index, 'nome', e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <TextField
                              fullWidth
                              type="number"
                              label="Quantidade"
                              value={item.quantidade}
                              onChange={(e) => handleItemChange(index, 'quantidade', parseInt(e.target.value))}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <TextField
                              fullWidth
                              type="number"
                              label="Preço Unitário"
                              value={item.precoUnitario}
                              onChange={(e) => handleItemChange(index, 'precoUnitario', parseFloat(e.target.value))}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <TextField
                              fullWidth
                              disabled
                              label="Total"
                              value={item.precoTotal}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <IconButton color="error" onClick={() => handleRemoveItem(index)}>
                              <RemoveIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        );

      case 6:
        return (
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Resumo do Orçamento
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">Cliente</Typography>
                  <Typography>{formData.cliente.nome}</Typography>
                  <Typography>{formData.cliente.telefone}</Typography>
                  <Typography>
                    {formData.cliente.premium ? 'Cliente Premium' : 'Cliente Regular'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">Veículo</Typography>
                  <Typography>{formData.veiculo.modelo} - {formData.veiculo.ano}</Typography>
                  <Typography>Placa: {formData.veiculo.placa}</Typography>
                  <Typography>Cor: {formData.veiculo.cor}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">Serviço</Typography>
                  <Typography>Tipo: {formData.servico.tipo}</Typography>
                  <Typography>Urgência: {formData.servico.urgencia}</Typography>
                  <Typography>Valor: R$ {formData.servico.valor}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Agendamento</Typography>
                  <Typography>Data: {formData.agendamento.data}</Typography>
                  <Typography>Hora: {formData.agendamento.hora}</Typography>
                  <Typography>Duração: {formData.agendamento.duracao}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Prestador</Typography>
                  <Typography>{formData.prestador.nome}</Typography>
                  <Typography>{formData.prestador.email}</Typography>
                  <Typography>CPF/CNPJ: {formData.prestador.cpfCnpj}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Itens</Typography>
                  {formData.itens.map((item, index) => (
                    <Box key={index} mt={1}>
                      <Typography>
                        {item.nome} - {item.quantidade}x R$ {item.precoUnitario.toFixed(2)} = R$ {item.precoTotal.toFixed(2)}
                      </Typography>
                    </Box>
                  ))}
                  <Box mt={2}>
                    <Typography variant="h6">
                      Total: R$ {(formData.itens.reduce((acc, item) => acc + item.precoTotal, 0) + formData.servico.valor).toFixed(2)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );

      default:
        return null;
    }
  };

  const handleSubmit = () => {
    const finalData = {
      ...formData,
      servico: {
        ...formData.servico,
        valor: Number(formData.servico.valor)
      },
      itens: formData.itens.map(item => ({
        ...item,
        quantidade: Number(item.quantidade),
        precoUnitario: Number(item.precoUnitario),
        precoTotal: Number(item.precoTotal)
      }))
    };
    
    console.log('Dados do orçamento:', finalData);
    // Aqui você implementa a chamada à API para salvar os dados
  };

  const isStepValid = (step) => {
    switch (step) {
      case 0:
        return formData.cliente.nome && formData.cliente.telefone;
      case 1:
        return formData.veiculo.modelo && formData.veiculo.placa;
      case 2:
        return formData.servico.tipo && formData.servico.descricao && formData.servico.valor > 0;
      case 3:
        return formData.agendamento.data && formData.agendamento.hora && formData.agendamento.duracao;
      case 4:
        return formData.prestador.nome && formData.prestador.cpfCnpj && formData.prestador.email;
      case 5:
        return formData.itens.length > 0 && formData.itens.every(item => 
          item.nome && item.quantidade > 0 && item.precoUnitario > 0
        );
      default:
        return true;
    }
  };

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ color: 'primary.main' }}>
        Novo Orçamento
      </Typography>
      
      <Stepper 
        activeStep={activeStep} 
        alternativeLabel 
        sx={{ 
          mb: 4,
          '& .MuiStepLabel-root .Mui-completed': {
            color: 'primary.main',
          },
          '& .MuiStepLabel-root .Mui-active': {
            color: 'primary.main',
          },
        }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      
      <Box sx={{ mt: 2, mb: 2 }}>
        {renderStepContent(activeStep)}
      </Box>

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        mt: 2,
        position: 'sticky',
        bottom: 16,
        backgroundColor: 'background.default',
        p: 2,
        borderRadius: 1,
        boxShadow: 3
      }}>
        <Button
          variant="outlined"
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{
            '&:hover': {
              borderColor: 'primary.main',
              backgroundColor: 'rgba(249, 115, 22, 0.04)'
            }
          }}
        >
          Voltar
        </Button>
        {activeStep === steps.length - 1 ? (
          <Button 
            variant="contained" 
            onClick={handleSubmit}
            sx={{
              backgroundColor: 'primary.main',
              '&:hover': {
                backgroundColor: 'primary.dark'
              }
            }}
          >
            Finalizar Orçamento
          </Button>
        ) : (
          <Button 
            variant="contained" 
            onClick={handleNext}
            disabled={!isStepValid(activeStep)}
            sx={{
              backgroundColor: 'primary.main',
              '&:hover': {
                backgroundColor: 'primary.dark'
              }
            }}
          >
            Próximo
          </Button>
        )}
      </Box>

      <Dialog 
        open={searchDialog} 
        onClose={() => setSearchDialog(false)}
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            borderRadius: 2
          }
        }}
      >
        <DialogTitle>Buscar Cliente</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Buscar por nome ou telefone"
            fullWidth
            variant="outlined"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSearchDialog(false)}>Cancelar</Button>
          <Button 
            onClick={() => setSearchDialog(false)} 
            variant="contained"
            sx={{ backgroundColor: 'primary.main' }}
          >
            Buscar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ServiceBudget;