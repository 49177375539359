import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Cart.css';

const Cart = ({ cartItems, removeFromCart }) => {
  const [eventDate, setEventDate] = useState('');
  const [eventDuration, setEventDuration] = useState('');
  const [eventLocation, setEventLocation] = useState('');
  const [amountPeople, setAmountPeople] = useState('');
  const navigate = useNavigate();

  const handleSaveBudget = async () => {
    // Formatar os itens do carrinho para enviar apenas os dados necessários
    const formattedItems = cartItems.map(item => ({
      name: item.name,
      description: item.description,
      price: 0,//item.price, Preço não informar agora
      location: item.location,
      product_service_id: item.product_service_id,
    }));

    const budgetData = {
      items: formattedItems, // Usar os itens formatados
      eventDate,
      eventDuration,
      eventLocation,
      amountPeople,
    };

    // Salvar os dados do orçamento no localStorage antes de redirecionar
    localStorage.setItem('savedBudget', JSON.stringify(budgetData));

    console.log(JSON.stringify(budgetData));

    navigate('/login');
  };

  return (
    <div className="cart">
      <h2>Orçamento</h2>
      {cartItems.length === 0 ? (
        <p>Seu orçamento está vazio.</p>
      ) : (
        <>
          <ul>
            {cartItems.map((item) => (
              <li key={item.id} className="cart-item">
                <div className="cart-item-details">
                  <h3>{item.name}</h3>
                  <p>{item.description}</p>
                  <p className="price">{item.price}</p>
                  <p className="location">{item.location}</p>
                </div>
                <button className="btn-remove" onClick={() => removeFromCart(item.id)}>
                  Remover
                </button>
              </li>
            ))}
          </ul>
          <div className="cart-details">
            <div className="input-group">
              <label htmlFor="eventDate">Data do Evento:</label>
              <input
                type="date"
                id="eventDate"
                value={eventDate}
                onChange={(e) => setEventDate(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label htmlFor="amountPeople">Total de convidados:</label>
              <input
                type="number"
                id="amountPeople"
                placeholder="Total de convidados"
                value={amountPeople}
                onChange={(e) => setAmountPeople(e.target.value)} // Corrigido para atualizar o valor correto
              />
            </div>
            <div className="input-group">
              <label htmlFor="eventDuration">Duração:</label>
              <input
                type="text"
                id="eventDuration"
                placeholder="Duração do evento(em horas)"
                value={eventDuration}
                onChange={(e) => setEventDuration(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label htmlFor="eventLocation">Localidade:</label>
              <input
                type="text"
                id="eventLocation"
                placeholder="Localidade do evento"
                value={eventLocation}
                onChange={(e) => setEventLocation(e.target.value)}
              />
            </div>
            <button className="btn-save" onClick={handleSaveBudget}>
              Salvar Orçamento e Enviar Cotações
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
