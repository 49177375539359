import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Fab,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  alpha,
} from '@mui/material';
import {
  Menu as MenuIcon,
  CalendarMonth,
  LocalGasStation,
  Speed,
  Close,
  Download,
  Share,
  Star,  
  Apple,
  Android,
} from '@mui/icons-material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from './components/Footer';
import Header from './components/Header';
import { useNavigate } from 'react-router-dom';
const apiUrl = "https://oyster-app-4ypyg.ondigitalocean.app";//process.env.URL_ENDPOINTS

// Definir o tema com tons de laranja e azul escuro
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#F97316',
    },
    secondary: {
      main: '#0A192F',
    },
    background: {
      default: '#0A192F',
      paper: '#112240',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: '#ff6400',
          borderColor: '#ff6400',
        },
      },
    },
  },
});

// Styled components
const StyledGradientBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.1,
    zIndex: 1,
  },
}));

const FloatingAppCTA = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1000,
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.paper, 0.95),
  backdropFilter: 'blur(10px)',
  boxShadow: theme.shadows[4],
  borderRadius: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    flexDirection: 'column',
  },
}));

const TorkiClassifieds = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showDownloadPrompt, setShowDownloadPrompt] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Buscar os três veículos mais recentes
    const fetchRecentVehicles = async () => {
      try {
        console.log(`${apiUrl}/api/listings`);
        const response = await fetch(`${apiUrl}/api/listings`);
        const data = await response.json();
        const sortedVehicles = data.listings
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 3);
        setVehicles(sortedVehicles);
      } catch (error) {
        console.error('Erro ao carregar veículos recentes:', error);
      }
    };
    fetchRecentVehicles();
  }, []);

  // Hero Section
  const Hero = () => (
    <StyledGradientBox sx={{ minHeight: '80vh', position: 'relative' }}>
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2, py: 8 }}>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '2.5rem', md: '4rem' },
                fontWeight: 800,
                color: 'white',
                mb: 3,
              }}
            >
              Seu próximo carro está aqui na{' '}
              <Box component="span" sx={{ color: "#0A192F" }}>
                Torki
              </Box>
            </Typography>
            <Typography variant="h5" sx={{ color: 'white', mb: 4, fontWeight: 300 }}>
              A maior plataforma de compra e venda de veículos do Brasil
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                startIcon={<Download />}
                sx={{ py: 2, px: 4 }}
              >
                Baixe o App
              </Button>
              <Button
                variant="outlined"
                size="large"
                sx={{ py: 2, px: 4, color: 'white', borderColor: 'white' }}
                onClick={() => navigate('/classificados/pesquisa')}
              >
                Ver Ofertas
              </Button>
            </Box>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box sx={{ textAlign: 'center', color: 'white' }}>
                <Typography variant="h4" fontWeight="bold">50K+</Typography>
                <Typography variant="body2">Veículos</Typography>
              </Box>
              <Box sx={{ textAlign: 'center', color: 'white' }}>
                <Typography variant="h4" fontWeight="bold">100K+</Typography>
                <Typography variant="body2">Usuários</Typography>
              </Box>
              <Box sx={{ textAlign: 'center', color: 'white' }}>
                <Typography variant="h4" fontWeight="bold">4.8</Typography>
                <Typography variant="body2">Avaliação</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="/assets/fundo.jpeg"
              sx={{
                width: '100%',
                maxWidth: 600,
                borderRadius: 4,
                boxShadow: theme.shadows[20],
                transform: 'perspective(1000px) rotateY(-10deg)',
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </StyledGradientBox>
  );

  // App Promo Section
  const AppPromo = () => (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="/assets/tela1.jpg"
              sx={{
                width: '100%',
                maxWidth: 300,
                mx: 'auto',
                display: 'block',
                transform: 'rotate(-5deg)',
                boxShadow: theme.shadows[10],
                borderRadius: 4,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h2" gutterBottom fontWeight="bold">
              Baixe o App da Torki
            </Typography>
            <Typography variant="h5" color="text.secondary" paragraph>
              Tenha o maior classificado de veículos na palma da sua mão
            </Typography>
            <Grid container spacing={2} sx={{ mb: 4 }}>
              {["Notificações de novos veículos", "Chat direto com vendedores", "Financiamento facilitado", "Vistoria garantida"].map((feature) => (
                <Grid item xs={12} key={feature}>
                  <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2, bgcolor: 'background.paper' }}>
                    <Star color="primary" />
                    <Typography>{feature}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" size="large" startIcon={<Apple />} color="primary" sx={{ py: 2, px: 4 }}>
                App Store
              </Button>
              <Button variant="contained" size="large" startIcon={<Android />} color="primary" sx={{ py: 2, px: 4 }}>
                Play Store
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  // Floating Download Prompt
  const DownloadPrompt = () => (
    <FloatingAppCTA>
      <Typography variant="body1" sx={{ fontWeight: 500 }}>
        Veja mais ofertas no App da Torki!
      </Typography>
      <Button variant="contained" color="primary" startIcon={<Download />} sx={{ whiteSpace: 'nowrap' }}>
        Baixar Agora
      </Button>
      <IconButton size="small" onClick={() => setShowDownloadPrompt(false)} sx={{ position: 'absolute', right: 8, top: 8 }}>
        <Close fontSize="small" />
      </IconButton>
    </FloatingAppCTA>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
        <Header />
        <Hero />
        <Container maxWidth="lg" sx={{ py: 8 }}>
          <Typography variant="h3" gutterBottom fontWeight="bold" color="primary.main">
            Veículos em Destaque
          </Typography>
          <Grid container spacing={3}>
          {vehicles.map((vehicle) => (
  <Grid item xs={12} sm={6} md={4} key={vehicle._id}>
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.2s',
        '&:hover': { transform: 'translateY(-8px)' },
      }}
      onClick={() => navigate(`/classificados/detalhes/${vehicle._id}`)}
    >
      <CardMedia 
        component="img" 
        height="200" 
        image={vehicle.imagens[0]} 
        alt={vehicle.vehicleId.name} 
      />
      <CardContent>
        <Typography variant="h6" gutterBottom color="primary.main">
          {vehicle.vehicleId.name}
        </Typography>
        <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
          {vehicle.vehicleId.highlights?.map((highlight) => (
            <Chip 
              key={highlight} 
              label={highlight} 
              size="small" 
              color="primary" 
              variant="outlined" 
            />
          ))}
        </Stack>
        <Typography variant="h5" color="primary.main" gutterBottom>
          R$ {vehicle.price.toLocaleString('pt-BR')}
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CalendarMonth fontSize="small" color="primary" />
            <Typography variant="body2">{vehicle.vehicleId.year}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Speed fontSize="small" color="primary" />
            <Typography variant="body2">{vehicle.vehicleId.odometer}km</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <LocalGasStation fontSize="small" color="primary" />
            <Typography variant="body2">{vehicle.vehicleId.combustivel}</Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  </Grid>
))}

          </Grid>
        </Container>
        <AppPromo />
        <Footer />
        {showDownloadPrompt && <DownloadPrompt />}
        <Fab color="primary" aria-label="share" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
          <Share />
        </Fab>
      </Box>
    </ThemeProvider>
  );
};

export default TorkiClassifieds;
