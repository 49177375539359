import React, { useState, useEffect,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './AlinyChatbot.css';

const AlinyChatbot = ({ addEventDetail, addToBudgetItems }) => {
  const [step, setStep] = useState(0);
  const [userAnswers, setUserAnswers] = useState({
    eventName: '',
    eventLocation: '',
    eventState: '',
    guestCount: 0, // Número de participantes
    eventDate: '',
    eventType: '',
    eventDuration: 0,
    budgetItems: [], // Orçamento
  });
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [typing, setTyping] = useState(false);
  const [isFinalStep, setIsFinalStep] = useState(false);
  const [eventSuggestions, setEventSuggestions] = useState([]);
  const [mood, setMood] = useState('neutral');
  const chatEndRef = useRef(null);
  const navigate = useNavigate();
  const [isRedirectConfirmation, setIsRedirectConfirmation] = useState(false); // Nova variável para confirmação
  // Estados para gerenciar o fluxo de e-mail, nome e senha
  const [isEmailConfirmation, setIsEmailConfirmation] = useState(false);
  const [isCollectingName, setIsCollectingName] = useState(false);
  const [isCollectingPassword, setIsCollectingPassword] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: '',
    name: '',
    password: '',
  });



  // Dicionário de cidades e estados para sugestão automática
  const cityStateMapping = {
    'São Paulo': 'SP',
    'Rio de Janeiro': 'RJ',
    'Belo Horizonte': 'MG',
    'Curitiba': 'PR',
    'Porto Alegre': 'RS',
    // Adicione mais cidades conforme necessário
  };

  
  // Perguntas amigáveis
  const questions = [
    "Oi! Sou a Aliny 😊 Tudo bem com você? Vou te ajudar a organizar seu evento! Como vai ser o nome do seu evento? 🎉",
    "Legal! Agora, me diz uma coisa, em qual cidade vai ser esse evento incrível? 🌍",
    "Ótimo! E qual o estado? Vou te ajudar a garantir que está tudo certo com o local. 🗺️",
    "Uau! Quantas pessoas vão fazer parte dessa festa? 👥",
    "Que demais! E quando vai ser o grande dia? 📅 Quanto mais cedo organizarmos, melhor! 😉",
    "Entendido! E quantas horas você imagina que o evento vai durar? ⏳",
    "A última pergunta, prometo! Qual o tipo de festa? Infantil, casamento, formatura...? Estou curiosa! 🎈",    
  ];

  // Função para rolar até o fim do chat automaticamente
  const scrollToBottom = () => {
    // console.log(chatEndRef.current );
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Rola para o final sempre que o chatHistory mudar
  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, typing]);


  useEffect(() => {
    if (step < questions.length) {
      handleTyping(questions[step]);
    } else if (!isFinalStep) {
      handleFinalization();
      setIsFinalStep(true);
    }

    

  }, [step]);

  const handleTyping = (text) => {
    setTyping(true);
    setTimeout(() => {
      setChatHistory((prev) => [...prev, { from: 'aliny', text }]);
      setTyping(false);
      
    }, 1200);
  };

  // Função para identificar comandos do usuário
  const identifyCommand = async (response) => {
    try {
      const commands = {
        'adicionar': 'add',
        'remover': 'remove',
        'mostrar orçamento': 'show_budget',
        'sugestão': 'suggest',
        'confirmar detalhes': 'confirm_details',
        'finalizar orçamento': 'finalize_budget', // Novo comando
        'aceito': 'accept', // Novo comando
        'continuar': 'continue', // Novo comando
        'validar email': 'validate_mail',
        'ajuda': 'help',
      };
  
      for (const command in commands) {
        if (response.toLowerCase().includes(command)) {
          return commands[command];
        }
      }
      return null;
    } catch (error) {
      console.error("Erro ao identificar o comando:", error);
      // Retorna uma mensagem para a Aliny avisar o usuário
      handleTyping("Desculpe, tive um problema ao tentar entender o seu comando. Pode tentar de novo? 😅");
      return null; // Retorna null para garantir que não haja falha no fluxo
    }
  };
  

  // Função para lidar com comandos
  const handleCommand = async (command, response) => {
    switch (command) {
      case 'add':
        await handleAddCommand(response);
        break;
      case 'remove':
        await handleRemoveCommand(response);
        break;
      case 'show_budget':
        await handleShowBudgetCommand();
        break;
      case 'suggest':
        await handleSuggestCommand(response);
        break;
      case 'confirm_details':
        await handleConfirmDetailsCommand();
        break;
      case 'finalize_budget':
        handleFinalizeBudgetCommand(response); // Novo comando para finalizar o orçamento
        break;
      case 'accept':
         handleConfirmRedirect(response); // Novo comando para aceitar redirect
          break;    
      case 'continue':
          handleConfirmRedirect(response); // Novo comando para aceitar redirect
            break;  
      case 'validate_email':
        handleEmailInput(response);
          break;  
      case 'help':
        handleTyping(`Aqui estão os comandos que você pode usar:
        - "Adicionar": Para adicionar itens ao orçamento
        - "Remover": Para remover itens do orçamento
        - "Mostrar orçamento": Para exibir o orçamento atual
        - "Sugestão": Para pedir sugestões de itens
        - "Confirmar detalhes": Para revisar detalhes do evento
        - "Finalizar orçamento": Para salvar e finalizar o orçamento
        - "Ajuda": Para ver os comandos disponíveis.`);
        break;
      default:
        handleTyping('Comando não reconhecido. Tente usar "ajuda" para ver os comandos disponíveis.');
    }
  };

  // Funções de ação para cada comando
  const handleAddCommand = async (response) => {
    const item = response.split('adicionar')[1].trim();
    if (!item) {
      handleTyping("Desculpe, você pode me dizer o que deseja adicionar?");
      return;
    }

    const similarResponse = await checkSimilarInteractions(response);
    if (similarResponse) {
        handleTyping(similarResponse);
      } else {
        handleTyping(`Adicionando ${item} ao orçamento!`);
        
        // Adiciona o item ao orçamento e registra no log
        const newItem = { name: item, id: Date.now(), description: "Descrição do item", price: 100, location: "Local de exemplo" }; // Exemplo de item
        
        setUserAnswers((prev) => ({
          ...prev,
          budgetItems: [...prev.budgetItems, newItem],
        }));
      
        // Chama a função addToBudgetItems para adicionar ao carrinho
        addToBudgetItems(newItem);
      
        // Registra a interação no log
        logInteraction('adicionar', item, mood);
      }      
  };

  const handleRemoveCommand = async (response) => {
    const item = response.split('remover')[1].trim();
    if (!item) {
      handleTyping("Desculpe, você pode me dizer o que deseja remover?");
      return;
    }

    const newBudgetItems = userAnswers.budgetItems.filter(budgetItem => budgetItem.name.toLowerCase() !== item.toLowerCase());
    if (newBudgetItems.length !== userAnswers.budgetItems.length) {
      setUserAnswers(prev => ({ ...prev, budgetItems: newBudgetItems }));
      handleTyping(`Removi "${item}" do seu orçamento.`);
    } else {
      handleTyping(`Não encontrei o item "${item}" no seu orçamento.`);
    }
    logInteraction('remover', item, mood);
  };

  const handleShowBudgetCommand = () => {
    if (userAnswers.budgetItems.length === 0) {
      handleTyping("Seu orçamento está vazio no momento.");
    } else {
      const budget = userAnswers.budgetItems.map(item => item.name).join(', ');
      handleTyping(`Aqui está seu orçamento: ${budget}.`);
    }
  };

  const handleSuggestCommand = async (response) => {
    try {
      const type = response.split('sugestão')[1]?.trim(); // Verifica se a parte após "sugestão" existe antes de usar trim
      if (type) {
        suggestItems(type);
      } else {
        handleTyping("Desculpe, você pode me dizer para qual tipo de evento gostaria de sugestões?");
      }
    } catch (error) {
      console.error("Erro ao processar a sugestão:", error);
      // Aliny responde de forma amigável ao usuário
      handleTyping("Opa, parece que tive um problema ao sugerir itens. Pode tentar de novo? 😅");
    }
  };
  

  const handleConfirmDetailsCommand = () => {
    const { eventName, guestCount, eventDate } = userAnswers;
    handleTyping(`Aqui estão os detalhes do seu evento:
    📛 Nome: ${eventName || 'Não fornecido'}
    👥 Número de convidados: ${guestCount || 'Não fornecido'}
    📅 Data: ${eventDate || 'Não fornecida'}`);
  };

  // Função para fazer uma chamada ao log_interaction.php e validar itens similares
  const checkSimilarInteractions = async (query) => {
    try {
      const response = await fetch('https://teste.stratusport.co/festafacil/log_interaction.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }),
      });
      const data = await response.json();
      return data.similar ? data.response : null; // Se houver similaridade, retorna a resposta anterior
    } catch (error) {
      console.error('Erro ao consultar interações anteriores:', error);
      return null;
    }
  };
  // Função para enviar e-mail e verificar se o usuário existe
  const handleEmailInput = async (email) => {
    // Validação básica de e-mail
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      handleTyping('O e-mail fornecido não parece ser válido. Por favor, tente novamente. 📧');
      return;
    }

    // Verificação no sistema se o e-mail existe via POST
    try {
      const response = await fetch('https://teste.stratusport.co/festafacil/check_user.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }), // Envia o e-mail no corpo da requisição
      });

      const data = await response.json();

      if (data.user_exists) {
        handleTyping(`Bem-vindo de volta! Seu e-mail já está registrado em nosso sistema. Você será redirecionado para o login agora. 🔑`);

        // Redireciona para a página de login
        setTimeout(() => {
          navigate('/login'); // Ajuste a rota para a página de login
        }, 2000);
      } else {
        // Solicita o nome se o usuário não existe
        handleTyping(`
          Parece que você ainda não tem um cadastro conosco. 😅
          Vamos te ajudar a se cadastrar! Por favor, me diga seu nome para continuar. 😊
        `);
        setIsCollectingName(true); // Define o estado para coletar o nome
        setUserDetails({ ...userDetails, email }); // Salva o e-mail no estado
      }
    } catch (error) {
      console.error('Erro ao verificar o e-mail:', error);
      handleTyping('Desculpe, ocorreu um erro ao verificar seu e-mail. Tente novamente mais tarde.');
    }

    setIsEmailConfirmation(false); // Desativa o estado de coleta de e-mail após a validação
  };

  // Função para lidar com a coleta do nome
  const handleNameInput = (name) => {
    if (!name || name.trim().length === 0) {
      handleTyping('O nome fornecido não parece ser válido. Por favor, tente novamente.');
      return;
    }

    // Solicitar a senha após obter o nome
    handleTyping('Perfeito, obrigado! Agora, por favor, escolha uma senha para sua conta. 🔒');
    setUserDetails((prevDetails) => ({ ...prevDetails, name })); // Salva o nome no estado
    setIsCollectingName(false);
    setIsCollectingPassword(true); // Define o estado para coletar a senha
  };

  // Função para lidar com a coleta da senha e registro
  const handlePasswordInput = async (password) => {
    if (!password || password.trim().length < 6) {
      handleTyping('A senha deve ter pelo menos 6 caracteres. Por favor, tente novamente.');
      return;
    }

    // Finaliza o cadastro do usuário enviando nome, email e senha para o backend
    try {
      const userDetailsWithPassword = {
        email: userDetails.email,  // Pega o email coletado antes
        name: userDetails.name,    // Nome coletado na etapa anterior
        password,
      };

      const response = await fetch('https://teste.stratusport.co/festafacil/register_user.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userDetailsWithPassword),
      });

      const data = await response.json();

      if (data.success) {
        handleTyping('Cadastro realizado com sucesso! Agora podemos continuar com o processo de cotação. 🚀');
        setTimeout(() => {
          navigate('/cotacao'); // Ajuste a rota para onde o usuário deve ser redirecionado
        }, 2000);
      } else {
        handleTyping('Ocorreu um problema ao tentar cadastrar seu usuário. Tente novamente mais tarde.');
      }
    } catch (error) {
      console.error('Erro ao registrar o usuário:', error);
      handleTyping('Desculpe, ocorreu um erro durante o registro. Tente novamente mais tarde.');
    }

    setIsCollectingPassword(false); // Finaliza a coleta de senha
  };


  // Função para lidar com a resposta do usuário
  const handleUserResponse = async (response) => {
    const command = await identifyCommand(response);

    console.log("Foi mandado >>>>>>>>> " + command +  "  " + response);

    if (command) {
      await handleCommand(command, response);
    } else {
      // Processo normal de interação se não for um comando
      let newAnswers = { ...userAnswers };
      let detectedMood = detectMood(response);
  
      const similarResponse = await checkSimilarInteractions(response);
  
      if (similarResponse) {
        handleTyping(similarResponse);
      } else {
        switch (step) {
          case 0:
            newAnswers.eventName = response;
            addEventDetail({ eventName: response });  // Adiciona o nome do evento aos detalhes
            handleTyping(`Que nome legal! "${response}" tem tudo a ver com uma festa memorável 🎉`);
            break;
          case 1:
            newAnswers.eventLocation = response;
            addEventDetail({ eventLocation: response });  // Adiciona a localização do evento
            if (cityStateMapping[response]) {
              newAnswers.eventState = cityStateMapping[response];
              addEventDetail({ eventState: cityStateMapping[response] });  // Atualiza o estado
              handleTyping(`Você mencionou "${response}", então acredito que seja no estado de ${cityStateMapping[response]}, certo?`);
            } else {
              handleTyping(`"${response}" é uma cidade incrível! 🌍`);
            }
            break;
          case 2:
            if (!newAnswers.eventState) {
              newAnswers.eventState = response;
              addEventDetail({ eventState: response });  // Adiciona o estado do evento
            }
            handleTyping(`Ok, anotado! Estado: ${response}. Vamos em frente!`);
            break;
          case 3:
            newAnswers.guestCount = parseInt(response);  // Armazena o número de convidados
            addEventDetail({ guestCount: parseInt(response) });  // Adiciona o número de convidados
            handleTyping(`Uau, ${response} pessoas! Vai ser uma grande festa! 👥`);
            break;
          case 4:
            newAnswers.eventDate = response;
            addEventDetail({ eventDate: response });  // Adiciona a data do evento
            handleTyping(`Perfeito, "${response}" está marcado! Só falta a contagem regressiva começar! 📅`);
            break;
          case 5:
              newAnswers.eventDuration = response;
              addEventDetail({ eventDuration: parseInt(response) });  // Adiciona a data do evento
              handleTyping(`Sensacional, "${response}" vão ser as melhores horas deste dia :-)! 📅`);
              break;
          case 6:
            newAnswers.eventType = response;
            addEventDetail({ eventType: response });  // Adiciona o tipo de evento
            handleTyping(`Ahh, adoro esse tipo de festa! Vamos caprichar em tudo para sua festa de ${response}! 🎈`);
            suggestItems(response);  // Chama a função para sugerir itens
            break;
          case 7:
            if (isRedirectConfirmation) {
              newAnswers.eventType = response;                      
              handleConfirmRedirect(response);
              break;
            }else{
              
              break;
            }
          default:
            break;
        }
  
        logInteraction(questions[step], response, detectedMood);        
      }
  
      setUserAnswers(newAnswers);
      setMood(detectedMood);
      setChatHistory((prev) => [...prev, { from: 'user', text: response }]);
      // scrollToBottom();
      setStep(step + 1);
    }
  };
  

  const detectMood = (response) => {
    const negativeWords = ['triste', 'chateado', 'decepcionado', 'raiva', 'frustrado'];
    const positiveWords = ['feliz', 'alegre', 'empolgado', 'animado', 'contente'];
    let moodDetected = 'neutral';

    negativeWords.forEach((word) => {
      if (response.toLowerCase().includes(word)) {
        moodDetected = 'negative';
      }
    });

    positiveWords.forEach((word) => {
      if (response.toLowerCase().includes(word)) {
        moodDetected = 'positive';
      }
    });

    return moodDetected;
  };

  // Função para sugerir itens com base no tipo de evento
  const suggestItems = (eventType) => {
    let suggestions = [];
    if (eventType.toLowerCase() === 'infantil') {
      suggestions = [
        'Buffet infantil com salgadinhos e doces',
        'Brinquedos infláveis',
        'Decoração temática',
        'Animadores de festa',
      ];
    } else if (eventType.toLowerCase() === 'casamento') {
      suggestions = [
        'Tendas para festa ao ar livre',
        'Decoração floral',
        'Iluminação especial',
        'DJ ou banda',
        'Buffet completo',
      ];
    } else if (eventType.toLowerCase() === 'formatura') {
      suggestions = [
        'Pista de dança',
        'Mesa de buffet',
        'Bar de drinks',
        'Cabine de fotos',
        'Serviço de catering',
      ];
    }

    setEventSuggestions(suggestions);
    handleTyping(`Já estou cheia de ideias! Que tal incluir: ${suggestions.join(', ')}?`);
  };

  const analyzeEvent = () => {
    const { guestCount, eventDate } = userAnswers;
    const daysUntilEvent = calculateDaysUntilEvent();

    if (!guestCount || guestCount <= 0) return;

    const sweets = Math.ceil(guestCount * 5);
    const savories = Math.ceil(guestCount * 8);
    const tables = Math.ceil(guestCount / 6);
    const chairs = guestCount;
    const beverages = Math.ceil(guestCount * 0.5);

    const analysisMessage = `
      🎉 O seu evento está chegando em ${daysUntilEvent} dias! Com ${guestCount} convidados, sugiro que você considere:
      🍬 ${sweets} doces,
      🥟 ${savories} salgadinhos,
      🪑 ${chairs} cadeiras,
      🍽️ ${tables} mesas,
      🥤 ${beverages} litros de bebidas não alcoólicas (refrigerantes/sucos),
      Além disso, é sempre bom garantir água e, caso tenha bebidas alcoólicas, considerar cerca de 3 copos por pessoa!
    `;

    handleTyping(analysisMessage);
  };

  const calculateDaysUntilEvent = () => {
    const { eventDate } = userAnswers;

    if (!eventDate) return 'Data não fornecida';

    const eventDateParts = eventDate.split('/');
    if (eventDateParts.length !== 3) {
      return 'Formato de data inválido';
    }

    const formattedDate = `${eventDateParts[1]}/${eventDateParts[0]}/${eventDateParts[2]}`;
    const eventDateObject = new Date(formattedDate);
    const today = new Date();

    if (isNaN(eventDateObject.getTime())) {
      return 'Data inválida';
    }

    const timeDiff = eventDateObject.getTime() - today.getTime();
    const daysUntilEvent = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return daysUntilEvent >= 0 ? daysUntilEvent : 'Data passada';
  };

  const handleFinalization = () => {
    analyzeEvent();

    const finalMessages = [
      "Uhuu! Já organizei tudo aqui! 🎉",
      `Vamos revisar o que já temos:`,
      `📛 Nome do evento: ${userAnswers.eventName}`,
      `📍 Local: ${userAnswers.eventLocation}, ${userAnswers.eventState}`,
      `👥 Número de convidados: ${userAnswers.guestCount}`,
      `📅 Data: ${userAnswers.eventDate} (${calculateDaysUntilEvent()} dias até o grande dia!)`,
      `🎉 Tipo de festa: ${userAnswers.eventType}`,
      `🎉 Duração da festa: ${userAnswers.eventDuration} horas`,
      "Agora você pode me pedir sugestões de produtos ou serviços para o seu evento! Estou pronta! 😁"
    ];

    finalMessages.forEach((msg, index) => {
      setTimeout(() => handleTyping(msg), index * 1300);
    });
  };

  const handleSearch = async (query) => {
    try {
      // Remove espaços extras e coloca o query em lowercase para comparação
      const normalizedQuery = query.trim().toLowerCase();
  
      // Lista de comandos que devem ser tratados
      const commands = {
        'adicionar': 'add',
        'remover': 'remove',
        'mostrar orçamento': 'show_budget',
        'sugestão': 'suggest',
        'confirmar detalhes': 'confirm_details',
        'finalizar orçamento': 'finalize_budget',
        'aceito': 'accept',
        'continuar': 'continue',
        'validar email': 'validate_mail',
        'ajuda': 'help'
      };
  
      // Verifica se a query contém algum comando específico
      const detectedCommand = Object.keys(commands).find(command => normalizedQuery.includes(command));
  
      if (detectedCommand) {
        // Se for um comando, chama a função correspondente ao comando
        await handleCommand(commands[detectedCommand], query);
        return;
      }
  
      // Verificação se a pesquisa está vazia ou inválida
      if (!normalizedQuery || normalizedQuery.length < 2) {
        handleTyping("Por favor, insira um termo de pesquisa válido com pelo menos 2 caracteres.");
        return;
      }
  
      // Realiza a busca caso a query seja válida
      const response = await fetch(`https://teste.stratusport.co/festafacil/products.php?search=${normalizedQuery}`);
      const data = await response.json();
  
      if (data && data.length > 0) {
        // Adiciona os itens ao orçamento
        setUserAnswers((prev) => ({
          ...prev,
          budgetItems: [...prev.budgetItems, ...data]
        }));
  
        // Notifica o usuário que os itens foram encontrados
        handleTyping(`Encontrei alguns itens e já adicionei ao seu orçamento: ${data.map(item => item.name).join(', ')}. O que mais posso fazer por você? 😄 Se quiser finalizar o orçamento, é só digitar "finalizar orçamento".`);
  
        // Adiciona cada item ao carrinho chamando addToBudgetItems individualmente
        data.forEach((item) => addToBudgetItems(item));
      } else {
        handleTyping(`Hmm... não encontrei "${query}". Mas já acionei nosso time comercial e vamos trazer novidades! Que tal tentar outro item?`);
      }
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
      handleTyping("Opa! Algo deu errado ao buscar o produto. Pode tentar de novo? Prometo que vou conseguir! 🙏");
    }
  };
  
  

  const handleSendMessage = (e) => {
    e.preventDefault();
  
    try {
      // Verifica se a mensagem existe e se não está vazia antes de usar o trim()
      if (message && message.trim() !== '') {
        if (step >= questions.length) {
          handleSearch(message.trim());
        } else {
          handleUserResponse(message.trim());
        }
        setMessage(''); // Limpa o campo de input após o envio
      } else {
        handleTyping("Por favor, insira uma mensagem válida.");
      }
    } catch (error) {
      console.error("Ocorreu um erro ao processar a mensagem:", error);
      // Aliny avisa o usuário sobre o erro de forma amigável
      handleTyping("Opa, parece que algo deu errado. Pode tentar de novo? 😅");
    }
  };
  
  const handleFinalizeBudgetCommand = (response) => {
    // Formatar os itens do orçamento
    const formattedItems = userAnswers.budgetItems.map(item => ({
      name: item.name,
      description: item.description,
      price: 0, // Preço não informado agora
      product_service_id: item.product_service_id
    }));
  
    // Formatar o objeto completo com detalhes do evento
    const budget = {
      items: formattedItems,
      eventDate: userAnswers.eventDate || 'Data não fornecida',
      eventDuration: userAnswers.eventDuration || 'Duração não fornecida',
      eventLocation: userAnswers.eventLocation || 'Local não fornecido',
      amountPeople: userAnswers.guestCount || 'Quantidade de pessoas não fornecida'
    };
  
    // Salvar o orçamento formatado no localStorage
    localStorage.setItem('savedBudget', JSON.stringify(budget));
  
    // Perguntar ao usuário se deseja ser redirecionado
    handleTyping(`
      Seu orçamento foi salvo com sucesso! 🚀 Agora podemos solicitar cotações aos fornecedores. 
      Esse processo pode demorar um pouco, mas vamos agilizar ao máximo! 😄
  
      🔒 Lembre-se: Nenhuma informação de contato será repassada aos fornecedores até que você aceite o orçamento.
  
      Você concorda em ser redirecionado para solicitar as cotações agora? (Digite "Aceito" para confirmar ou "Continuar" para cancelar.)
    `);
  
    // Aguardar a resposta do usuário
    // Define o estado especial para confirmação de redirecionamento
    // setStep(7);
    // setIsRedirectConfirmation(true);
    handleConfirmRedirect(response);
  };
    
//   // Função para lidar com a resposta de confirmação
// const handleConfirmRedirect = (response) => {
  
//   if (response.toLowerCase() === 'aceito') {
//     handleTyping('Ótimo! Redirecionando você agora...');
//     setIsRedirectConfirmation(false)
//     // Redireciona para a próxima tela após a confirmação
//     setTimeout(() => {
//       navigate('/login'); // Ajuste a rota para onde o usuário deve ser redirecionado
//     }, 2000);
//   } else if (response.toLowerCase() === 'continuar') {
//     handleTyping('Entendido! Você pode continuar aqui e solicitar as cotações mais tarde. 😊');
//   } else {
//     handleTyping('Por favor, responda com "Aceito" ou "Continuar".');
//   }
// };


// Função para lidar com a jornada de validação/criação de usuário
const handleConfirmRedirect = async (response) => {
  if (response.toLowerCase() === 'aceito') {
    handleTyping('Ótimo! Vamos validar seu e-mail para continuar o processo.');

    // Iniciar a jornada pedindo o e-mail do usuário
    setIsEmailConfirmation(true); // Define o estado para coleta do e-mail
    handleTyping('Por favor, informe seu e-mail para validarmos se já possui cadastro. 📧');
  } else if (response.toLowerCase() === 'continuar') {
    handleTyping('Entendido! Você pode continuar aqui e solicitar as cotações mais tarde. 😊');
  } else {
    handleTyping('Por favor, responda com "Aceito" ou "Continuar".');
  }
};


  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const logInteraction = async (question, response, mood) => {
    try {
      const interaction = { question, response, mood };
      await fetch('https://teste.stratusport.co/festafacil/log_interaction.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(interaction),
      });
    } catch (error) {
      console.error('Erro ao registrar interação:', error);
    }
  };

  return (
    <div className="chatbot-container">
      <div className="chat-header">
        <h2>Aliny, sua assistente de festa virtual 🎉</h2>
      </div>
      <div className="chat-history">
        {chatHistory.map((chat, index) => (
          <div key={index} className={`chat-message ${chat.from}`}>
            <p>{chat.text}</p>
            <div className="powered-by">
              <small>powered by FestaFacil</small>
            </div>
          </div>          
        ))}        

        {typing && <div className="chat-message typing">Aliny está digitando...</div>}
        {/* Adicione esta linha para associar o ref ao final do chat */}
        <div ref={chatEndRef}></div>
      </div>

      <form onSubmit={handleSendMessage} className="chat-input-form">
        <input
          type="text"
          value={message}
          onChange={handleInputChange}
          className="chat-input"
          placeholder="Digite aqui..."
        />
        <button type="submit" className="send-btn">Enviar</button>
      </form>
    </div>
  );
};

export default AlinyChatbot;
