// App.js
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import TicketLead from './componentes/TicketLead';
import theme from '../Tema';

function App() {
  return (
    <TicketLead />
    
  );
}

export default App;
