// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import CatalogPage from './CatalogPage'; // Supondo que você tenha essa página
import LoginPage from './LoginPage';
import Dashboard from './Dashboard';
import Budgets from './Budgets';
import BudgetDetails from './BudgetDetails'; // Página de detalhes do orçamento
import AlinyAndCart from './AlinyAndCart'; // Página de detalhes do orçamento
import Servicos from "./Services";
import ServiceDetails from './ServiceDetails';
import Fornecedores from './Fornecedores';
import SupplierDetails from './SupplierDetails';
import Services from "./Servicos";
import Orcamentos from "./Orcamento";
import Contaspf1 from "./contaspf/AccountJourney";

import CadastrarProduto from "./marketplace/ProductRegisterScreen";
import DashBoardMarket from "./marketplace/DashboardMarket";
import Listarprodutos from "./marketplace/ProductList";
import Autoshop from "./marketplace/LandingPage";
import ServicosLojaDetalhe from "./marketplace/servicos/ServicosContent";
import ServicosLoja from "./marketplace/servicos/Servicos";
import SupplierDetailsLoja from "./marketplace/servicos/SupplierDetails";
import OrcamentosLoja from "./marketplace/servicos/Orcamento";
import DetalhesServicos from "./marketplace/servicos/ServiceDetails";
import DetalhesOrcamentosLoja from "./marketplace/servicos/DetalhesOrcamentosLoja";
import FornecedoresServico from "./marketplace/fornecedores/Fornecedores";
import Cadastro from "./marketplace/fornecedores/CadastroPrestador";
import CadastroServicos from "./marketplace/fornecedores/Servicos";

import Classificados from "./classificados/Classificados";
import DetalhesVeiculo from "./classificados/DetalhesVeiculo";
import PesquisarVeiculos from "./classificados/PesquisaVeiculo";
import NossoValores from "./rodape/Integridade";
import Breve from "./Breve";
import Pluto from "./pluto/Inicial";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Breve />} />
        <Route path="/catalog" element={<CatalogPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<Dashboard />} /> 
        <Route path="/budgets" element={<Budgets />} />
        <Route path="/budget-details/:budgetId" element={<BudgetDetails />} /> {/* Página de detalhes do orçamento */}
        <Route path="/aliny" element={<AlinyAndCart />} />
        
        
        <Route path="/service-details/:scheduleId" element={<ServiceDetails />} />
        <Route path="/fornecedor" element={<Fornecedores />} />
        <Route path="/supplier/:supplierId" element={<SupplierDetails />} />
        <Route path="/servicos" element={<Servicos />} />
        <Route path="/services/:supplierId" element={<Services />} />
        <Route path="/orcamentos/:schedule_id" element={<Orcamentos />} />
        <Route path="/contaspf/" element={<Contaspf1 />} />
        <Route path="/classificados" element={<Classificados />} />
        <Route path="/classificados/pesquisa" element={<PesquisarVeiculos />} />
        <Route path="/classificados/detalhes/:idVeiculo" element={<DetalhesVeiculo />} />
        <Route path="/loja/cadastrarprodutos/" element={<CadastrarProduto />} />
        <Route path="/loja" element={<DashBoardMarket />} />
        <Route path="/loja/listarprodutos" element={<Listarprodutos />} />
        <Route path="/loja/servicos/:supplierId" element={<ServicosLoja />} />
        {/* <Route path="/loja/servicos/detalhes/:scheduleId" element={<ServicosLojaDetalhe />} /> */}
        <Route path="/loja/servicos/supplier/:supplierId" element={<SupplierDetailsLoja />} />
        <Route path="/loja/servicos/orcamento/:plate/:id_fornecedor" element={<OrcamentosLoja />} />
        <Route path="/loja/servicos/orcamento/detalhes/:schedule_id" element={<OrcamentosLoja />} />
        <Route path="/loja/servicos/detalhes/:scheduleId" element={<DetalhesServicos />} />
        <Route path="/loja/fornecedores" element={<FornecedoresServico />} />
        <Route path="/loja/fornecedores/cadastro" element={<Cadastro />} />
        <Route path="/loja/fornecedores/cadastro/servicos/:supplierId" element={<CadastroServicos />} />

        <Route path="/autoshop" element={<Autoshop />} />
        <Route path="/nossosvalores" element={<NossoValores />} />        
        <Route path="/breve" element={<Breve />} />  
        <Route path="/pluto" element={<Pluto />} />        
      </Routes>
    </Router>
  );
}

export default App;
