import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Header.css'; // Arquivo CSS para estilização

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    // Verifica se existe um token JWT na URL ou no localStorage
    const queryParams = new URLSearchParams(location.search);
    const tokenInUrl = queryParams.get('token');
    const tokenInStorage = localStorage.getItem('jwt_token');

    if (tokenInUrl) {
      setToken(tokenInUrl);
      localStorage.setItem('jwt_token', tokenInUrl);
      setIsLoggedIn(true);
    } else if (tokenInStorage) {
      setToken(tokenInStorage);
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [location]);

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleLogoutClick = () => {
    localStorage.removeItem('jwt_token');
    setToken(null);
    setIsLoggedIn(false);
    setMenuOpen(false);
    navigate('/', { replace: true });
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev); // Alterna o estado do menu
  };

  return (
    <header className="header">
      <Link to="/" className="logo-link">
        <div className="logo">Torki</div>
      </Link>

      <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
        {isLoggedIn ? (
          <>
            <Link to={`/dashboard?token=${token}`} className="nav-item" onClick={() => setMenuOpen(false)}>
              Dashboard
            </Link>
            <Link to={`/fornecedor?token=${token}`} className="nav-item" onClick={() => setMenuOpen(false)}>
              Fornecedores
            </Link>
            <Link to={`/services?token=${token}`} className="nav-item" onClick={() => setMenuOpen(false)}>
              Serviços
            </Link>
            <button className="btn-access" onClick={handleLogoutClick}>
              Sair
            </button>
          </>
        ) : (
          <button className="btn-access" onClick={handleLoginClick}>
            Acessar
          </button>
        )}
      </nav>

      {/* Menu hambúrguer para dispositivos móveis */}
      {isLoggedIn && (
        <div className={`hamburger-menu ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      )}
    </header>
  );
};

export default Header;
