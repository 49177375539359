import React from 'react';
import Sidebar from './dashboard/Sidebar';
import Header from './dashboard/Header';
import ServicesContent from './ServicesContente'

import './Dashboard.css';


const App = () => {
  return (
    <div className="app">
      <Sidebar />      
      <div className="main-content">        
        <Header />        
        <ServicesContent/>             
      </div>      
    </div>
  );
};

export default App;
