import React from 'react';
// import Sidebar from './dashboard/Sidebar';
// import Header from './dashboard/Header';
import ServicesContent from './ServicesContente';
import { useParams } from 'react-router-dom';
import theme from './Tema';
import { ThemeProvider } from '@mui/material/styles';
import {
  Box
} from '@mui/material';



const Services = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}> 
      <ServicesContent />           
      </Box>
    </ThemeProvider>
  );
};

export default Services;
