import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import {
  Card,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Chip,
  Divider,
  Grid,
  Box,
  TextField,
  Snackbar,
  IconButton,
  Paper,
  Tabs,
  Tab,
  Alert,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ErrorIcon from '@mui/icons-material/Error';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import BusinessIcon from '@mui/icons-material/Business';
import { alpha } from '@mui/material/styles';

const customJwtDecode = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c =>
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));
    return JSON.parse(jsonPayload);
  } catch (error) {
    throw new Error('Invalid token');
  }
};

const MaintenanceDashboard = () => {
  const { supplierId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [supplierDetails, setSupplierDetails] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const bffUrl = `${process.env.REACT_APP_API_BASE_URL}/prestadores/fornecedor.php`;

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (!token) {
      setError('Token não encontrado.');
      setLoading(false);
      return;
    }

    let userId;
    try {
      const decodedToken = customJwtDecode(token);
      userId = decodedToken.user.id;
    } catch (e) {
      setError('Erro ao decodificar o token.');
      setLoading(false);
      return;
    }

    if (supplierId && userId) {
      fetchSupplierDetails(userId);
    } else {
      setError('ID do fornecedor não encontrado.');
      setLoading(false);
    }
  }, [supplierId, location.search]); // Adicionadas dependências corretas

  const fetchSupplierDetails = async (userId) => {
    try {
      const response = await fetch(`${bffUrl}?action=getSupplierHistory&supplier_id=${supplierId}&user_id=${userId}`);
      const data = await response.json();

      // console.log("AQUI MEU  " + JSON.stringify(data));

      if (Array.isArray(data) && data.length > 0) {
        const supplierInfo = data[0];
        setSupplierDetails({
          supplierName: supplierInfo.supplier_name || 'Fornecedor Indisponível',
          serviceType: supplierInfo.service_type,
          locationLat: supplierInfo.location_lat,
          locationLong: supplierInfo.location_long,
          rating: supplierInfo.rating,
          reviews: supplierInfo.reviews,
          createdAt: supplierInfo.created_at,
          status: supplierInfo.status,
          cnpj: supplierInfo.cnpj,
          email: supplierInfo.email,
          telefone: supplierInfo.telefone,
          endereco: `${supplierInfo.logradouro || ''}, ${supplierInfo.numero || ''} - ${supplierInfo.bairro || ''}, ${supplierInfo.municipio || ''} - ${supplierInfo.uf || ''}`,
          naturezaJuridica: supplierInfo.natureza_juridica || 'N/A',
          capitalSocial: supplierInfo.capital_social || 'N/A',
          tipo: supplierInfo.tipo || 'N/A',
          porte: supplierInfo.porte || 'N/A',
          atividadePrincipal: supplierInfo.atividade_principal_text || 'N/A',
          dataAbertura: supplierInfo.abertura ? new Date(supplierInfo.abertura).toLocaleDateString() : 'N/A',
        });

        const groupedVehicles = data.reduce((acc, entry) => {
          const { plate } = entry;
          if (!acc[plate]) {
            acc[plate] = {
              vehicleDetails: {
                vehicleName: entry.vehicle_name || 'Veículo Indisponível',
                vehicleModel: entry.model || 'Modelo Indisponível',
                vehiclePlate: plate,
                vehicleColor: entry.color || 'Cor Indisponível',
                vehicleYear: entry.year || 'Ano Indisponível',
                vehicleOdometer: entry.odometer || 0,
                consumption: entry.consumption || 'N/A',
                tipoVeiculo: entry.tipo_veiculo || 'N/A',
                chassis: entry.chassi || 'N/A',
                potencia: entry.potencia || 'N/A',
                cargaMaxima: entry.carga || 'N/A',
                quantidadeLugares: entry.quantidade_lugares || 'N/A',
                ultimaManutencao: entry.ultima_manutencao || 'N/A',
              },
              history: [],
            };
          }

          const existingService = acc[plate].history.find(service => service.idOrcamento === entry.id_orcamento);
          if (!existingService && entry.id_orcamento) {
            acc[plate].history.push({
              maintenanceType: entry.maintenance_type,
              scheduledDate: entry.scheduled_date,
              schedule_id: entry.schedule_id,
              nextServiceDistance: entry.next_service_distance,
              status: entry.orcamento_status,
              idOrcamento: entry.id_orcamento,
              dataCriacao: entry.orcamento_data_criacao,
              valorTotal: entry.orcamento_valor_total,
              observacao: entry.orcamento_observacao,
              paymentMethod: entry.payment_method || 'N/A',
              paymentStatus: entry.payment_status || 'N/A',
              notes: entry.notes || 'N/A',
              serviceDuration: entry.service_duration || 'N/A',
              lastMaintenanceDate: entry.last_maintenance_date || 'N/A',
            });
          }

          return acc;
        }, {});

        setVehicles(Object.values(groupedVehicles));
        setError(null);
      } else {
        setError('Dados do fornecedor não encontrados.');
      }
    } catch (err) {
      setError('Erro ao buscar histórico do fornecedor.');
      console.error('Erro:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleBudgetLinkClick = (scheduleId, index) => {
    if (scheduleId) {
      window.open(`/loja/servicos/orcamento/detalhes/${scheduleId}`, '_blank');
    } else {
      setError('ID do orçamento não encontrado.');
      setSnackbarOpen(true);
    }
  };

  // Função handleRequestBudget atualizada para aceitar plate e scheduleId
  const handleRequestBudget = (plate, scheduleId) => {
    
    if(scheduleId=== undefined || scheduleId === 0){
      setError('Cliente não solicitou nenhum atendimento!');
      setSnackbarOpen(true);
    }else{
      window.open(`/loja/servicos/orcamento/${scheduleId}/${plate}`, '_blank');
    }
    
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const SupplierCard = () => (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          <BusinessIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
          Informações do Fornecedor
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ '& > *': { mb: 1 } }}>
              <Typography><strong>Nome:</strong> {supplierDetails?.supplierName}</Typography>
              <Typography><strong>CNPJ:</strong> {supplierDetails?.cnpj || 'N/A'}</Typography>
              <Typography><strong>Email:</strong> {supplierDetails?.email}</Typography>
              <Typography><strong>Telefone:</strong> {supplierDetails?.telefone}</Typography>
              <Typography><strong>Endereço:</strong> {supplierDetails?.endereco}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ '& > *': { mb: 1 } }}>
              <Typography><strong>Status:</strong> {supplierDetails?.status}</Typography>
              <Typography><strong>Avaliação:</strong> {supplierDetails?.rating} ⭐</Typography>
              <Typography><strong>Tipo:</strong> {supplierDetails?.tipo}</Typography>
              <Typography><strong>Porte:</strong> {supplierDetails?.porte}</Typography>
              <Typography><strong>Data Abertura:</strong> {supplierDetails?.dataAbertura}</Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  // Componente VehicleCard atualizado para passar o scheduleId
  const VehicleCard = ({ vehicle }) => {
    const [scheduleId, setScheduleId] = useState(vehicle.history[0]?.schedule_id || null);
  
    useEffect(() => {
      // Função para buscar o schedule_id caso não esteja disponível no histórico
      const fetchScheduleId = async () => {
        if (!scheduleId && vehicle.history[0]?.scheduledDate) {
          try {
            // console.log("URL USADA " + `https://dojo.stratusport.co/torki/maintenance_schedule.php?plate=${vehicle.vehicleDetails.vehiclePlate}&scheduled_date=${vehicle.history[0].scheduledDate}`);

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/maintenance_schedule.php?plate=${vehicle.vehicleDetails.vehiclePlate}&scheduled_date=${vehicle.history[0].scheduledDate}`);
            const data = await response.json();
            if (data && data.schedule_id) {
              setScheduleId(data.schedule_id);
            }
          } catch (error) {
            console.error("Erro ao buscar o schedule_id:", error);
          }
        }
      };
  
      fetchScheduleId();
    }, [scheduleId, vehicle.history, vehicle.vehicleDetails.vehiclePlate]);
  
    const handleRequestBudget = () => {
      if (scheduleId) {
        window.open(`/loja/servicos/orcamento/${scheduleId}/${vehicle.vehicleDetails.vehiclePlate}`, '_blank');
      } else {
        window.open(`/loja/servicos/orcamento/${vehicle.vehicleDetails.vehiclePlate}`, '_blank');
      }
    };
  
    return (
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <DirectionsCarIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {vehicle.vehicleDetails.vehicleName}
            </Typography>
            <Chip 
              label={vehicle.vehicleDetails.vehiclePlate} 
              color="primary" 
              variant="outlined"
            />
          </Box>
  
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">Modelo</Typography>
              <Typography>{vehicle.vehicleDetails.vehicleModel}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">Hodômetro</Typography>
              <Typography>{vehicle.vehicleDetails.vehicleOdometer} km</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">Última Manutenção</Typography>
              <Typography>{vehicle.vehicleDetails.ultimaManutencao}</Typography>
            </Grid>
          </Grid>
  
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleRequestBudget}
            sx={{ mb: 2 }}
          >
            {scheduleId ? "Ver Orçamento" : "Solicitar Novo Orçamento"}
          </Button>
  
          <Divider sx={{ my: 2 }} />
  
          <Typography variant="subtitle1" gutterBottom>
            Histórico de Manutenções
          </Typography>
  
          {vehicle.history.length > 0 ? (
            vehicle.history.map((service, idx) => (
              <Accordion key={idx} sx={{ mb: 1 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    {service.status === 'Paid' && <CheckCircleIcon color="success" sx={{ mr: 1 }} />}
                    {service.status === 'Pending' && <ScheduleIcon color="warning" sx={{ mr: 1 }} />}
                    {service.status === 'vencido' && <ErrorIcon color="error" sx={{ mr: 1 }} />}
                    <Typography>{service.maintenanceType}</Typography>
                    <Chip 
                      label={service.status}
                      size="small"
                      sx={{ ml: 'auto' }}
                      color={
                        service.status === 'Paid' ? 'success' :
                        service.status === 'Pending' ? 'warning' : 'default'
                      }
                    />
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ '& > *': { mb: 1 } }}>
                    <Typography><strong>Data Agendada:</strong> {service.scheduledDate ? new Date(service.scheduledDate).toLocaleDateString() : 'Não disponível'}</Typography>
                    <Typography><strong>Valor:</strong> R$ {service.valorTotal || 'Não disponível'}</Typography>
                    <Typography><strong>Próxima Revisão:</strong> {service.nextServiceDistance ? `${service.nextServiceDistance} km` : 'Não disponível'}</Typography>
                    {service.observacao && (
                      <Typography><strong>Observações:</strong> {service.observacao}</Typography>
                    )}
                    <Button
                      variant="outlined"
                      startIcon={<AttachMoneyIcon />}
                      onClick={() => handleRequestBudget(service.vehiclePlate, service.schedule_id)}
                      sx={{ mt: 1 }}
                    >
                      Ver Orçamento
                    </Button>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Typography color="textSecondary">Nenhum histórico disponível</Typography>
          )}
        </CardContent>
      </Card>
    );
  };
  

  const filteredVehicles = vehicles.filter(vehicle =>
    vehicle.vehicleDetails.vehicleName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    vehicle.vehicleDetails.vehiclePlate.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box
          sx={{
            minHeight: '100vh',
            background: `linear-gradient(135deg, ${alpha('#0A192F', 0.95)} 0%, ${alpha('#112240', 0.95)} 100%)`,
            py: 4,
            px: 2
          }}
        >
      <Paper sx={{ mb: 3, p: 2 }}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          centered
          sx={{ mb: 2 }}
        >
          <Tab icon={<DirectionsCarIcon />} label="Veículos" />
          <Tab icon={<BusinessIcon />} label="Fornecedor" />
        </Tabs>

        <TextField
          fullWidth
          placeholder="Pesquisar por nome ou placa do veículo..."
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon sx={{ mr: 1, color: 'action.active' }} />,
          }}
        />
      </Paper>

      {loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      ) : (
        <>
          {activeTab === 0 ? (
            <Grid container spacing={3}>
              {filteredVehicles.map((vehicle, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <VehicleCard vehicle={vehicle} />
                </Grid>
              ))}
              {filteredVehicles.length === 0 && (
                <Grid item xs={12}>
                  <Alert severity="info">
                    Nenhum veículo encontrado com os critérios de busca informados.
                  </Alert>
                </Grid>
              )}
            </Grid>
          ) : (
            <SupplierCard />
          )}
        </>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        action={
          <IconButton
            size="small"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MaintenanceDashboard;