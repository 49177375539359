// AtendimentoTicket.js
import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Grid, 
  Card, 
  CardContent, 
  Typography, 
  CircularProgress, 
  Snackbar, 
  IconButton, 
  Chip, 
  Divider, 
  Button, 
  Link, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  TextField 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BusinessIcon from '@mui/icons-material/Business';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DateRangeIcon from '@mui/icons-material/DateRange';

const AtendimentoTicket = ({ ticket, onUpdateStatus }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [reScheduleData, setReScheduleData] = useState({ id: ticket.id, date: '', time: '' });
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState(null);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleReSchedule = () => {
    const { date, time } = reScheduleData;
    if (!date || !time) {
      setError('Por favor, selecione uma data e horário válidos para reagendamento.');
      setSnackbarOpen(true);
      return;
    }

    const newDateTime = `${date} ${time}`;
    onUpdateStatus(ticket.id, 'agendado', newDateTime);
    setOpenDialog(false); // Fecha o modal após reagendamento
  };

  const handleOpenDialog = () => {
    setReScheduleData({ id: ticket.id, date: '', time: '' });
    setOpenDialog(true);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: `linear-gradient(135deg, #0A192F 0%, #112240 100%)`,
        py: 4,
        px: 2
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ color: '#1976d2' }}>
        Atendimento - {ticket.title}
      </Typography>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={error || 'Operação realizada com sucesso'}
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Card 
            sx={{ 
              borderRadius: 2, 
              transition: 'all 0.3s ease',
              '&:hover': { transform: 'scale(1.00)' },
              height: '100%'
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ 
                fontWeight: 'bold', 
                color: '#1976d2', 
                marginBottom: 1,
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}>
                <DirectionsCarIcon />
                {ticket.maintenance_type}
              </Typography>
              
              <Divider sx={{ marginY: 2 }} /> 
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <DirectionsCarIcon sx={{ color: '#1976d2' }} />
                  <Typography variant="body2" sx={{ fontSize: 20 }}>
                    Placa: {ticket.plate}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <DateRangeIcon sx={{ color: '#1976d2' }} />
                  <Typography variant="body2">
                    {new Date(ticket.scheduled_date).toLocaleDateString()} às {new Date(ticket.scheduled_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Chip 
                    label={ticket.status.toUpperCase()} 
                    color={ticket.status === 'confirmado' ? 'success' : 'primary'}
                    sx={{ fontWeight: 'bold' }}
                  />
                </Box>

                <Typography variant="body2">
                  <strong>Próxima Distância:</strong> {ticket.next_service_distance} km
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <BusinessIcon sx={{ color: '#1976d2' }} />
                  <Link href={`/supplier/${ticket.supplier_id}`} underline="hover" sx={{ color: '#1976d2' }}>
                    {ticket.supplier_name}
                  </Link>
                </Box>

                <Typography variant="body2">
                  <strong>Avaliação:</strong> {ticket.supplier_rating} ({ticket.supplier_reviews} reviews)
                </Typography>

                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={ticket.status}
                    label="Status"
                    onChange={(e) => onUpdateStatus(ticket.id, e.target.value)}
                  >
                    <MenuItem value="confirmado">Confirmado</MenuItem>
                    <MenuItem value="agendado">Agendado</MenuItem>
                    <MenuItem value="concluido">Concluído</MenuItem>
                    <MenuItem value="cancelado">Cancelado</MenuItem>
                  </Select>
                </FormControl>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, marginTop: 2 }}>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    startIcon={<ScheduleIcon />}
                    onClick={handleOpenDialog}
                    fullWidth
                  >
                    Reagendar
                  </Button>
                  <Button 
                    variant="contained" 
                    color="success" 
                    startIcon={<CheckCircleIcon />}
                    onClick={() => onUpdateStatus(ticket.id, 'confirmado')}
                    fullWidth
                  >
                    Confirmar
                  </Button>
                </Box>

                <Divider sx={{ marginY: 2 }} />
                <Typography variant="caption" sx={{ color: '#9e9e9e', textAlign: 'center', marginTop: 2 }}>
                  Powered by Torki
                </Typography>
              </Box>
            </CardContent>                
          </Card>
        </Grid>
      </Grid>

      {/* Modal para Reagendamento */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Reagendar Atendimento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, insira a nova data e hora para o reagendamento.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Data"
            type="date"
            fullWidth
            variant="outlined"
            value={reScheduleData.date}
            onChange={(e) => setReScheduleData({ ...reScheduleData, date: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Hora"
            type="time"
            fullWidth
            variant="outlined"
            value={reScheduleData.time}
            onChange={(e) => setReScheduleData({ ...reScheduleData, time: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
          <Button onClick={handleReSchedule}>Reagendar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AtendimentoTicket;
