// FinanceiroTicket.js
import React from 'react';
import { Card, CardContent, Typography, Divider } from '@mui/material';

const FinanceiroTicket = ({ ticket }) => (
  <Card variant="outlined" sx={{ backgroundColor: 'background.paper', borderRadius: 2 }}>
    <CardContent>
      <Typography variant="h6" color="text.primary">
        Detalhes Financeiros
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant="body2" color="text.secondary" mb={1}>
        <strong>Cliente:</strong> {ticket.clientName}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={1}>
        <strong>Tipo de Transação:</strong> {ticket.transactionType}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={1}>
        <strong>Valor:</strong> {ticket.amount}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={1}>
        <strong>Data da Transação:</strong> {new Date(ticket.transactionDate).toLocaleDateString()}
      </Typography>
    </CardContent>
  </Card>
);

export default FinanceiroTicket;
