import React, { useState } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';

import {
  AppBar,
  Box,
  Card,
  CardContent,
  Container,
  CssBaseline,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Toolbar,
  Typography,
  createTheme,
  alpha,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  Dashboard as DashboardIcon,
  CalendarMonth as CalendarIcon,
  Inventory as InventoryIcon,
  ShoppingCart as CartIcon,
  AccountBalance as FinanceIcon,
  People as PeopleIcon,
  DirectionsCar as CarIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import DashboardMarket from './marketplace/DashboardMarket';


// Theme configuration
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#F97316',
    },
    secondary: {
      main: '#0A192F',
    },
    background: {
      default: '#0A192F',
      paper: '#112240',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: alpha('#112240', 0.7),
          backdropFilter: 'blur(10px)',
          borderRadius: 16,
          transition: 'transform 0.2s ease-in-out',
          '&:hover': {
            transform: 'translateY(-4px)',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: alpha('#0A192F', 0.8),
          backdropFilter: 'blur(10px)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#0A192F',
          borderRight: '1px solid rgba(255, 255, 255, 0.05)',
        },
      },
    },
  },
});

// Styled components
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 2),
}));

const GlassCard = styled(Card)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: 'blur(10px)',
  borderRadius: 16,
  border: '1px solid rgba(255, 255, 255, 0.05)',
}));


// Mock data
const mockData = {
  salesData: [
    { month: 'Jan', services: 4000, products: 2400, total: 6400 },
    { month: 'Feb', services: 3000, products: 1398, total: 4398 },
    { month: 'Mar', services: 2000, products: 9800, total: 11800 },
    { month: 'Apr', services: 2780, products: 3908, total: 6688 },
    { month: 'May', services: 1890, products: 4800, total: 6690 },
    { month: 'Jun', services: 2390, products: 3800, total: 6190 },
  ],
  appointments: [
    { id: 1, customer: 'João Silva', service: 'Troca de Óleo', time: '14:00', date: '2024-11-03', status: 'pending' },
    { id: 2, customer: 'Maria Santos', service: 'Revisão Geral', time: '15:30', date: '2024-11-03', status: 'confirmed' },
    { id: 3, customer: 'Carlos Oliveira', service: 'Alinhamento', time: '09:00', date: '2024-11-04', status: 'pending' },
  ],
  products: [
    { name: 'Óleo Motor 5W30', stock: 45, sales: 23, revenue: 2070 },
    { name: 'Filtro de Ar', stock: 32, sales: 15, revenue: 750 },
    { name: 'Pastilha de Freio', stock: 28, sales: 12, revenue: 1440 },
  ],
};

const DashboardContent = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate(); // Hook para navegação
  const location = useLocation();

  const drawerWidth = 280;


// Obtenha o token da URL
const token = new URLSearchParams(location.search).get('token');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: `/dashboard?token=${token}`, active: true },
    { text: 'Agendamentos', icon: <CalendarIcon />, link: `/agendamentos?token=${token}` },
    { text: 'Produtos', icon: <InventoryIcon />, link: `/produtos?token=${token}` },
    { text: 'Marketplace', icon: <CartIcon />, link: `/loja?token=${token}` }, // Use CartIcon aqui
    { text: 'Financeiro', icon: <FinanceIcon />, link: `/financeiro?token=${token}` },
    { text: 'Clientes', icon: <PeopleIcon />, link: `/clientes?token=${token}` },
  ];
  

  const drawer = (
    <Box>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
        <CarIcon sx={{ color: 'primary.main' }} />
        <Typography variant="h6" noWrap component="div">
          Torki Partner
        </Typography>
      </Box>
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            onClick={() => {
              navigate(item.link); // Navega para o link
              setMobileOpen(false); // Fecha o drawer em dispositivos móveis
            }}
            sx={{
              mx: 1,
              borderRadius: 2,
              mb: 1,
              ...(item.active && {
                bgcolor: 'primary.main',
                '&:hover': {
                  bgcolor: alpha('#F97316', 0.8),
                },
              }),
            }}
          >
            <ListItemIcon sx={{ color: item.active ? 'white' : 'primary.main' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <StyledToolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton color="inherit">
              <NotificationsIcon />
            </IconButton>
            <IconButton color="inherit">
              <SettingsIcon />
            </IconButton>
            <IconButton color="inherit">
              <LogoutIcon />
            </IconButton>
          </Box>
        </StyledToolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer 
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          minHeight: '100vh',
          background: `linear-gradient(135deg, ${alpha('#0A192F', 0.95)} 0%, ${alpha('#112240', 0.95)} 100%)`,
        }}
      >
        <Toolbar />
        <Container maxWidth="xl">
          {/* Summary Cards */}
          <Grid container spacing={3} sx={{ mb: 4 }}>
            {[
              { title: 'Vendas Hoje', value: 'R$ 4.890,00', trend: '+12%' },
              { title: 'Agendamentos', value: '18', trend: '+5' },
              { title: 'Produtos Ativos', value: '124', trend: '+3' },
              { title: 'Clientes Novos', value: '47', trend: '+8' },
            ].map((item) => (
              <Grid item xs={12} sm={6} md={3} key={item.title}>
                <GlassCard>
                  <CardContent>
                    <Typography color="text.secondary" gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography variant="h4" component="div" sx={{ mb: 1 }}>
                      {item.value}
                    </Typography>
                    <Typography color="primary" variant="body2">
                      {item.trend} este mês
                    </Typography>
                  </CardContent>
                </GlassCard>
              </Grid>
            ))}
          </Grid>

          {/* Charts */}
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} md={8}>
              <GlassCard sx={{ height: 400 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Vendas Mensais
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={mockData.salesData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip 
                        contentStyle={{ 
                          backgroundColor: alpha('#112240', 0.9),
                          border: '1px solid rgba(255, 255, 255, 0.1)',
                        }} 
                      />
                      <Legend />
                      <Bar dataKey="services" name="Serviços" fill="#F97316" />
                      <Bar dataKey="products" name="Produtos" fill="#3B82F6" />
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </GlassCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <GlassCard sx={{ height: 400 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Receita Total
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={mockData.salesData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip 
                        contentStyle={{ 
                          backgroundColor: alpha('#112240', 0.9),
                          border: '1px solid rgba(255, 255, 255, 0.1)',
                        }} 
                      />
                      <Legend />
                      <Line type="monotone" dataKey="total" name="Total" stroke="#F97316" />
                    </LineChart>
                  </ResponsiveContainer>
                </CardContent>
              </GlassCard>
            </Grid>
          </Grid>

          {/* Tables */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <GlassCard>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Próximos Agendamentos
                  </Typography>
                  <List>
                    {mockData.appointments.map((appointment) => (
                      <ListItem
                        key={appointment.id}
                        sx={{
                          mb: 2,
                          borderRadius: 2,
                          bgcolor: alpha('#1E293B', 0.3),
                        }}
                      >
                        <ListItemText
                          primary={appointment.customer}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                color="primary"
                              >
                                {appointment.service}
                              </Typography>
                              {` — ${appointment.date} às ${appointment.time}`}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </GlassCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <GlassCard>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Produtos em Destaque
                  </Typography>
                  <List>
                    {mockData.products.map((product) => (
                      <ListItem
                        key={product.name}
                        sx={{
                          mb: 2,
                          borderRadius: 2,
                          bgcolor: alpha('#1E293B', 0.3),
                        }}
                      >
                        <ListItemText
                          primary={product.name}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                color="primary"
                              >
                                {`Vendas: ${product.sales} | `}
                              </Typography>
                              {`Estoque: ${product.stock} | Receita: R$ ${product.revenue}`}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </GlassCard>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

const Dashboard = () => {
  return (
    <ThemeProvider theme={theme}>
      <DashboardContent />
    </ThemeProvider>
  );
};

export default Dashboard;