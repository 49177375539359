import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Container,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useScrollTrigger,
  Slide,
  Typography,
  Badge,
  Chip,
  Divider,
  Tooltip,
  Avatar,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  Dashboard,
  Inventory,
  ShoppingCart,
  QuestionAnswer,
  Business,
  People,
  AttachMoney,
  Menu as MenuIcon,
  NotificationsActive,
  Whatshot,
  Close,
  ArrowUpward,
  Campaign,
  Star,
  TrendingUp,
  Circle
} from '@mui/icons-material';
import { AnimatePresence, motion } from 'framer-motion';
import { jwtDecode } from 'jwt-decode';

const quickStats = [
  { label: 'Vendas Hoje', value: 'R$ 12.458', trend: '+15%' },
  { label: 'Visitas', value: '1.2k', trend: '+8%' },
  { label: 'Conversão', value: '4.2%', trend: '+2%' },
];

const ads = [
  {
    title: '🔥 Black Friday antecipada!',
    description: 'Prepare seu estoque para a maior venda do ano',
    action: 'Saiba mais',
    color: '#FF6B6B',
  },
  {
    title: '⭐ Premium Seller',
    description: 'Desbloqueie recursos exclusivos',
    action: 'Upgrade',
    color: '#4ECDC4',
  },
  {
    title: '📢 Novo: Marketing Automation',
    description: 'Aumente suas vendas com automação',
    action: 'Ativar',
    color: '#FFD93D',
  },
];

const EnhancedFloatingMenu = () => {
  const [anchorEls, setAnchorEls] = useState({});
  const [showAd, setShowAd] = useState(true);
  const [adIndex, setAdIndex] = useState(0);
  const [activeItem, setActiveItem] = useState('Dashboard');
  const [hoveredItem, setHoveredItem] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const trigger = useScrollTrigger();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Extract token from URL params
  const token = new URLSearchParams(location.search).get('token');

  // Decode token and extract user info on component mount
  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserInfo(decoded.user);
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
  }, [token]);

  // Effect for ad rotation
  useEffect(() => {
    const timer = setInterval(() => {
      setAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  // Dynamic menu configuration based on user info
  const getMenuConfig = () => [
    {
      title: 'Dashboard',
      icon: <Dashboard fontSize="small" />,
      path: '/dashboard',
      items: [
        { title: 'Visão Geral', path: `/loja` }
      ]
    },
    {
      title: 'Serviços',
      icon: <Business fontSize="small" />,
      items: [
        { 
          title: 'Solicitações', 
          path: userInfo ? `/loja/servicos/${userInfo.id}` : '/loja/servicos'
        },
        { title: 'Em Andamento', path: '/services/in-progress' },
        { title: 'Concluídos', path: '/services/completed' }
      ]
    },
    {
      title: 'Fornecedores',
      icon: <People fontSize="small" />,
      items: [
        { title: 'Cadastrar', path: '/loja/fornecedores/cadastro' }
        // { title: 'Listar', path: '/suppliers/list' },
        // { title: 'Avaliações', path: '/suppliers/ratings' }
      ]
    },
    {
      title: 'Financeiro',
      icon: <AttachMoney fontSize="small" />,
      items: [
        { title: 'Faturamento', path: '/financial/billing' },
        { title: 'Pagamentos', path: '/financial/payments' },
        { title: 'Relatórios', path: '/financial/reports' }
      ]
    },
    {
      title: 'Produtos',
      icon: <Inventory fontSize="small" />,
      items: [
        { title: 'Cadastrar', path: '/products/register' },
        { title: 'Listar', path: '/products/list' },
        { title: 'Categorias', path: '/products/categories' },
        { title: 'Estoque', path: '/products/inventory' }
      ]
    }
  ];

  const menuItemVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
    hover: { scale: 1.05, x: 10 }
  };

  const handleMenuOpen = (event, menuTitle) => {
    setAnchorEls(prev => ({
      ...prev,
      [menuTitle]: event.currentTarget
    }));
  };

  const handleMenuClose = (menuTitle) => {
    setAnchorEls(prev => ({
      ...prev,
      [menuTitle]: null
    }));
  };

  const handleNavigate = (path, title) => {
    // Ensure token is appended to all navigation paths
    const hasQueryParams = path.includes('?');
    const tokenParam = `token=${token}`;
    const navigatePath = hasQueryParams ? `${path}&${tokenParam}` : `${path}?${tokenParam}`;
    
    navigate(navigatePath);
    setActiveItem(title);
    handleMenuClose(title);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          top: 0,
          backgroundColor: 'transparent',
          backdropFilter: 'blur(16px)',
          borderBottom: '1px solid',
          borderColor: 'rgba(255, 255, 255, 0.12)',
          background: alpha('#112240', 0.94),
        }}
      >
        <Container maxWidth="xl">
          {/* Advertisement Banner */}
          <AnimatePresence mode="wait">
            {showAd && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Box
                  sx={{
                    py: 1.5,
                    px: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    background: alpha(ads[adIndex].color, 0.15),
                    borderBottom: '1px solid',
                    borderColor: alpha(ads[adIndex].color, 0.25),
                    borderRadius: '0 0 12px 12px',
                  }}
                >
                  <motion.div
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Campaign sx={{ mr: 1.5, color: ads[adIndex].color }} />
                  </motion.div>
                  <Typography variant="subtitle2" sx={{ color: '#fff', fontWeight: 600 }}>
                    {ads[adIndex].title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mx: 2, color: alpha('#fff', 0.8), display: { xs: 'none', sm: 'block' } }}
                  >
                    {ads[adIndex].description}
                  </Typography>
                  <Button
                    size="small"
                    sx={{
                      ml: 2,
                      px: 2,
                      py: 0.5,
                      bgcolor: alpha(ads[adIndex].color, 0.25),
                      color: '#fff',
                      borderRadius: '20px',
                      '&:hover': {
                        bgcolor: alpha(ads[adIndex].color, 0.35),
                        transform: 'translateY(-1px)',
                        transition: 'all 0.2s',
                      },
                    }}
                  >
                    {ads[adIndex].action}
                  </Button>
                  <IconButton
                    size="small"
                    onClick={() => setShowAd(false)}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      color: alpha('#fff', 0.6),
                      '&:hover': {
                        color: '#fff',
                        transform: 'rotate(90deg)',
                        transition: 'all 0.3s',
                      },
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </Box>
              </motion.div>
            )}
          </AnimatePresence>

          <Toolbar disableGutters sx={{ flexDirection: { xs: 'column', md: 'row' }, py: 1.5 }}>
            {/* Mobile Menu */}
            <Box sx={{ display: { xs: 'flex', md: 'none' }, width: '100%', justifyContent: 'space-between', mb: 1 }}>
              <IconButton
                size="large"
                onClick={(e) => handleMenuOpen(e, 'mobile')}
                sx={{
                  color: '#fff',
                  '&:hover': {
                    bgcolor: alpha('#fff', 0.1),
                  },
                }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEls['mobile']}
                open={Boolean(anchorEls['mobile'])}
                onClose={() => handleMenuClose('mobile')}
                PaperProps={{
                  sx: {
                    backgroundColor: alpha('#112240', 0.98),
                    backdropFilter: 'blur(16px)',
                    border: '1px solid',
                    borderColor: 'rgba(255, 255, 255, 0.12)',
                    borderRadius: '12px',
                    color: '#E0E0E0',
                    mt: 2,
                    '& .MuiList-root': {
                      py: 1,
                    },
                  },
                }}
              >
                {getMenuConfig().map((item) => (
                  <motion.div
                    key={item.title}
                    variants={menuItemVariants}
                    initial="hidden"
                    animate="visible"
                    whileHover="hover"
                    transition={{ duration: 0.2 }}
                  >
                    <MenuItem
                      onClick={(e) => handleMenuOpen(e, `mobile-${item.title}`)}
                      sx={{
                        color: activeItem === item.title ? '#F97316' : 'inherit',
                        borderRadius: '8px',
                        mx: 1,
                        my: 0.5,
                        px: 2,
                        '&:hover': {
                          bgcolor: alpha('#F97316', 0.1),
                        },
                      }}
                    >
                      {item.icon}
                      <Typography sx={{ ml: 1.5, fontWeight: 500 }}>{item.title}</Typography>
                    </MenuItem>
                    <Menu
                      anchorEl={anchorEls[`mobile-${item.title}`]}
                      open={Boolean(anchorEls[`mobile-${item.title}`])}
                      onClose={() => handleMenuClose(`mobile-${item.title}`)}
                      PaperProps={{
                        sx: {
                          backgroundColor: alpha('#112240', 0.98),
                          backdropFilter: 'blur(16px)',
                          border: '1px solid',
                          borderColor: 'rgba(255, 255, 255, 0.12)',
                          borderRadius: '12px',
                          color: '#E0E0E0',
                          mt: 1,
                          '& .MuiList-root': {
                            py: 1,
                          },
                        },
                      }}
                    >
                      {item.items.map((subItem) => (
                        <MenuItem
                          key={subItem.title}
                          onClick={() => handleNavigate(subItem.path, item.title)}
                          sx={{
                            borderRadius: '8px',
                            mx: 1,
                            my: 0.5,
                            px: 2,
                            '&:hover': {
                              bgcolor: alpha('#F97316', 0.1),
                            },
                          }}
                        >
                          <Circle sx={{ fontSize: 8, mr: 1.5, color: '#F97316' }} />
                          {subItem.title}
                        </MenuItem>
                      ))}
                    </Menu>
                  </motion.div>
                ))}
              </Menu>
            </Box>

            {/* Desktop Menu */}
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 3,
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', gap: 1 }}>
                {getMenuConfig().map((item) => (
                  <Box key={item.title}>
                    <Tooltip title={`Acessar ${item.title}`} arrow>
                      <Button
                        onMouseEnter={() => setHoveredItem(item.title)}
                        onMouseLeave={() => setHoveredItem(null)}
                        onClick={(e) => handleMenuOpen(e, item.title)}
                        sx={{
                          color: activeItem === item.title ? '#F97316' : '#fff',
                          borderRadius: '8px',
                          px: 2,
                          py: 1,
                          position: 'relative',
                          overflow: 'hidden',
                          '&:hover': {
                            bgcolor: alpha('#F97316', 0.1),
                          },
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: activeItem === item.title ? '100%' : '0%',
                            height: '2px',
                            bgcolor: '#F97316',
                            transition: 'all 0.3s',
                          },
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {item.icon}
                          <Typography sx={{ fontWeight: 500 }}>{item.title}</Typography>
                        </Box>
                      </Button>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEls[item.title]}
                      open={Boolean(anchorEls[item.title])}
                      onClose={() => handleMenuClose(item.title)}
                      PaperProps={{
                        sx: {
                          backgroundColor: alpha('#112240', 0.98),
                          backdropFilter: 'blur(16px)',
                          border: '1px solid',
                          borderColor: 'rgba(255, 255, 255, 0.12)',
                          borderRadius: '12px',
                          color: '#E0E0E0',
                          mt: 1,
                          minWidth: '200px',
                          '& .MuiList-root': {
                            py: 1,
                          },
                        },
                      }}
                    >
                      {item.items.map((subItem) => (
                        <MenuItem
                          key={subItem.title}
                          onClick={() => handleNavigate(subItem.path, item.title)}
                          sx={{
                            borderRadius: '8px',
                            mx: 1,
                            my: 0.5,
                            px: 2,
                            '&:hover': {
                              bgcolor: alpha('#F97316', 0.1),
                            },
                          }}
                        >
                          <Circle sx={{ fontSize: 8, mr: 1.5, color: '#F97316' }} />
                          {subItem.title}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                ))}
              </Box>

              {/* Quick Stats */}
              <Box sx={{ display: 'flex', gap: 3, ml: 'auto', mr: 4 }}>
                {quickStats.map((stat) => (
                  <Tooltip key={stat.label} title={stat.label} arrow>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="h6" sx={{ color: '#fff', fontWeight: 600 }}>
                        {stat.value}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <Typography variant="caption" sx={{ color: alpha('#fff', 0.7) }}>
                          {stat.label}
                        </Typography>
                        <Chip
                          label={stat.trend}
                          size="small"
                          icon={<TrendingUp sx={{ fontSize: 14 }} />}
                          sx={{
                            bgcolor: alpha('#4CAF50', 0.2),
                            color: '#4CAF50',
                            height: 20,
                            '& .MuiChip-label': {
                              px: 1,
                              fontSize: '0.625rem',
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Tooltip>
                ))}
              </Box>

              {/* User Profile & Notifications */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Tooltip title="Notificações" arrow>
                  <IconButton
                    sx={{
                      color: '#fff',
                      '&:hover': {
                        bgcolor: alpha('#fff', 0.1),
                      },
                    }}
                  >
                    <Badge badgeContent={3} color="error">
                      <NotificationsActive />
                    </Badge>
                  </IconButton>
                </Tooltip>

                <Divider orientation="vertical" flexItem sx={{ borderColor: alpha('#fff', 0.12) }} />

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Avatar
                    src={userInfo?.avatar || ''}
                    alt={userInfo?.name || 'User'}
                    sx={{
                      width: 40,
                      height: 40,
                      border: '2px solid',
                      borderColor: alpha('#F97316', 0.5),
                    }}
                  />
                  <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <Typography sx={{ color: '#fff', fontWeight: 600 }}>
                      {userInfo?.name || 'Usuário'}
                    </Typography>
                    <Typography variant="caption" sx={{ color: alpha('#fff', 0.7) }}>
                      {userInfo?.role || 'Vendedor'}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={(e) => handleMenuOpen(e, 'profile')}
                    sx={{
                      color: '#fff',
                      '&:hover': {
                        bgcolor: alpha('#fff', 0.1),
                      },
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEls['profile']}
                    open={Boolean(anchorEls['profile'])}
                    onClose={() => handleMenuClose('profile')}
                    PaperProps={{
                      sx: {
                        backgroundColor: alpha('#112240', 0.98),
                        backdropFilter: 'blur(16px)',
                        border: '1px solid',
                        borderColor: 'rgba(255, 255, 255, 0.12)',
                        borderRadius: '12px',
                        color: '#E0E0E0',
                        mt: 1,
                        minWidth: '200px',
                        '& .MuiList-root': {
                          py: 1,
                        },
                      },
                    }}
                  >
                    <MenuItem onClick={handleLogout} sx={{ color: '#FF4842' }}>
                      Sair
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Slide>
  );
};

export default EnhancedFloatingMenu;