import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  IconButton,
  Snackbar,
  Alert,
  Stack,
  MenuItem,
  InputAdornment,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Divider,
  Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import ReceiptIcon from '@mui/icons-material/Receipt';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

const ServiceDetailsOrcamento = () => {
  const { plate,id_fornecedor } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Estados
  const [activeStep, setActiveStep] = useState(0);
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState('');
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([{ product_description: '', quantity: 1, unit_price: '' }]);
  const [laborCost, setLaborCost] = useState('');
  const [showReview, setShowReview] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [savingBudget, setSavingBudget] = useState(false);

  const steps = ['Selecionar Serviço', 'Adicionar Itens', 'Revisão'];

  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        const response = await fetch(`${BASE_URL}/maintenance_schedule.php?plate=${plate}&id_fornecedor=${id_fornecedor}`);
        if (!response.ok) throw new Error('Falha ao carregar agendamentos');
        const data = await response.json();
        setSchedules(data);
        if (data.length > 0) setSelectedSchedule(data[0].id);
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Erro ao carregar agendamentos: ' + error.message,
          severity: 'error'
        });
      } finally {
        setLoading(false);
      }
    };
    fetchSchedules();
  }, [plate]);

  const handleAddItem = () => {
    setItems([...items, { product_description: '', quantity: 1, unit_price: '' }]);
  };

  const handleRemoveItem = (index) => {
    if (items.length > 1) {
      setItems(items.filter((_, i) => i !== index));
    }
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setItems(updatedItems);
  };

  const calculateTotal = () => {
    const itemsTotal = items.reduce((sum, item) => 
      sum + (Number(item.quantity) * Number(item.unit_price) || 0), 0);
    return itemsTotal + Number(laborCost || 0);
  };

  const handleSave = async () => {
    if (!selectedSchedule) {
        setSnackbar({
            open: true,
            message: 'Selecione um agendamento primeiro',
            severity: 'warning'
        });
        return;
    }

    setSavingBudget(true);
    const schedule = schedules.find(s => s.id === selectedSchedule);

    try {
        // Create budget payload
        const orcamentoPayload = {
            schedule_id: id_fornecedor,
            data_criacao: new Date().toISOString().slice(0, 10),
            data_vencimento: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
            status: 'pendente',
            valor_total: calculateTotal(),
            observacao: `Orçamento para ${schedule.maintenance_type}`
        };

        // First API call to create budget and get the `orcamento_id`
        const budgetResponse = await fetch(`${BASE_URL}/prestadores/orcamentos.php?action=create`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(orcamentoPayload)
        });

        const budgetResult = await budgetResponse.json();

        console.log("budgetResult " + JSON.stringify(budgetResult));

        if (!budgetResult.success || !budgetResult.orcamento_id) {
            throw new Error('Failed to create budget');
        }

        const orcamentoId = budgetResult.orcamento_id;

        // Prepare items payload with `orcamento_id`
        const detailsPayload = items.map(item => ({
            schedule_id: selectedSchedule,
            product_description: item.product_description,
            quantity: Number(item.quantity),
            unit_price: Number(item.unit_price),
            labor_description: 'Mão de obra',
            labor_cost: Number(laborCost || 0),
            service_date: schedule.scheduled_date,
            discount: 0,
            tax: 0,
            notes: 'Orçamento via app',
            orcamento_id: orcamentoId  // Pass the retrieved `orcamento_id`
        }));

        // Second API call to create budget items
        await fetch(`${BASE_URL}/prestadores/itens_orcamento.php?action=create`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(detailsPayload)
        });

        setSnackbar({
            open: true,
            message: 'Orçamento salvo com sucesso!',
            severity: 'success'
        });
        
        // Reset form
        setActiveStep(0);
        setItems([{ product_description: '', quantity: 1, unit_price: '' }]);
        setLaborCost('');
        
    } catch (error) {
        setSnackbar({
            open: true,
            message: 'Erro ao salvar: ' + error.message,
            severity: 'error'
        });
    } finally {
        setSavingBudget(false);
    }
};

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Card sx={{ mb: 2, bgcolor: 'background.paper' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom color="text.primary">
                Selecione o Serviço para {plate}
              </Typography>
              <TextField
                select
                fullWidth
                label="Tipo de Serviço"
                value={selectedSchedule}
                onChange={(e) => setSelectedSchedule(e.target.value)}
                sx={{ mb: 2 }}
              >
                {schedules.map((schedule) => (
                  <MenuItem key={schedule.id} value={schedule.id}>
                    {schedule.maintenance_type} - {new Date(schedule.scheduled_date).toLocaleDateString()}
                  </MenuItem>
                ))}
              </TextField>
            </CardContent>
          </Card>
        );

      case 1:
        return (
          <Stack spacing={2}>
            {items.map((item, index) => (
              <Paper 
                key={index} 
                sx={{ 
                  p: 2, 
                  bgcolor: 'background.paper',
                  position: 'relative'
                }}
              >
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    label="Descrição do Item"
                    value={item.product_description}
                    onChange={(e) => handleItemChange(index, 'product_description', e.target.value)}
                  />
                  <Stack direction="row" spacing={2}>
                    <TextField
                      type="number"
                      label="Quantidade"
                      value={item.quantity}
                      onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                      sx={{ width: '30%' }}
                    />
                    <TextField
                      type="number"
                      label="Valor Unitário"
                      value={item.unit_price}
                      onChange={(e) => handleItemChange(index, 'unit_price', e.target.value)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                      }}
                      sx={{ width: '70%' }}
                    />
                  </Stack>
                  <Typography variant="subtitle2" color="text.secondary" align="right">
                    Subtotal: R$ {(item.quantity * item.unit_price || 0).toFixed(2)}
                  </Typography>
                </Stack>
                <Tooltip title="Remover Item">
                  <IconButton 
                    color="error" 
                    onClick={() => handleRemoveItem(index)}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Paper>
            ))}
            
            <Paper sx={{ p: 2, bgcolor: 'background.paper' }}>
              <TextField
                fullWidth
                type="number"
                label="Valor da Mão de Obra"
                value={laborCost}
                onChange={(e) => setLaborCost(e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
              />
            </Paper>

            <Paper sx={{ p: 2, bgcolor: 'background.paper' }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" color="text.primary">
                  Total do Orçamento
                </Typography>
                <Typography variant="h6" color="primary.main">
                  R$ {calculateTotal().toFixed(2)}
                </Typography>
              </Stack>
            </Paper>

            <Fab
              color="primary"
              sx={{ position: 'fixed', bottom: 80, right: 16 }}
              onClick={handleAddItem}
            >
              <AddIcon />
            </Fab>
          </Stack>
        );

      case 2:
        return (
          <Card sx={{ mb: 2, bgcolor: 'background.paper' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom color="text.primary">
                Resumo do Orçamento
              </Typography>
              <Divider sx={{ my: 2 }} />
              {items.map((item, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography variant="subtitle1" color="text.primary">
                    {item.product_description}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.quantity}x R$ {item.unit_price} = R$ {(item.quantity * item.unit_price || 0).toFixed(2)}
                  </Typography>
                </Box>
              ))}
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1" color="text.primary">
                Mão de Obra: R$ {Number(laborCost || 0).toFixed(2)}
              </Typography>
              <Typography variant="h6" color="primary.main" sx={{ mt: 2 }}>
                Total Final: R$ {calculateTotal().toFixed(2)}
              </Typography>
            </CardContent>
          </Card>
        );
    }
  };

  return (
    <Box sx={{ p: 2, maxWidth: 800, margin: '0 auto' }}>
      <Stepper 
        activeStep={activeStep} 
        sx={{ 
          mb: 4,
          '& .MuiStepLabel-label': {
            color: 'text.secondary',
            '&.Mui-active': {
              color: 'primary.main'
            }
          }
        }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {renderStepContent(activeStep)}

      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          p: 2,
          bgcolor: 'background.paper',
          borderTop: 1,
          borderColor: 'divider'
        }}
      >
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Button
            onClick={handleBack}
            disabled={activeStep === 0}
            startIcon={<NavigateBeforeIcon />}
          >
            Voltar
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={savingBudget}
              startIcon={savingBudget ? <CircularProgress size={20} /> : <SaveIcon />}
            >
              {savingBudget ? 'Salvando...' : 'Finalizar Orçamento'}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleNext}
              endIcon={<NavigateNextIcon />}
            >
              Próximo
            </Button>
          )}
        </Stack>
      </Paper>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ServiceDetailsOrcamento;