import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Collapse,
  ThemeProvider,
  createTheme,
  alpha
} from '@mui/material';
import {
  Search as SearchIcon,
  LocationOn as LocationIcon,
  TuneRounded as FilterIcon,
  Directions as DirectionsIcon,
  LocalGasStation as FuelIcon,
  Speed as SpeedIcon,
  CalendarToday as CalendarIcon,
} from '@mui/icons-material';

import Footer from './components/Footer';
import Header from './components/Header';

// Definir o tema com tons de laranja e azul escuro
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#F97316',
    },
    secondary: {
      main: '#0A192F',
    },
    background: {
      default: '#0A192F',
      paper: '#112240',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: '#ff6400',
          borderColor: '#ff6400',
        },
      },
    },
  },
});

const SearchPage = () => {
  const [searchParams, setSearchParams] = useState({
    manufacturer: '',
    model: '',
    location: '',
    priceMax: '',
    year: '',
    color: '',
    fuelType: '',
    bodyType: '',
    seatingCapacity: ''
  });

  const [showFilters, setShowFilters] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userCity, setUserCity] = useState('');

  const navigate = useNavigate(); // Add useNavigate hook

  // Função para obter a cidade a partir das coordenadas
  const getCityFromCoordinates = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();
      return data.address.city || data.address.town || data.address.municipality;
    } catch (error) {
      console.error('Error getting city name:', error);
      return '';
    }
  };

  // Função para obter a localização do usuário
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const city = await getCityFromCoordinates(latitude, longitude);
          setUserCity(city);
          setSearchParams(prev => ({ ...prev, location: city }));
          fetchVehicles(city);
        },
        (error) => {
          console.error('Error getting location:', error);
          fetchVehicles(); // Buscar veículos sem filtro de cidade em caso de erro
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      fetchVehicles(); // Buscar veículos sem filtro de cidade se geolocalização não for suportada
    }
  };

  // Função para buscar veículos
  const fetchVehicles = async (city = '') => {
    setLoading(true);
    try {
      const params = new URLSearchParams({
        sort: 'popular',
        limit: 50,
        ...(city && { location: city })
      });
      
      const response = await fetch(`https://oyster-app-4ypyg.ondigitalocean.app/api/listings/search?${params}`);
      const data = await response.json();
      
      if (data.listings && Array.isArray(data.listings)) {
        setVehicles(data.listings);
      }
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getUserLocation();
  }, []);


  // Função de busca atualizada
  const handleSearch = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams({
        ...searchParams,
        sort: 'popular',
        limit: 50
      });
      
      const response = await fetch(`https://oyster-app-4ypyg.ondigitalocean.app/api/listings/search?${params}`);
      const data = await response.json();
      
      if (data.listings && Array.isArray(data.listings)) {
        setVehicles(data.listings);
      }
    } catch (error) {
      console.error('Error searching vehicles:', error);
    } finally {
      setLoading(false);
    }
  };

  // Adicionar mensagem sobre a localização
  const LocationMessage = () => (
    <Typography 
      variant="body2" 
      color="text.secondary" 
      sx={{ mt: 2, mb: 2 }}
    >
      {userCity ? 
        `Mostrando veículos em ${userCity}` : 
        'Buscando sua localização...'}
    </Typography>
  );



  const handleCardClick = (listingId) => {
    navigate(`/classificados/detalhes/${listingId}`);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
      <Header />
        {/* Hero Section */}
        <Paper 
          elevation={0} 
          sx={{ 
            bgcolor: 'background.paper',
            borderBottom: 1,
            borderColor: 'divider',
            boxShadow: theme.shadows[4]
          }}
        >
          <Container maxWidth="lg" sx={{ py: 4 }}>
            <Typography 
              variant="h4" 
              component="h1" 
              gutterBottom 
              sx={{ 
                fontWeight: 'bold',
                color: 'primary.main'
              }}
            >
              Encontre o carro perfeito para você
            </Typography>

            <LocationMessage />

            
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  placeholder="Marca ou modelo"
                  value={searchParams.manufacturer}
                  onChange={(e) => setSearchParams({
                    ...searchParams,
                    manufacturer: e.target.value
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: alpha(theme.palette.primary.main, 0.3),
                      },
                      '&:hover fieldset': {
                        borderColor: alpha(theme.palette.primary.main, 0.5),
                      },
                    },
                  }}
                />
              </Grid>
              
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  placeholder="Cidade"
                  value={searchParams.location}
                  onChange={(e) => setSearchParams({
                    ...searchParams,
                    location: e.target.value
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: alpha(theme.palette.primary.main, 0.3),
                      },
                      '&:hover fieldset': {
                        borderColor: alpha(theme.palette.primary.main, 0.5),
                      },
                    },
                  }}
                />
              </Grid>
              
              <Grid item xs={12} md={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSearch}
                  sx={{ 
                    height: '56px',
                    bgcolor: 'primary.main',
                    '&:hover': {
                      bgcolor: alpha(theme.palette.primary.main, 0.8),
                    },
                  }}
                >
                  Buscar
                </Button>
              </Grid>
              
              <Grid item xs={12} md={2}>
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<FilterIcon />}
                  onClick={() => setShowFilters(!showFilters)}
                  sx={{ 
                    height: '56px',
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    '&:hover': {
                      borderColor: alpha(theme.palette.primary.main, 0.8),
                      bgcolor: alpha(theme.palette.primary.main, 0.1),
                    },
                  }}
                >
                  Filtros
                </Button>
              </Grid>
            </Grid>

            <Collapse in={showFilters}>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {['Preço máximo', 'Ano', 'Cor', 'Combustível'].map((placeholder, index) => (
                  <Grid item xs={12} md={3} key={placeholder}>
                    <TextField
                      fullWidth
                      placeholder={placeholder}
                      value={searchParams[Object.keys(searchParams)[index + 3]]}
                      onChange={(e) => setSearchParams({
                        ...searchParams,
                        [Object.keys(searchParams)[index + 3]]: e.target.value
                      })}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: alpha(theme.palette.primary.main, 0.3),
                          },
                          '&:hover fieldset': {
                            borderColor: alpha(theme.palette.primary.main, 0.5),
                          },
                        },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Collapse>
          </Container>
        </Paper>

        {/* Results Section */}
        <Container maxWidth="lg" sx={{ py: 4 }}>
          {loading ? (
            <Box display="flex" justifyContent="center" py={4}>
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <Grid container spacing={3}>
              {vehicles.map((vehicle) => (
                <Grid item xs={12} sm={6} md={4} key={vehicle._id}>
                  <Card 
                    onClick={() => handleCardClick(vehicle._id)}
                    sx={{ 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column',
                      bgcolor: 'background.paper',
                      '&:hover': {
                        boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
                        transform: 'translateY(-4px)',
                        transition: 'all 0.3s ease-in-out',
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image={vehicle.imagens[0] || '/api/placeholder/400/300'}
                      alt={vehicle.vehicleId.name}
                      sx={{ objectFit: 'cover' }}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h6" component="h2" color="primary">
                        {vehicle.vehicleId.fabricante} {vehicle.vehicleId.name}
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {[
                          { icon: CalendarIcon, text: `Ano: ${vehicle.vehicleId.year}` },
                          { icon: SpeedIcon, text: `Km: ${vehicle.vehicleId.odometer}` },
                          { icon: FuelIcon, text: vehicle.vehicleId.combustivel }
                        ].map(({ icon: Icon, text }) => (
                          <Box key={text} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Icon fontSize="small" sx={{ color: 'primary.main' }} />
                            <Typography variant="body2" color="text.secondary">
                              {text}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          mt: 2,
                          color: 'primary.main',
                          fontWeight: 'bold'
                        }}
                      >
                        R$ {vehicle.price.toLocaleString()}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                        <LocationIcon fontSize="small" sx={{ color: 'primary.main' }} />
                        <Typography variant="body2" color="text.secondary">
                          {vehicle.vehicleId.cidade || 'Localização não informada'}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
          
          {vehicles.length === 0 && !loading && (
            <Box textAlign="center" py={6}>
              <Typography color="text.secondary">
                Nenhum veículo encontrado. Tente ajustar seus filtros de busca.
              </Typography>
            </Box>
          )}
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default SearchPage;