// VendasTicket.js
import React from 'react';
import { Card, CardContent, Typography, Divider } from '@mui/material';

const VendasTicket = ({ ticket }) => (
  <Card variant="outlined" sx={{ backgroundColor: 'background.paper', borderRadius: 2 }}>
    <CardContent>
      <Typography variant="h6" color="text.primary">
        Detalhes da Venda
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant="body2" color="text.secondary" mb={1}>
        <strong>Produto:</strong> {ticket.productName}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={1}>
        <strong>Cliente:</strong> {ticket.clientName}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={1}>
        <strong>Status do Pedido:</strong> {ticket.orderStatus}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={1}>
        <strong>Data do Pedido:</strong> {new Date(ticket.orderDate).toLocaleDateString()}
      </Typography>
    </CardContent>
  </Card>
);

export default VendasTicket;
