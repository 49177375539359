import React from 'react';
// import Sidebar from './dashboard/Sidebar';
// import Header from './dashboard/Header';
import ServicosContent from './ServicosContent';
import { useParams } from 'react-router-dom';
import theme from '../../Tema';
import { ThemeProvider } from '@mui/material/styles';
import {
  Box,
  CssBaseline
} from '@mui/material';
import Layout from '../Layout';



const Services = () => {
  return (
    
    <Layout>
    <ThemeProvider theme={theme}>
    <CssBaseline />
      <ServicosContent />           
      </ThemeProvider>  
      </Layout>
    
  );
};

export default Services;
