import React from 'react';
import ServicesContent from './ServicosContent';
import { useParams } from 'react-router-dom';
import theme from '../../Tema';
import { ThemeProvider } from '@mui/material/styles';
import Layout from "../Layout";
import {
  Box
} from '@mui/material';



const Services = () => {
  return (
    <Layout>
      <ThemeProvider theme={theme}>
        <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}> 
        <ServicesContent />           
        </Box>
      </ThemeProvider>
    </Layout>
  );
};

export default Services;

