import React, { useState, useCallback } from 'react';
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Alert,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from '@mui/material';

import { useNavigate, useLocation } from 'react-router-dom';

const CadastroFornecedor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  const [formData, setFormData] = useState({
    cnpj: '',
    nome: '',
    nome_fantasia: '',
    abertura: '',
    situacao: '',
    data_situacao: '',
    motivo_situacao: '',
    tipo: '',
    porte: '',
    natureza_juridica: '',
    logradouro: '',
    numero: '',
    bairro: '',
    municipio: '',
    uf: '',
    cep: '',
    complemento: '',
    email: '',
    telefone: '',
    capital_social: '',
    status: 'ativo',
    efr: '',
    situacao_especial: '',
    data_situacao_especial: '',
    atividade_principal_code: '',
    atividade_principal_text: '',
    ultima_atualizacao: '',
    simples_optante: false,
    simples_data_opcao: '',
    simples_data_exclusao: '',
    simei_optante: false,
    simei_data_opcao: '',
    simei_data_exclusao: '',
    latitude: '',
    longitude: ''
  });

  // Formatadores
  const formatCNPJ = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .slice(0, 18);
  };

  const formatTelefone = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .slice(0, 15);
  };

  const formatCEP = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .slice(0, 9);
  };

  // Validadores
  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validateCNPJ = (cnpj) => {
    return cnpj.replace(/\D/g, '').length === 14;
  };

  const validateTelefone = (telefone) => {
    const numbers = telefone.replace(/\D/g, '');
    return numbers.length >= 10 && numbers.length <= 11;
  };

  // Handlers
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let formattedValue = value;

    // Aplicar formatação específica para cada campo
    switch (name) {
      case 'cnpj':
        formattedValue = formatCNPJ(value);
        break;
      case 'telefone':
        formattedValue = formatTelefone(value);
        break;
      case 'cep':
        formattedValue = formatCEP(value);
        break;
      case 'capital_social':
        formattedValue = value.replace(/\D/g, '');
        break;
      default:
        formattedValue = type === 'checkbox' ? checked : value;
    }

    setFormData(prev => ({
      ...prev,
      [name]: formattedValue
    }));
    
    setError(null);
  };

  const consultarCNPJ = useCallback(async (cnpj) => {
    if (!validateCNPJ(cnpj)) {
      setError('CNPJ inválido');
      return false;
    }

    setLoading(true);
    try {
      const cnpjLimpo = cnpj.replace(/\D/g, '');
      const response = await fetch(`https://www.receitaws.com.br/v1/cnpj/${cnpjLimpo}`);
      if (!response.ok) throw new Error('Erro ao consultar CNPJ');
      
      const data = await response.json();
      
      if (data.status === 'ERROR') {
        throw new Error(data.message);
      }

      // Preencher todos os campos com os dados da API
      setFormData(prev => ({
        ...prev,
        nome: data.nome,
        nome_fantasia: data.fantasia,
        abertura: data.abertura,
        situacao: data.situacao,
        data_situacao: data.data_situacao,
        motivo_situacao: data.motivo_situacao,
        tipo: data.tipo,
        porte: data.porte,
        natureza_juridica: data.natureza_juridica,
        logradouro: data.logradouro,
        numero: data.numero,
        bairro: data.bairro,
        municipio: data.municipio,
        uf: data.uf,
        cep: data.cep,
        complemento: data.complemento,
        email: data.email,
        telefone: data.telefone,
        capital_social: data.capital_social,
        efr: data.efr,
        situacao_especial: data.situacao_especial,
        data_situacao_especial: data.data_situacao_especial,
        atividade_principal_code: data.atividade_principal?.[0]?.code || '',
        atividade_principal_text: data.atividade_principal?.[0]?.text || '',
        ultima_atualizacao: data.ultima_atualizacao,
        simples_optante: data.simples?.optante || false,
        simples_data_opcao: data.simples?.data_opcao || '',
        simples_data_exclusao: data.simples?.data_exclusao || '',
        simei_optante: data.simei?.optante || false,
        simei_data_opcao: data.simei?.data_opcao || '',
        simei_data_exclusao: data.simei?.data_exclusao || ''
      }));

      setSuccess('Dados do CNPJ carregados com sucesso!');
      return true;
    } catch (err) {
      setError('Não foi possível consultar o CNPJ. Preencha os campos manualmente.');
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    // Validações básicas
    if (!validateCNPJ(formData.cnpj)) {
      setError('CNPJ inválido');
      return;
    }

    if (!validateEmail(formData.email)) {
      setError('Email inválido');
      return;
    }

    if (!validateTelefone(formData.telefone)) {
      setError('Telefone inválido');
      return;
    }

    setLoading(true);

    try {
      // Obter coordenadas se houver endereço completo
      if (formData.logradouro && formData.numero && formData.municipio && formData.uf) {
        const endereco = `${formData.logradouro}, ${formData.numero}, ${formData.bairro}, ${formData.municipio} - ${formData.uf}`;
        const geocodeResponse = await fetch(
          `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(endereco)}&key=13aba2f505474548b5fd66d2ec3a4ab5`
        );
        
        if (!geocodeResponse.ok) throw new Error('Erro ao obter localização');
        
        const geocodeData = await geocodeResponse.json();
        
        if (geocodeData.results.length) {
          const { lat, lng } = geocodeData.results[0].geometry;
          formData.latitude = lat;
          formData.longitude = lng;
        }
      }

      // Enviar dados para a API
      const queryParams = new URLSearchParams(location.search);
      const apiResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/ec.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          telefone: formData.telefone.replace(/\D/g, '')
        }),
      });

      if (!apiResponse.ok) throw new Error('Erro ao salvar dados');

      const responseData = await apiResponse.json();
      
      setSuccess('Cadastro realizado com sucesso!');
      
      setTimeout(() => {
        navigate(`/loja/fornecedores/cadastro/servicos/${responseData.id}?token=${queryParams.get('token')}`, {
          state: {
            nome: formData.nome,
            latitude: formData.latitude,
            longitude: formData.longitude,
          },
        });
      }, 1500);

    } catch (err) {
      setError(err.message || 'Ocorreu um erro ao processar o cadastro. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="max-w-4xl mx-auto p-4">
      <Card className="relative overflow-hidden">
        <CardContent className="space-y-6">
          <Box className="text-center">
            <Typography variant="h4" className="mb-2">
              Cadastro de Fornecedor
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Preencha todos os dados do estabelecimento
            </Typography>
          </Box>

          <form onSubmit={handleSubmit} className="space-y-4">
            {error && (
              <Alert severity="error" className="mb-4">
                {error}
              </Alert>
            )}

            {success && (
              <Alert severity="success" className="mb-4">
                {success}
              </Alert>
            )}

            <Grid container spacing={2}>
              {/* Dados Principais */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="CNPJ"
                  name="cnpj"
                  value={formData.cnpj}
                  onChange={handleChange}
                  onBlur={() => formData.cnpj && consultarCNPJ(formData.cnpj)}
                  placeholder="00.000.000/0000-00"
                  disabled={loading}
                  InputProps={{
                    endAdornment: loading && (
                      <InputAdornment position="end">
                        <CircularProgress size={20} />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Nome/Razão Social"
                  name="nome"
                  value={formData.nome}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Nome Fantasia"
                  name="nome_fantasia"
                  value={formData.nome_fantasia}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={loading}
                  error={formData.email && !validateEmail(formData.email)}
                  helperText={formData.email && !validateEmail(formData.email) ? 'Email inválido' : ''}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Telefone"
                  name="telefone"
                  value={formData.telefone}
                  onChange={handleChange}
                  placeholder="(00) 00000-0000"
                  disabled={loading}
                  error={formData.telefone && !validateTelefone(formData.telefone)}
                  helperText={formData.telefone && !validateTelefone(formData.telefone) ? 'Telefone inválido' : ''}
                />
              </Grid>

              {/* Endereço */}
              <Grid item xs={12}>
                <Typography variant="h6" className="mb-2">
                  Endereço
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Logradouro"
                  name="logradouro"
                  value={formData.logradouro}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <TextField
                  fullWidth
                  required
                  label="Número"
                  name="numero"
                  value={formData.numero}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Complemento"
                  name="complemento"
                  value={formData.complemento}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  required
                  label="Bairro"
                  name="bairro"
                  value={formData.bairro}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  required
                  label="Cidade"
                  name="municipio"
                  value={formData.municipio}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <TextField
                  fullWidth
                  required
                  label="UF"
                  name="uf"
                  value={formData.uf}
                  inputProps={{ maxLength: 2 }}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <TextField
                  fullWidth
                  required
                  label="CEP"
                  name="cep"
                  value={formData.cep}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              {/* Dados Fiscais */}
              <Grid item xs={12}>
                <Typography variant="h6" className="mb-2">
                  Dados Fiscais e Empresariais
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Data de Abertura"
                  name="abertura"
                  type="date"
                  value={formData.abertura}
                  onChange={handleChange}
                  disabled={loading}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Situação</InputLabel>
                  <Select
                    name="situacao"
                    value={formData.situacao}
                    onChange={handleChange}
                    disabled={loading}
                  >
                    <MenuItem value="ATIVA">Ativa</MenuItem>
                    <MenuItem value="BAIXADA">Baixada</MenuItem>
                    <MenuItem value="SUSPENSA">Suspensa</MenuItem>
                    <MenuItem value="INAPTA">Inapta</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Data da Situação"
                  name="data_situacao"
                  type="date"
                  value={formData.data_situacao}
                  onChange={handleChange}
                  disabled={loading}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Motivo Situação"
                  name="motivo_situacao"
                  value={formData.motivo_situacao}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Tipo</InputLabel>
                  <Select
                    name="tipo"
                    value={formData.tipo}
                    onChange={handleChange}
                    disabled={loading}
                  >
                    <MenuItem value="MATRIZ">Matriz</MenuItem>
                    <MenuItem value="FILIAL">Filial</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Porte</InputLabel>
                  <Select
                    name="porte"
                    value={formData.porte}
                    onChange={handleChange}
                    disabled={loading}
                  >
                    <MenuItem value="ME">Microempresa</MenuItem>
                    <MenuItem value="EPP">Empresa de Pequeno Porte</MenuItem>
                    <MenuItem value="MEDIO">Médio Porte</MenuItem>
                    <MenuItem value="GRANDE">Grande Porte</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Natureza Jurídica"
                  name="natureza_juridica"
                  value={formData.natureza_juridica}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Capital Social"
                  name="capital_social"
                  value={formData.capital_social}
                  onChange={handleChange}
                  disabled={loading}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="EFR"
                  name="efr"
                  value={formData.efr}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              {/* Atividade Principal */}
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Código Atividade Principal"
                  name="atividade_principal_code"
                  value={formData.atividade_principal_code}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  label="Descrição Atividade Principal"
                  name="atividade_principal_text"
                  value={formData.atividade_principal_text}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              {/* Simples Nacional */}
              <Grid item xs={12}>
                <Typography variant="h6" className="mb-2">
                  Simples Nacional
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Optante Simples</InputLabel>
                  <Select
                    name="simples_optante"
                    value={formData.simples_optante}
                    onChange={handleChange}
                    disabled={loading}
                  >
                    <MenuItem value={true}>Sim</MenuItem>
                    <MenuItem value={false}>Não</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Data Opção Simples"
                  name="simples_data_opcao"
                  type="date"
                  value={formData.simples_data_opcao}
                  onChange={handleChange}
                  disabled={loading || !formData.simples_optante}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Data Exclusão Simples"
                  name="simples_data_exclusao"
                  type="date"
                  value={formData.simples_data_exclusao}
                  onChange={handleChange}
                  disabled={loading || !formData.simples_optante}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* SIMEI */}
              <Grid item xs={12}>
                <Typography variant="h6" className="mb-2">
                  Microempreendedor Individual
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Optante MEI</InputLabel>
                  <Select
                    name="simei_optante"
                    value={formData.simei_optante}
                    onChange={handleChange}
                    disabled={loading}
                  >
                    <MenuItem value={true}>Sim</MenuItem>
                    <MenuItem value={false}>Não</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Data Opção MEI"
                  name="simei_data_opcao"
                  type="date"
                  value={formData.simei_data_opcao}
                  onChange={handleChange}
                  disabled={loading || !formData.simei_optante}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Data Exclusão MEI"
                  name="simei_data_exclusao"
                  type="date"
                  value={formData.simei_data_exclusao}
                  onChange={handleChange}
                  disabled={loading || !formData.simei_optante}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Situação Especial */}
              <Grid item xs={12}>
                <Typography variant="h6" className="mb-2">
                  Situação Especial
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Situação Especial"
                  name="situacao_especial"
                  value={formData.situacao_especial}
                  onChange={handleChange}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Data Situação Especial"
                  name="data_situacao_especial"
                  type="date"
                  value={formData.data_situacao_especial}
                  onChange={handleChange}
                  disabled={loading}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Última Atualização */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Última Atualização"
                  name="ultima_atualizacao"
                  type="datetime-local"
                  value={formData.ultima_atualizacao}
                  onChange={handleChange}
                  disabled={loading}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    disabled={loading}
                  >
                    <MenuItem value="ativo">Ativo</MenuItem>
                    <MenuItem value="inativo">Inativo</MenuItem>
                    <MenuItem value="pendente">Pendente</MenuItem>
                    <MenuItem value="suspenso">Suspenso</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={loading}
              className="mt-6"
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Finalizar Cadastro'
              )}
            </Button>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CadastroFornecedor;