import React from "react";
import SupplierDetailsContent from "./SupplierDetailsContent";
import { useParams } from "react-router-dom";
import theme from "../../Tema";
import { ThemeProvider } from "@mui/material/styles";
import { Box, CssBaseline } from "@mui/material";
import Layout from "../Layout";

const Services = () => {
  return (
    <Layout>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SupplierDetailsContent />
      </ThemeProvider>
    </Layout>
  );
};

export default Services;
