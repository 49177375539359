import React from 'react';
import { 
  Box, 
  Chip, 
  Paper, 
  Stack, 
  Typography,
  Divider,
  Button,
  Grid,
  LinearProgress,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import { 
  TrendingDown, 
  TrendingUp, 
  LocalAtm,
  Security,
  ThumbUp,
  Download,
  CheckCircle,
  Error,
  BusinessCenter,
  Group,
  Phone,
  Star,
  SupportAgent
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const FipeComparisonPanel = ({ price, fipeValue }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Função para limpar valores monetários no formato brasileiro (ex.: 44.639,00)
  const cleanValue = (val) => {
    if (typeof val === 'string' && val.match(/^\d{1,3}(\.\d{3})*,\d{2}$/)) {
      // Remove os pontos e substitui a vírgula por um ponto
      return parseFloat(val.replace(/\./g, '').replace(',', '.'));
    }
    return parseFloat(val) || 0; // Retorna 0 caso o valor seja inválido ou não esteja no formato esperado
  };

  const numericPrice = cleanValue(price);
  const numericFipe = cleanValue(fipeValue);
  const difference = numericFipe - numericPrice;
  const percentageDiff = ((difference / numericFipe) * 100).toFixed(1);
  const isBelowFipe = numericPrice < numericFipe;

  const formatCurrency = (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);

  return (
    <Paper 
      elevation={5}
      sx={{
        width: '100%',
        maxWidth: '100%',
        p: isMobile ? 2 : 4,
        mb: 4,
        background: `linear-gradient(135deg, ${theme.palette.background.default}, ${theme.palette.info.light}10)`,
        borderRadius: 3,
        boxShadow: '0px 6px 15px rgba(0,0,0,0.1)',
        transition: 'transform 0.3s ease',
        '&:hover': { transform: 'scale(1.02)' },
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
        {isBelowFipe ? (
          <TrendingDown sx={{ color: theme.palette.success.main, fontSize: isMobile ? 24 : 32 }} />
        ) : (
          <TrendingUp sx={{ color: theme.palette.warning.main, fontSize: isMobile ? 24 : 32 }} />
        )}
        <Typography variant={isMobile ? "h6" : "h5"} sx={{ fontWeight: 'bold', color: isBelowFipe ? theme.palette.success.dark : theme.palette.warning.dark }}>
          {isBelowFipe ? "Oportunidade Incrível!" : "Preço Competitivo"}
        </Typography>
      </Stack>

      <Box sx={{ my: 2 }}>
        <Typography variant="body2" sx={{ color: theme.palette.text.secondary, textAlign: 'center' }}>
          Comparação de valores:
        </Typography>
        <Grid container spacing={isMobile ? 1 : 2} alignItems="center" sx={{ my: 1 }}>
          <Grid item xs={6} sm={5}>
            <Typography variant="subtitle2" sx={{ color: theme.palette.text.primary }}>
              Valor FIPE
            </Typography>
            <Typography variant="h6" sx={{ color: theme.palette.info.main, fontWeight: 'bold', fontSize: isMobile ? '1rem' : '1.25rem' }}>
              {formatCurrency(numericFipe)}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={5}>
            <Typography variant="subtitle2" sx={{ color: theme.palette.text.primary }}>
              Valor do Veículo
            </Typography>
            <Typography variant="h6" sx={{ color: isBelowFipe ? theme.palette.success.dark : theme.palette.warning.dark, fontWeight: 'bold', fontSize: isMobile ? '1rem' : '1.25rem' }}>
              {formatCurrency(numericPrice)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} display="flex" justifyContent="center">
            <Chip
              label={`${Math.abs(percentageDiff)}% ${isBelowFipe ? 'Abaixo' : 'Acima'} da FIPE`}
              color={isBelowFipe ? "success" : "warning"}
              sx={{
                fontWeight: 'bold',
                fontSize: isMobile ? '0.75rem' : '0.9rem',
                px: 2,
                py: 1,
                borderRadius: 2,
              }}
            />
          </Grid>
        </Grid>

        <LinearProgress 
          variant="determinate" 
          value={isBelowFipe ? 100 - parseFloat(percentageDiff) : 100} 
          sx={{
            height: 8,
            borderRadius: 5,
            backgroundColor: isBelowFipe ? theme.palette.success.light : theme.palette.warning.light,
            '& .MuiLinearProgress-bar': {
              borderRadius: 5,
              backgroundColor: isBelowFipe ? theme.palette.success.main : theme.palette.warning.main,
            }
          }}
        />
      </Box>

      <Divider sx={{ my: 2 }} />

      <Stack direction="column" spacing={1} alignItems="center">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: isMobile ? 60 : 80,
            height: isMobile ? 60 : 80,
            borderRadius: '50%',
            backgroundColor: theme.palette.info.light,
            color: theme.palette.info.main,
            fontSize: isMobile ? 28 : 40,
            boxShadow: '0px 4px 10px rgba(0,0,0,0.1)'
          }}
        >
          {isBelowFipe ? <CheckCircle /> : <Error />}
        </Box>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: theme.palette.info.main, textAlign: 'center', fontSize: isMobile ? '0.9rem' : '1.1rem' }}>
          {isBelowFipe ? "Oportunidade Incrível na Torki!" : "Preço Competitivo na Torki"}
        </Typography>
        <Typography variant="body2" align="center" sx={{ color: theme.palette.text.secondary, px: 3, fontSize: isMobile ? '0.8rem' : '0.9rem' }}>
          {isBelowFipe 
            ? "Encontre veículos com preços abaixo da FIPE e realize uma compra segura e confiável com a Torki."
            : "Compre com tranquilidade sabendo que o preço do veículo está competitivo em relação à FIPE."}
        </Typography>
        <Button 
          variant="contained" 
          color="info" 
          size="medium"
          startIcon={<Download />}
          sx={{ fontWeight: 'bold', mt: 2, fontSize: isMobile ? '0.8rem' : '1rem' }}
          onClick={() => window.open('https://torki.com.br/download', '_blank')}
        >
          Baixe o App da Torki
        </Button>
      </Stack>

      <Box sx={{ mt: 4, p: isMobile ? 1 : 2, backgroundColor: theme.palette.info.light + '15', borderRadius: 2 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: theme.palette.info.main, mb: 1, fontSize: isMobile ? '0.85rem' : '1rem' }}>
          Benefícios Exclusivos da Torki:
        </Typography>
        <Grid container spacing={isMobile ? 1 : 2}>
          {[
            { icon: <Star />, text: "Selo Premium para Anúncios" },
            { icon: <BusinessCenter />, text: "Destaque Comercial nos Classificados" },
            { icon: <ThumbUp />, text: "Atraia Compradores Interessados" },
            { icon: <SupportAgent />, text: "Suporte Personalizado para Anunciantes" },
            { icon: <LocalAtm />, text: "Em breve: Financiamento e Consórcio" },
            { icon: <Security />, text: "Em breve: Cotação de Seguros" }
          ].map((benefit, index) => (
            <Grid item xs={6} sm={4} key={index} sx={{ textAlign: 'center' }}>
              <Tooltip title={benefit.text} placement="top">
                <Stack direction="column" alignItems="center" spacing={0.5}>
                  {React.cloneElement(benefit.icon, { fontSize: isMobile ? 'small' : 'medium', color: theme.palette.info.dark })}
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: theme.palette.info.dark, fontSize: isMobile ? '0.75rem' : '0.875rem' }}>
                    {benefit.text}
                  </Typography>
                </Stack>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Divider sx={{ my: 3 }} />

      <Typography variant="subtitle1" align="center" sx={{ fontWeight: 'bold', color: theme.palette.text.primary, mt: 2, fontSize: isMobile ? '0.9rem' : '1rem' }}>
        Torki: Seu parceiro em cada viagem!
      </Typography>
    </Paper>
  );
};

export default FipeComparisonPanel;
