import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./dashboard/Rodape";
import {
  Card,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Chip,
  Divider,
  Grid,
  Box,
  TextField,
  Snackbar,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ErrorIcon from '@mui/icons-material/Error';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';

const customJwtDecode = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c =>
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));
    return JSON.parse(jsonPayload);
  } catch (error) {
    throw new Error('Invalid token');
  }
};

const MaintenanceDashboard = () => {
  const { supplierId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [supplierDetails, setSupplierDetails] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const bffUrl = 'https://dojo.stratusport.co/torki/prestadores/fornecedor.php';

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (!token) {
      setError('Token não encontrado.');
      setLoading(false);
      return;
    }

    try {
      const decodedToken = customJwtDecode(token);
      const userId = decodedToken.user.id;
      fetchSupplierDetails(userId);
    } catch (e) {
      setError('Erro ao decodificar o token.');
      setLoading(false);
    }
  }, [supplierId, location]);

  const fetchSupplierDetails = (userId) => {
    if (!supplierId) {
      setError('ID do fornecedor não encontrado.');
      setLoading(false);
      return;
    }

    fetch(`${bffUrl}?action=getSupplierHistory&supplier_id=${supplierId}&user_id=${userId}`)
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data) && data.length > 0) {
          const supplierInfo = data[0];
          setSupplierDetails({
            supplierName: supplierInfo.supplier_name || 'Fornecedor Indisponível',
            serviceType: supplierInfo.service_type,
            locationLat: supplierInfo.location_lat,
            locationLong: supplierInfo.location_long,
            rating: supplierInfo.rating,
            reviews: supplierInfo.reviews,
            createdAt: supplierInfo.created_at,
            status: supplierInfo.status,
            cnpj: supplierInfo.cnpj,
            email: supplierInfo.email,
            telefone: supplierInfo.telefone,
            endereco: `${supplierInfo.logradouro || ''}, ${supplierInfo.numero || ''} - ${supplierInfo.bairro || ''}, ${supplierInfo.municipio || ''} - ${supplierInfo.uf || ''}`,
            naturezaJuridica: supplierInfo.natureza_juridica || 'N/A',
            capitalSocial: supplierInfo.capital_social || 'N/A',
            tipo: supplierInfo.tipo || 'N/A',
            porte: supplierInfo.porte || 'N/A',
            atividadePrincipal: supplierInfo.atividade_principal_text || 'N/A',
            dataAbertura: supplierInfo.abertura ? new Date(supplierInfo.abertura).toLocaleDateString() : 'N/A',
          });

          const groupedByVehicle = data.reduce((acc, entry) => {
            const { plate } = entry;
            if (!acc[plate]) {
              acc[plate] = {
                vehicleDetails: {
                  vehicleName: entry.vehicle_name || 'Veículo Indisponível',
                  vehicleModel: entry.model || 'Modelo Indisponível',
                  vehiclePlate: plate,
                  vehicleColor: entry.color || 'Cor Indisponível',
                  vehicleYear: entry.year || 'Ano Indisponível',
                  vehicleOdometer: entry.odometer || 0,
                  consumption: entry.consumption || 'N/A',
                  tipoVeiculo: entry.tipo_veiculo || 'N/A',
                  chassis: entry.chassi || 'N/A',
                  potencia: entry.potencia || 'N/A',
                  cargaMaxima: entry.carga || 'N/A',
                  quantidadeLugares: entry.quantidade_lugares || 'N/A',
                  ultimaManutencao: entry.ultima_manutencao || 'N/A',
                },
                history: [],
              };
            }
            // Adiciona atendimento
            const existingService = acc[plate].history.find(service => service.idOrcamento === entry.id_orcamento);
            if (!existingService) {
              acc[plate].history.push({
                maintenanceType: entry.maintenance_type,
                scheduledDate: entry.scheduled_date,
                schedule_id: entry.schedule_id,
                nextServiceDistance: entry.next_service_distance,
                status: entry.orcamento_status,
                idOrcamento: entry.id_orcamento,
                dataCriacao: entry.orcamento_data_criacao,
                valorTotal: entry.orcamento_valor_total,
                observacao: entry.orcamento_observacao,
                paymentMethod: entry.payment_method || 'N/A',
                paymentStatus: entry.payment_status || 'N/A',
                notes: entry.notes || 'N/A',
                serviceDuration: entry.service_duration || 'N/A',
                lastMaintenanceDate: entry.last_maintenance_date || 'N/A',
                budgetStatus: entry.budget_status || null,
              });
            }

            return acc;
          }, {});

          setVehicles(Object.values(groupedByVehicle));
          setError(null);
        } else {
          setError('Dados do fornecedor não encontrados.');
        }
      })
      .catch(() => {
        setError('Erro ao buscar histórico do fornecedor.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleBudgetLinkClick = (idOrcamento, index) => {
    if (!idOrcamento || idOrcamento === 'N/A') {
      navigate(`/orcamentos/${index}`);
    }
  };

  const handleRequestBudget = (index) => {
    const url = `/orcamentos/${index}`;
    window.open(url, '_blank'); // Abre a URL em uma nova aba
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Paid':
        return <CheckCircleIcon color="success" />;
      case 'Pending':
        return <ScheduleIcon color="primary" />;
      case 'vencido':
        return <ErrorIcon color="error" />;
      default:
        return <ScheduleIcon />;
    }
  };

  const filteredVehicles = vehicles.filter(vehicle =>
    vehicle.vehicleDetails.vehicleName.toLowerCase().includes(searchTerm.toLowerCase()) &&
    vehicle.vehicleDetails.vehicleName !== 'Veículo Indisponível' &&
    vehicle.vehicleDetails.vehiclePlate
  );

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', flex: 1 }}>
        <Typography variant="h4" gutterBottom>
          Dashboard de Manutenção
        </Typography>
        <TextField
          label="Pesquisar veículo"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        {loading && <CircularProgress />}
        {error && (
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            message={error}
            action={
              <IconButton size="small" color="inherit" onClick={handleCloseSnackbar}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        )}

        {supplierDetails && (
          <Card sx={{ marginBottom: 2, padding: 2, boxShadow: 3, borderRadius: 2, backgroundColor: '#e3f2fd' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Detalhes do Fornecedor</Typography>
            <Typography variant="body1">Nome: {supplierDetails.supplierName}</Typography>
            <Typography variant="body1">Tipo de Serviço: {supplierDetails.serviceType}</Typography>
            <Typography variant="body1">CNPJ: {supplierDetails.cnpj || 'N/A'}</Typography>
            <Typography variant="body1">Email: {supplierDetails.email}</Typography>
            <Typography variant="body1">Telefone: {supplierDetails.telefone}</Typography>
            <Typography variant="body1">Endereço: {supplierDetails.endereco}</Typography>
            <Typography variant="body1">Status: {supplierDetails.status}</Typography>
            <Typography variant="body1">Avaliação: {supplierDetails.rating}</Typography>
            <Typography variant="body1">Revisões: {supplierDetails.reviews}</Typography>
            <Typography variant="body1">Criado em: {new Date(supplierDetails.createdAt).toLocaleDateString()}</Typography>
            <Typography variant="body1">Natureza Jurídica: {supplierDetails.naturezaJuridica}</Typography>
            <Typography variant="body1">Capital Social: R$ {supplierDetails.capitalSocial}</Typography>
            <Typography variant="body1">Tipo: {supplierDetails.tipo}</Typography>
            <Typography variant="body1">Porte: {supplierDetails.porte}</Typography>
            <Typography variant="body1">Atividade Principal: {supplierDetails.atividadePrincipal}</Typography>
            <Typography variant="body1">Data de Abertura: {supplierDetails.dataAbertura}</Typography>
          </Card>
        )}

        {!loading && !error && filteredVehicles.length > 0 ? (
          <Grid container spacing={2}>
            {filteredVehicles.map((vehicle, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ backgroundColor: '#ffffff', borderRadius: 2, boxShadow: 3 }}>
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                      {vehicle.vehicleDetails.vehicleName} ({vehicle.vehicleDetails.vehiclePlate})
                    </Typography>
                    <Typography variant="body2">Modelo: {vehicle.vehicleDetails.vehicleModel}</Typography>
                    <Typography variant="body2">Cor: {vehicle.vehicleDetails.vehicleColor}</Typography>
                    <Typography variant="body2">Ano: {vehicle.vehicleDetails.vehicleYear}</Typography>
                    <Typography variant="body2">Hodômetro: {vehicle.vehicleDetails.vehicleOdometer} km</Typography>
                    <Typography variant="body2">Consumo: {vehicle.vehicleDetails.consumption}</Typography>
                    <Typography variant="body2">Tipo de Veículo: {vehicle.vehicleDetails.tipoVeiculo}</Typography>
                    <Typography variant="body2">Chassi: {vehicle.vehicleDetails.chassis}</Typography>
                    <Typography variant="body2">Potência: {vehicle.vehicleDetails.potencia}</Typography>
                    <Typography variant="body2">Carga Máxima: {vehicle.vehicleDetails.cargaMaxima}</Typography>
                    <Typography variant="body2">Quantidade de Lugares: {vehicle.vehicleDetails.quantidadeLugares}</Typography>
                    <Typography variant="body2">Última Manutenção: {vehicle.vehicleDetails.ultimaManutencao}</Typography>
                    <Divider sx={{ margin: '16px 0' }} />
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#00796b' }}>Histórico de Atendimentos:</Typography>
                    {vehicle.history.length > 0 ? (
                      vehicle.history.map((service, idx) => (
                        <Accordion key={idx} sx={{ marginBottom: 1, boxShadow: 1 }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                              {getStatusIcon(service.status)} {service.maintenanceType} em {new Date(service.scheduledDate).toLocaleDateString()}
                            </Typography>
                            <Chip
                              label={service.status}
                              sx={{ marginLeft: 1 }}
                              color={
                                service.status === 'Paid'
                                  ? 'success'
                                  : service.status === 'Pending'
                                  ? 'primary'
                                  : 'default'
                              }
                              size="small"
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box sx={{ padding: 1, backgroundColor: '#e3f2fd', borderRadius: 1, width: '100%' }}>
                              {service.idOrcamento ? (
                                <>
                                  <Typography
                                    variant="body2"
                                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                    onClick={() => handleBudgetLinkClick(service.schedule_id, index)}
                                  >
                                    <AttachMoneyIcon sx={{ marginRight: 1 }} /> Orçamento ID: {service.idOrcamento}
                                  </Typography>
                                  <Typography variant="body2">Valor Total: R$ {service.valorTotal}</Typography>
                                  <Typography variant="body2">Data de Criação: {new Date(service.dataCriacao).toLocaleDateString()}</Typography>
                                  <Typography variant="body2">Método de Pagamento: {service.paymentMethod}</Typography>
                                  <Typography variant="body2">Status de Pagamento: {service.paymentStatus}</Typography>
                                  <Typography variant="body2">Notas: {service.notes}</Typography>
                                  <Typography variant="body2">Observação: {service.observacao}</Typography>
                                  <Typography variant="body2">Distância até o Próximo Serviço: {service.nextServiceDistance} km</Typography>
                                  <Typography variant="body2">Duração do Serviço: {service.serviceDuration}</Typography>
                                  <Typography variant="body2">Última Manutenção: {service.lastMaintenanceDate}</Typography>
                                </>
                              ) : (
                                <Typography
                                  variant="body2"
                                  sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#1976d2' }}
                                  onClick={() => handleRequestBudget(service.schedule_id)} // Função para solicitar orçamento
                                >
                                  <AttachMoneyIcon sx={{ marginRight: 1 }} /> Solicitar Orçamento
                                </Typography>
                              )}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      ))
                    ) : (
                      <Typography variant="body2" color="textSecondary">Nenhum histórico disponível.</Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body1" sx={{ color: '#f44336' }}>Nenhum veículo encontrado.</Typography>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default MaintenanceDashboard;
