import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Alert,
  Container,
  ThemeProvider,
  createTheme,
  IconButton,
  InputAdornment,
  alpha,
  Link,
  Fade,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
  DirectionsCar as CarIcon,
  Instagram as InstagramIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
  Security as SecurityIcon,
  Speed as SpeedIcon,
  Inventory as InventoryIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#F97316',
      light: '#FB923C',
      dark: '#EA580C',
    },
    secondary: {
      main: '#0A192F',
    },
    background: {
      default: '#0A192F',
      paper: '#112240',
    },
    success: {
      main: '#22C55E',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
      letterSpacing: '0.0075em',
    },
    button: {
      fontWeight: 500,
      letterSpacing: '0.02857em',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: alpha('#112240', 0.8),
          backdropFilter: 'blur(12px)',
          borderRadius: 20,
          border: '1px solid rgba(255, 255, 255, 0.08)',
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: `0 8px 24px ${alpha('#000', 0.2)}`,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 12,
            transition: 'all 0.3s ease-in-out',
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.12)',
              borderWidth: 1.5,
            },
            '&:hover fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.23)',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#F97316',
              borderWidth: 2,
            },
            '&.Mui-focused': {
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
            },
          },
          '& .MuiInputLabel-root': {
            transition: 'color 0.3s ease-in-out',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#F97316',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          textTransform: 'none',
          padding: '12px 24px',
          transition: 'all 0.3s ease-in-out',
          fontWeight: 500,
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: `0 8px 16px ${alpha('#F97316', 0.25)}`,
            transform: 'translateY(-1px)',
          },
        },
      },
    },
  },
});

const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  background: `linear-gradient(135deg, ${alpha('#0A192F', 0.97)} 0%, ${alpha('#112240', 0.97)} 100%)`,
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    backgroundImage: 'radial-gradient(circle at 50% 0, rgba(249, 115, 22, 0.15), transparent 75%)',
    pointerEvents: 'none',
  },
}));

const GlassCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  margin: '0 auto',
  overflow: 'visible',
  position: 'relative',
  zIndex: 1,
}));

const LogoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1.5),
  marginBottom: theme.spacing(4),
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const Feature = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: alpha('#fff', 0.85),
  padding: theme.spacing(1, 2),
  borderRadius: 8,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: alpha('#fff', 0.05),
    transform: 'translateY(-2px)',
  },
}));

const Footer = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(4, 0),
  marginTop: 'auto',
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(12px)',
  borderTop: '1px solid rgba(255, 255, 255, 0.08)',
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  color: alpha('#fff', 0.7),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    color: theme.palette.primary.main,
    transform: 'translateY(-2px)',
    backgroundColor: alpha('#fff', 0.05),
  },
}));

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const message = queryParams.get('message');

    if (message === 'sessao-expirada') {
      setErrorMessage('Sua sessão expirou. Faça login novamente.');
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    const action = isNewUser ? 'create' : 'authenticate';
    const body = isNewUser ? { email, password, name } : { email, password };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/prestadores/user.php?action=${action}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        if (isNewUser && data.success) {
          handleLogin();
        } else if (data.token) {
          localStorage.setItem('jwt_token', data.token);
          navigate(`/loja?token=${data.token}`);
        }
      } else {
        setErrorMessage(data.message || 'Ocorreu um erro. Tente novamente.');
      }
    } catch (error) {
      setErrorMessage('Erro de conexão. Verifique sua internet.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async () => {
    console.log(`AQUI Ë O LÖGIN ${process.env.REACT_APP_API_BASE_URL}/prestadores/user.php?action=authenticate`);

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/prestadores/user.php?action=authenticate`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      }
    );

    const data = await response.json();

    if (response.status === 200 && data.token) {
      localStorage.setItem('jwt_token', data.token);
      navigate(`/dashboard?token=${data.token}`);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledContainer maxWidth={false}>
        <Container maxWidth="sm" sx={{ py: 6, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Fade in timeout={800}>
            <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
              <GlassCard elevation={0}>
                <CardContent sx={{ p: { xs: 3, sm: 4 } }}>
                  <LogoBox>
                    <CarIcon sx={{ color: 'primary.main', fontSize: 44 }} />
                    <Typography 
                      variant="h4" 
                      component="h1" 
                      sx={{ 
                        color: 'white', 
                        fontWeight: 600,
                        textShadow: '0 2px 4px rgba(0,0,0,0.1)',
                      }}
                    >
                      Torki Partner
                    </Typography>
                  </LogoBox>

                  <Typography 
                    variant="h5" 
                    align="center" 
                    gutterBottom 
                    sx={{ 
                      mb: 4,
                      color: alpha('#fff', 0.9),
                      textShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                  >
                    {isNewUser ? 'Criar Conta' : 'Bem-vindo de volta'}
                  </Typography>

                  {errorMessage && (
                    <Alert 
                      severity="error" 
                      sx={{ 
                        mb: 3, 
                        bgcolor: alpha('#d32f2f', 0.1),
                        border: '1px solid ' + alpha('#d32f2f', 0.3),
                        borderRadius: 2,
                      }}
                    >
                      {errorMessage}
                    </Alert>
                  )}

                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    {isNewUser && (
                      <TextField
                        fullWidth
                        label="Nome completo"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        sx={{ bgcolor: alpha('#fff', 0.02) }}
                        required
                      />
                    )}

                    <TextField
                      fullWidth
                      label="Email"
                      variant="outlined"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      sx={{ bgcolor: alpha('#fff', 0.02) }}
                      required
                    />

                    <TextField
                      fullWidth
                      label="Senha"
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      sx={{ bgcolor: alpha('#fff', 0.02) }}
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                              sx={{ 
                                color: alpha('#fff', 0.7),
                                '&:hover': { color: '#fff' },
                              }}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      disabled={isLoading}
                      sx={{ 
                        mt: 2,
                        height: 52,
                        fontSize: '1.1rem',
                        position: 'relative',
                        overflow: 'hidden',
                      }}
                    >
                      {isNewUser ? 'Criar Conta' : 'Entrar'}
                    </Button>

                    <Button
                      onClick={() => setIsNewUser(!isNewUser)}
                      variant="text"
                      sx={{ 
                        color: alpha('#fff', 0.7),
                        '&:hover': {
                          color: '#fff',
                          bgcolor: alpha('#fff', 0.05),
                        }
                      }}
                    >
                      {isNewUser 
                        ? 'Já tem uma conta? Faça login' 
                        : 'Novo por aqui? Crie uma conta'}
                    </Button>
                  </Box>
                </CardContent>
              </GlassCard>

              <Box sx={{ 
                mt: 5, 
                display: 'flex', 
                justifyContent: 'center', 
                gap: { xs: 2, sm: 4 },
                px: 2,
              }}>
                <Tooltip title="Dados protegidos com criptografia">
                  <Feature>
                    <SecurityIcon sx={{ color: 'primary.main' }} />
                    <Typography variant="body2">Seguro</Typography>
                  </Feature>
                </Tooltip>
                <Tooltip title="Sistema otimizado para alta performance">
                  <Feature>
                    <SpeedIcon sx={{ color: 'primary.main' }} />
                    <Typography variant="body2">Rápido</Typography>
                  </Feature>
                </Tooltip>
                <Tooltip title="Todas as ferramentas que você precisa">
<Feature>
                    <InventoryIcon sx={{ color: 'primary.main' }} />
                    <Typography variant="body2">Completo</Typography>
                  </Feature>
                </Tooltip>
              </Box>
            </Box>
          </Fade>
        </Container>

        <Footer>
          <Container maxWidth="lg">
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' }, 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              gap: 3,
              px: { xs: 2, sm: 0 }
            }}>
              <Box sx={{ 
                display: 'flex', 
                gap: 3,
                '& a': {
                  color: alpha('#fff', 0.7),
                  transition: 'all 0.3s ease-in-out',
                  fontSize: '0.875rem',
                  '&:hover': {
                    color: '#fff',
                    textDecoration: 'none',
                  }
                }
              }}>
                <Link href="#" underline="hover">Termos</Link>
                <Link href="#" underline="hover">Privacidade</Link>
                <Link href="#" underline="hover">Suporte</Link>
              </Box>

              <Stack 
                direction="row" 
                spacing={2}
                sx={{
                  order: { xs: -1, sm: 0 }
                }}
              >
                <SocialIconButton size="small" aria-label="Instagram">
                  <InstagramIcon />
                </SocialIconButton>
                <SocialIconButton size="small" aria-label="Facebook">
                  <FacebookIcon />
                </SocialIconButton>
                <SocialIconButton size="small" aria-label="Twitter">
                  <TwitterIcon />
                </SocialIconButton>
                <SocialIconButton size="small" aria-label="LinkedIn">
                  <LinkedInIcon />
                </SocialIconButton>
              </Stack>

              <Typography 
                variant="body2" 
                sx={{
                  color: alpha('#fff', 0.5),
                  fontSize: '0.875rem',
                  textAlign: { xs: 'center', sm: 'right' }
                }}
              >
                © 2024 Torki Partner. Todos os direitos reservados.
              </Typography>
            </Box>
          </Container>
        </Footer>
      </StyledContainer>
    </ThemeProvider>
  );
};

export default LoginPage;