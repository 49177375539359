import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
  <div className="footer-content">
    <div className="footer-section">
      <h4>Sobre Nós</h4>
      <p>Conheça mais sobre a Torki e nossos serviços automotivos.</p>
    </div>
    <div className="footer-section">
      <h4>Contato</h4>
      <p>contato@torki.com</p>
      <p>+55 11 9999-9999</p>
    </div>
    <div className="footer-section">
      <h4>Termos</h4>
      <p>Termos de Serviço</p>
      <p>Política de Privacidade</p>
    </div>
  </div>
  <div className="footer-bottom">
    <p>Feito com ❤️ em São Paulo</p>
  </div>
</footer>

  );
};

export default Footer;
