import React, { useState, useEffect } from 'react';
import { Stack, Paper, Grid, Typography, Chip, CircularProgress, Box, Divider } from '@mui/material';
import { Build, AccessTime, DirectionsCar, Verified, ThumbUp } from '@mui/icons-material';

const HistoricoAtendimentos = ({ user_id }) => {
  const [historicoAtendimentos, setHistoricoAtendimentos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchHistoricoAtendimento();
  }, [user_id]);

  const fetchHistoricoAtendimento = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://oyster-app-4ypyg.ondigitalocean.app/api/atendimentos/search?user_id=${user_id}`
      );
      
      if (!response.ok) {
        throw new Error('Falha ao carregar os atendimentos');
      }
      
      const data = await response.json();
      setHistoricoAtendimentos(data.data.atendimentos || []);
    } catch (error) {
      console.error('Erro ao carregar histórico de atendimentos:', error);
      setError('Não foi possível carregar o histórico de atendimentos');
    } finally {
      setLoading(false);
    }
  };

  const anonymizePlate = (plate) => {
    if (!plate) return '***-****';
    return `${plate.slice(0, 3)}-****`;
  };

  const ServicePromoCard = ({ appointment }) => (
    <Box sx={{ mt: 2, p: 2, bgcolor: '#f8f9ff', borderRadius: 1 }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <ThumbUp sx={{ color: '#4CAF50', fontSize: 20 }} />
        <Typography variant="body2" color="success.main" sx={{ fontWeight: 'medium' }}>
          Serviço realizado com sucesso via Torki!
        </Typography>
      </Stack>
      <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
        Este atendimento foi intermediado pela Torki, garantindo qualidade e confiança no serviço prestado.
        Anuncie seu veículo com a credibilidade de quem cuida!
      </Typography>
    </Box>
  );

  if (loading) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ py: 4 }}>
        <CircularProgress />
      </Stack>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center" sx={{ py: 2 }}>
        {error}
      </Typography>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom sx={{ 
        fontWeight: 'bold', 
        color: '#1a237e',
        pb: 2
      }}>
        Histórico de Atendimentos
      </Typography>
      
      <Divider sx={{ mb: 4 }} />

      {!historicoAtendimentos.length ? (
        <Typography align="center" color="text.secondary" sx={{ py: 4 }}>
          Nenhum atendimento encontrado.
        </Typography>
      ) : (
        <Stack spacing={3}>
          {historicoAtendimentos.map((appointment) => (
            <Paper 
              key={appointment.id} 
              elevation={0} 
              sx={{ 
                p: 3,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                '&:hover': {
                  boxShadow: 1,
                  transition: 'box-shadow 0.3s ease-in-out'
                }
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Build sx={{ color: '#1a237e' }} />
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                        {appointment.servico.tipo}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        Serviço Automotivo
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <DirectionsCar sx={{ color: '#455a64' }} />
                    <Box>
                      <Typography variant="body2">
                        {appointment.veiculo.modelo}
                      </Typography>
                      <Typography variant="caption" color="text.secondary" sx={{ fontFamily: 'monospace' }}>
                        Placa: {anonymizePlate(appointment.veiculo.placa)}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <AccessTime sx={{ color: '#455a64' }} />
                    <Box>
                      <Typography variant="body2">
                        {appointment.agendamento.data}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {appointment.agendamento.hora}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>

              {appointment.servico.status === 'concluido' && (
                <>
                  <Divider sx={{ my: 2 }} />
                  <ServicePromoCard appointment={appointment} />
                </>
              )}
            </Paper>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default HistoricoAtendimentos;