import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';  // Adicionando a importação do styled


import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Button,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import ArrowLeft from '@mui/icons-material/ArrowBack';
import Calendar from '@mui/icons-material/CalendarToday';
import Car from '@mui/icons-material/DirectionsCar';
import Clock from '@mui/icons-material/AccessTime';
import DollarSign from '@mui/icons-material/AttachMoney';
import MapPin from '@mui/icons-material/LocationOn';
import Star from '@mui/icons-material/Star';
import User from '@mui/icons-material/Person';
import AlertCircle from '@mui/icons-material/Warning';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Send from '@mui/icons-material/Send';
import FileQuestion from '@mui/icons-material/HelpOutline';


// Styled components remain the same as in original code
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
  }
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '0.875rem',
  padding: theme.spacing(0.5, 1),
  '& .MuiChip-label': {
    padding: theme.spacing(0, 2),
  }
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: 440,
  '& .MuiTableRow-hover:hover': {
    backgroundColor: theme.palette.action.hover,
  }
}));

const ServiceDetails = () => {
  const { scheduleId } = useParams();
  const [serviceData, setServiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [sendingQuote, setSendingQuote] = useState(false);
  const [quoteSent, setQuoteSent] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState([]);


  useEffect(() => {
    fetchServiceDetails();
  }, [scheduleId]);

  

  const fetchServiceDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/prestadores/detalhesServicosBFF.php?schedule_id=${scheduleId}`
      );
      
      console.log('Response status:', response.status);
      const data = await response.json();
      console.log('API Response:', data);
  
      if (response.ok) {
        if (data.data) {
          setServiceData(data.data);
          setQuoteSent(!!data.data.orcamentos?.length);
        } else if (data.success && !data.data) {
          console.warn('Received success=true but no data');
          setError('Dados do serviço não encontrados');
        } else {
          console.warn('Invalid data structure:', data);
          setError('Estrutura de dados inválida');
        }
      } else {
        throw new Error(`Erro na requisição: ${response.status}`);
      }
      setLoading(false);
    } catch (error) {
      console.error('Fetch error:', error);
      setError(error.message);
      setLoading(false);
    }
  };
  const calculateTotal = () => {
    if (!serviceData?.orcamentos?.[0]?.detalhes_servico) return 0;
    
    const details = serviceData.orcamentos[0].detalhes_servico;
    
    // Calcular o total dos produtos
    const productTotal = details.reduce((total, detail) => {
      const productCost = detail.quantity * parseFloat(detail.unit_price);
      const discount = parseFloat(detail.discount || 0);
      const tax = parseFloat(detail.tax || 0);
      return total + productCost - discount + tax;
    }, 0);

    // Pegar apenas o primeiro valor de mão de obra encontrado
    const laborCost = parseFloat(details[0]?.labor_cost || 0);

    return productTotal + laborCost;
  };


  const calculateEarnings = (total) => {
    return total * 0.05;
  };

  const sendQuoteToClient = async () => {
    setSendingQuote(true);
    const totalCost = calculateTotal();
    const dataVencimento = new Date();
    dataVencimento.setDate(dataVencimento.getDate() + 7); // 7 days from now

    try {
      const payload = {
        schedule_id: scheduleId,
        data_vencimento: dataVencimento.toISOString().split('T')[0],
        status: 'PENDENTE',
        valor_total: totalCost,
        observacao: `Orçamento para ${serviceData?.agendamento?.maintenance_type || 'serviço'}`
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/prestadores/orcamentos.php?action=create`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();

      if (data.success) {
        setQuoteSent(true);
        fetchServiceDetails(); // Refresh data
      } else {
        throw new Error(data.message || 'Erro ao enviar o orçamento');
      }
    } catch (error) {
      setError(`Erro ao enviar o orçamento: ${error.message}`);
    } finally {
      setSendingQuote(false);
    }
  };

  const requestQuote = () => {
    const plate = serviceData?.veiculo?.plate || '';
    window.open(`/loja/servicos/orcamento/${plate}/${scheduleId}`, '_blank');
  };

  const getStatusColor = (status) => {
    const statusColors = {
      'agendado': 'warning',
      'confirmado': 'info',
      'em andamento': 'primary',
      'concluído': 'success',
      'pago': 'success',
      'pendente': 'warning'
    };
    return statusColors[status?.toLowerCase()] || 'default';
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Data não disponível';
    return new Date(dateString).toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return (
      <Box sx={{ p: 3 }}>
        <LinearProgress sx={{ mb: 2 }} />
        <Typography variant="body1" color="text.secondary">
          Carregando detalhes do serviço...
        </Typography>
      </Box>
    );
  }

  if (!serviceData) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" color="error">
          Nenhum dado encontrado
        </Typography>
      </Box>
    );
  }

  const { agendamento, veiculo, fornecedor, orcamentos } = serviceData;
  const totalCost = calculateTotal();
  const earnings = calculateEarnings(totalCost);

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', p: 3 }}>    
      <Button
        startIcon={<ArrowLeft />}
        onClick={() => navigate(`/dashboard?token=${new URLSearchParams(location.search).get('token')}`)}
        sx={{ mb: 3 }}
        variant="contained"
        color="primary"
      >
        Voltar para o Dashboard
      </Button>

      {error && (
        <Paper 
          elevation={3}
          sx={{ 
            p: 2, 
            mb: 3,
            bgcolor: 'warning.light',
            color: 'warning.dark'
          }}
        >
          <IconWrapper>
            <AlertCircle size={20} />
            <Typography variant="body1">{error}</Typography>
          </IconWrapper>
        </Paper>
      )}

      <Grid container spacing={3}>
        {/* Header Card */}
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box>
                  <Typography variant="h4" gutterBottom>
                    {agendamento.maintenance_type}
                  </Typography>
                  <IconWrapper>
                    <Calendar size={16} />
                    <Typography variant="subtitle1" color="text.secondary">
                      {formatDate(agendamento.scheduled_date)}
                    </Typography>
                  </IconWrapper>
                </Box>
                <StyledChip
                  label={agendamento.status}
                  color={getStatusColor(agendamento.status)}
                />
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Vehicle Info */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <IconWrapper sx={{ mb: 3 }}>
                <Car size={20} />
                <Typography variant="h6">Informações do Veículo</Typography>
              </IconWrapper>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="overline" display="block">Veículo</Typography>
                  <Typography variant="h6">{veiculo.fabricante} {veiculo.model}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {veiculo.year} - {veiculo.color}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="overline" display="block">Placa</Typography>
                  <Typography variant="h6">{veiculo.plate}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Próximo serviço: {agendamento.next_service_distance}km
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Supplier Info */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <IconWrapper sx={{ mb: 3 }}>
                <User size={20} />
                <Typography variant="h6">Informações do Fornecedor</Typography>
              </IconWrapper>
              <Typography variant="h5" gutterBottom>
                {fornecedor.name}
              </Typography>
              <IconWrapper>
                <Star size={16} sx={{ color: 'warning.main' }} />
                <Typography variant="body1">
                  {fornecedor.rating} ({fornecedor.reviews} avaliações)
                </Typography>
              </IconWrapper>
              {fornecedor.location_lat && fornecedor.location_long && (
                <IconWrapper sx={{ mt: 1 }}>
                  <MapPin size={16} />
                  <Typography variant="body2" color="text.secondary">
                    Localização disponível
                  </Typography>
                </IconWrapper>
              )}
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Service Details Table */}
        {orcamentos?.[0]?.detalhes_servico && (
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <IconWrapper sx={{ mb: 3 }}>
                <Typography variant="h6">Detalhes do Serviço</Typography>
              </IconWrapper>
              <StyledTableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Descrição</TableCell>
                      <TableCell align="right">Quantidade</TableCell>
                      <TableCell align="right">Preço Unit.</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orcamentos[0].detalhes_servico.map((detail, index) => (
                      <TableRow key={index} hover>
                        <TableCell>{detail.product_description}</TableCell>
                        <TableCell align="right">{detail.quantity}</TableCell>
                        <TableCell align="right">{formatCurrency(detail.unit_price)}</TableCell>
                        <TableCell>{formatDate(detail.service_date)}</TableCell>
                        <TableCell align="right">
                          {formatCurrency(detail.quantity * detail.unit_price)}
                        </TableCell>
                      </TableRow>
                    ))}
                    {/* Linha adicional para mão de obra */}
                    {parseFloat(orcamentos[0].detalhes_servico[0]?.labor_cost) > 0 && (
                      <TableRow>
                        <TableCell colSpan={4} align="right">
                          <Typography variant="subtitle1" fontWeight="bold">
                            Mão de Obra
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {formatCurrency(parseFloat(orcamentos[0].detalhes_servico[0].labor_cost))}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </CardContent>
          </StyledCard>
        </Grid>
      )}

        {/* Financial Summary */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <IconWrapper sx={{ mb: 3 }}>
                <DollarSign size={20} />
                <Typography variant="h6">Resumo Financeiro</Typography>
              </IconWrapper>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="overline" display="block">Total do Orçamento</Typography>
                  <Typography variant="h5" color="primary">
                    {formatCurrency(totalCost)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="overline" display="block">Seus Ganhos</Typography>
                  <Typography variant="h5" color="success.main">
                    {formatCurrency(earnings)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Actions */}
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <IconWrapper>
                  <Send size={20} />
                  <Typography variant="h6">Ações</Typography>
                </IconWrapper>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {agendamento.status.toLowerCase() === 'agendado' && (
                    <Button
                      variant="contained"
                      color={quoteSent ? 'success' : 'primary'}
                      disabled={sendingQuote || quoteSent}
                      onClick={sendQuoteToClient}
                      startIcon={quoteSent ? <CheckCircle /> : <Send />}
                      sx={{ 
                        minWidth: 200,
                        height: 48,
                        fontSize: '1rem',
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                          transform: 'translateY(-2px)'
                        }
                      }}
                    >
                      {sendingQuote ? 'Enviando...' : quoteSent ? 'Orçamento Enviado' : 'Enviar Orçamento'}
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={requestQuote}
                    startIcon={<FileQuestion />}
                    sx={{ 
                      minWidth: 200,
                      height: 48,
                      fontSize: '1rem',
                      transition: 'all 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-2px)'
                      }
                    }}
                  >
                    Pedir Orçamento
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      {/* Status de Orçamento */}
      {orcamentos?.[0] && (
        <Grid item xs={12} sx={{ mt: 3 }}>
          <StyledCard>
            <CardContent>
              <IconWrapper sx={{ mb: 3 }}>
                <Clock size={20} />
                <Typography variant="h6">Status do Orçamento</Typography>
              </IconWrapper>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="overline" display="block">Número do Orçamento</Typography>
                  <Typography variant="h6">#{orcamentos[0].id}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="overline" display="block">Data de Vencimento</Typography>
                  <Typography variant="h6">{formatDate(orcamentos[0].data_vencimento)}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="overline" display="block">Status</Typography>
                  <StyledChip
                    label={orcamentos[0].status}
                    color={getStatusColor(orcamentos[0].status)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>        
      )}      
    </Box>    
  );  
};

export default ServiceDetails;