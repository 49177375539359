// QuickTicketForm.js
import React from 'react';
import { Box, TextField, Typography, Button } from '@mui/material';

const QuickTicketForm = () => (
  <Box bgcolor="background.paper" p={3} borderRadius={2}>
    <Typography variant="h6" color="text.primary" mb={2}>
      Create Quick Ticket
    </Typography>
    <TextField fullWidth label="Customer Email" variant="outlined" margin="dense" />
    <TextField fullWidth label="Request Ticket Type" variant="outlined" margin="dense" />
    <TextField fullWidth label="Priority Status" variant="outlined" margin="dense" />
    <TextField fullWidth label="Ticket Body" variant="outlined" margin="dense" multiline rows={4} />
    <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
      Submit
    </Button>
  </Box>
);

export default QuickTicketForm;
