import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Badge,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Grid,
  Container,
  Chip,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Paper,
} from '@mui/material';
import {
  Search as SearchIcon,
  ShoppingCart as ShoppingCartIcon,
  Person as PersonIcon,
  Star as StarIcon,
  LocalShipping as ShippingIcon,
  Store as StoreIcon,
  Build as BuildIcon,
  DirectionsCar as CarIcon,
  Menu as MenuIcon,
  KeyboardArrowRight as ArrowIcon,
  LocalOffer as OfferIcon,
  Favorite as FavoriteIcon,
} from '@mui/icons-material';

const TorkiMarketplace = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  
  const categories = [
    { name: 'Motor', icon: <BuildIcon /> },
    { name: 'Suspensão', icon: <CarIcon /> },
    { name: 'Freios', icon: <BuildIcon /> },
    { name: 'Acessórios', icon: <StoreIcon /> },
  ];

  const featuredProducts = [
    {
      id: 1,
      name: 'Kit Suspensão Premium',
      price: 1299.90,
      rating: 4.8,
      reviews: 234,
      seller: 'Auto Parts Elite',
      discount: 15,
      shipping: 'Grátis',
      image: '/api/placeholder/400/300',
    },
    {
      id: 2,
      name: 'Óleo Sintético 5W30',
      price: 249.90,
      rating: 4.9,
      reviews: 456,
      seller: 'Lubrificantes Pro',
      discount: 20,
      shipping: 'Express',
      image: '/api/placeholder/400/300',
    },
    {
      id: 3,
      name: 'Kit Freio Completo',
      price: 899.90,
      rating: 4.7,
      reviews: 189,
      seller: 'Freios Master',
      discount: 10,
      shipping: 'Grátis',
      image: '/api/placeholder/400/300',
    },
  ];

  return (
    <Box sx={{ backgroundColor: '#f5f5f7', minHeight: '100vh' }}>
      <AppBar position="fixed" sx={{ backgroundColor: '#232F3E' }}>
        <Toolbar sx={{ justifyContent: 'space-between', px: { xs: 1, md: 3 } }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              sx={{ display: { md: 'none' }, mr: 1 }}
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
            <Box component="a" href="#" sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#FF9900' }}>
                TORKI
              </Typography>
            </Box>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            backgroundColor: 'white', 
            borderRadius: 2,
            width: { xs: '40%', md: '50%' },
            px: 2
          }}>
            <InputBase
              placeholder="Busque peças, acessórios ou serviços..."
              sx={{ ml: 1, flex: 1 }}
            />
            <IconButton>
              <SearchIcon />
            </IconButton>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Button 
              startIcon={<PersonIcon />}
              sx={{ 
                color: 'white',
                display: { xs: 'none', md: 'flex' }
              }}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              Entrar
            </Button>
            <IconButton sx={{ color: 'white' }}>
              <Badge badgeContent={3} color="error">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
          </Box>
        </Toolbar>

        <Box 
          sx={{ 
            bgcolor: '#131921', 
            py: 1,
            px: 3,
            display: { xs: 'none', md: 'flex' },
            gap: 4
          }}
        >
          {categories.map((category) => (
            <Button
              key={category.name}
              startIcon={category.icon}
              sx={{ color: 'white' }}
            >
              {category.name}
            </Button>
          ))}
        </Box>
      </AppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List sx={{ width: 250 }}>
          {categories.map((category) => (
            <ListItem button key={category.name}>
              <ListItemIcon>{category.icon}</ListItemIcon>
              <ListItemText primary={category.name} />
              <ArrowIcon />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem>Minha Conta</MenuItem>
        <MenuItem>Meus Pedidos</MenuItem>
        <MenuItem>Sair</MenuItem>
      </Menu>

      <Box sx={{ pt: { xs: 8, md: 16 }, pb: 6 }}>
        <Container maxWidth="lg">
          <Box sx={{ 
            backgroundImage: 'url("/api/placeholder/1200/400")',
            height: 400,
            borderRadius: 4,
            mb: 6,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            px: 6,
            bgcolor: 'rgba(0,0,0,0.4)',
            backgroundBlend: 'overlay',
            backgroundSize: 'cover'
          }}>
            <Box sx={{ color: 'white', maxWidth: 600 }}>
              <Typography variant="h3" fontWeight="bold" gutterBottom>
                Bem-vindo à Torki
              </Typography>
              <Typography variant="h5" sx={{ mb: 4 }}>
                O maior marketplace de peças e acessórios automotivos do Brasil
              </Typography>
              <Button 
                variant="contained" 
                size="large"
                sx={{ 
                  bgcolor: '#FF9900',
                  '&:hover': { bgcolor: '#F90' }
                }}
              >
                Explorar Produtos
              </Button>
            </Box>
          </Box>

          <Grid container spacing={4}>
            {featuredProducts.map((product) => (
              <Grid item xs={12} md={4} key={product.id}>
                <Card sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  transition: '0.3s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: 6
                  }
                }}>
                  {product.discount && (
                    <Chip
                      label={`-${product.discount}%`}
                      color="error"
                      sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        zIndex: 1
                      }}
                    />
                  )}
                  <CardMedia
                    component="img"
                    image={product.image}
                    alt={product.name}
                    sx={{ height: 200 }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" gutterBottom>
                      {product.name}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      {[...Array(5)].map((_, i) => (
                        <StarIcon 
                          key={i} 
                          sx={{ 
                            fontSize: 18, 
                            color: i < Math.floor(product.rating) ? '#FFC107' : 'grey.300'
                          }} 
                        />
                      ))}
                      <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                        ({product.reviews})
                      </Typography>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      Vendido por {product.seller}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                      <Typography variant="h5" color="primary" fontWeight="bold">
                        R$ {product.price.toFixed(2)}
                      </Typography>
                      {product.shipping === 'Grátis' && (
                        <Chip
                          icon={<ShippingIcon sx={{ fontSize: 16 }} />}
                          label="Frete Grátis"
                          size="small"
                          color="success"
                        />
                      )}
                    </Box>
                  </CardContent>
                  <Box sx={{ p: 2, pt: 0 }}>
                    <Button 
                      variant="contained" 
                      fullWidth 
                      sx={{ 
                        bgcolor: '#FF9900',
                        '&:hover': { bgcolor: '#F90' }
                      }}
                    >
                      Adicionar ao Carrinho
                    </Button>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ mt: 8 }}>
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              Por que escolher a Torki?
            </Typography>
            <Grid container spacing={4}>
              {[
                {
                  icon: <ShippingIcon sx={{ fontSize: 40 }} />,
                  title: 'Entrega Rápida',
                  desc: 'Enviamos para todo o Brasil'
                },
                {
                  icon: <StoreIcon sx={{ fontSize: 40 }} />,
                  title: 'Lojistas Verificados',
                  desc: 'Produtos 100% originais'
                },
                {
                  icon: <OfferIcon sx={{ fontSize: 40 }} />,
                  title: 'Melhores Preços',
                  desc: 'Ofertas imperdíveis'
                }
              ].map((feature, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Paper sx={{ p: 3, textAlign: 'center', height: '100%' }}>
                    <Box sx={{ color: '#FF9900', mb: 2 }}>
                      {feature.icon}
                    </Box>
                    <Typography variant="h6" gutterBottom>
                      {feature.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {feature.desc}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>

      <Box sx={{ bgcolor: '#232F3E', color: 'white', py: 6 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Sobre a Torki
              </Typography>
              <Typography variant="body2">
                O maior marketplace de peças automotivas do Brasil, conectando lojistas e compradores.
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Para Lojistas
              </Typography>
              <List>
                {['Venda na Torki', 'Central do Vendedor', 'Programa de Parceiros'].map((item) => (
                  <ListItem key={item} sx={{ px: 0 }}>
                    <Typography variant="body2">{item}</Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Ajuda
              </Typography>
              <List>
                {['Central de Ajuda', 'Política de Trocas', 'Fale Conosco'].map((item) => (
                  <ListItem key={item} sx={{ px: 0 }}>
                    <Typography variant="body2">{item}</Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Redes Sociais
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                {['Facebook', 'Instagram', 'LinkedIn'].map((social) => (
                  <IconButton key={social} sx={{ color: 'white' }}>
                    <PersonIcon />
                  </IconButton>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default TorkiMarketplace;