// NotificationPanel.jsx
import React, { useEffect, useState } from 'react';
import { 
  Typography,
  Box,
  Slide,
  styled,
  Card,
  CardContent,
  Stack,
  Chip,
  Divider,
  IconButton,
  Collapse,
  Badge,
  Tooltip,
  LinearProgress
} from '@mui/material';


import {
  DirectionsCar as CarIcon,
  Build as ToolsIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Timeline as TimelineIcon,
  Close as CloseIcon,
  Notifications as NotificationsIcon,
  AccessTime as AccessTimeIcon
} from '@mui/icons-material';

// Keyframes para animações
const pulse = `
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(33, 150, 243, 0.4);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(33, 150, 243, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(33, 150, 243, 0);
    }
  }
`;

const shimmer = `
  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`;

const NotificationCard = styled(Card)(({ theme, status }) => ({
  width: 380,
  marginBottom: theme.spacing(2),
  marginRight: theme.spacing(2),
  background: status === 'APROVADO'
    ? `linear-gradient(45deg, ${theme.palette.grey[900]} 30%, ${theme.palette.success.dark} 90%)`
    : `linear-gradient(45deg, ${theme.palette.grey[900]} 30%, ${theme.palette.error.dark} 90%)`,
  color: theme.palette.common.white,
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  animation: 'pulse 2s infinite',
  '&:hover': {
    transform: 'translateX(-10px)',
    boxShadow: theme.shadows[10],
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '2px',
    background: status === 'APROVADO' ? theme.palette.success.main : theme.palette.error.main,
    animation: 'shimmer 2s infinite linear',
    backgroundImage: `linear-gradient(to right, transparent 0%, ${theme.palette.primary.main} 50%, transparent 100%)`,
  },
}));

const ToggleButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  right: theme.spacing(2),
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[4],
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  zIndex: 9998,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
}));

const NotificationPanel = () => {
  const [notifications, setNotifications] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    // Adiciona notificação de teste
    addTestNotification();

    // Inicia o timer de progresso
    const progressInterval = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = oldProgress - 0.5;
        return newProgress <= 0 ? 100 : newProgress;
      });
    }, 50);

    return () => {
      clearInterval(progressInterval);
    };
  }, []);

  const addTestNotification = () => {
    const testNotifications = [
      {
        plate: "TEST123",
        maintenance_type: "Revisão Completa",
        status: "APROVADO",
        valor_total: "850.00",
        timestamp: new Date().toLocaleTimeString(),
      },
      {
        plate: "XYZ789",
        maintenance_type: "Troca de Óleo",
        status: "REJEITADO",
        valor_total: "258.00",
        timestamp: new Date().toLocaleTimeString(),
      }
    ];
    
    setNotifications(testNotifications);
  };

  const handleClose = (index) => {
    const newNotifications = notifications.filter((_, i) => i !== index);
    setNotifications(newNotifications);
  };

  const formatTimeAgo = (timestamp) => {
    return `Há ${Math.floor(Math.random() * 5) + 1} minutos`; // Simulação
  };

  if (notifications.length === 0) return null;

  return (
    <>
      <ToggleButton 
        onClick={() => setIsPanelOpen(!isPanelOpen)}
        size="large"
      >
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          variant="dot"
          invisible={!notifications.length}
        >
          <NotificationsIcon color="primary" />
        </StyledBadge>
      </ToggleButton>

      <Box
        sx={{
          position: 'fixed',
          right: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 9999,
          width: 'auto',
          maxWidth: '100%',
          display: 'block !important',
        }}
      >
        <Collapse in={isPanelOpen} orientation="horizontal">
          <Slide direction="left" in={isVisible} mountOnEnter unmountOnExit timeout={500}>
            <Stack spacing={2} sx={{ margin: 2 }}>
              {notifications.map((notification, index) => (
                <NotificationCard 
                  key={index} 
                  elevation={6} 
                  status={notification.status}
                >
                  <Box sx={{ position: 'relative' }}>
                    <LinearProgress 
                      variant="determinate" 
                      value={progress} 
                      sx={{ 
                        position: 'absolute', 
                        top: 0, 
                        left: 0, 
                        right: 0,
                        height: '2px',
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: notification.status === 'APROVADO' ? 
                            'success.light' : 'error.light',
                        }
                      }} 
                    />
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Tooltip title="Tempo desde a notificação" arrow>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <AccessTimeIcon sx={{ fontSize: 16, opacity: 0.7 }} />
                            <Typography variant="caption" sx={{ opacity: 0.7 }}>
                              {formatTimeAgo(notification.timestamp)}
                            </Typography>
                          </Box>
                        </Tooltip>
                        <IconButton
                          size="small"
                          onClick={() => handleClose(index)}
                          sx={{ color: 'white', opacity: 0.7, '&:hover': { opacity: 1 } }}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>

                      <Stack spacing={2}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Box sx={{ 
                            bgcolor: 'rgba(255,255,255,0.1)', 
                            borderRadius: '50%', 
                            p: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                            <CarIcon color="inherit" sx={{ fontSize: 32 }} />
                          </Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h6" component="div" sx={{ mb: 0.5 }}>
                              {notification.plate}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <ToolsIcon sx={{ fontSize: 16, color: 'grey.400' }} />
                              <Typography variant="body2" color="grey.400">
                                {notification.maintenance_type}
                              </Typography>
                            </Box>
                          </Box>
                          <Chip
                            label={notification.status}
                            icon={notification.status === 'APROVADO' ? 
                              <CheckCircleIcon /> : <CancelIcon />
                            }
                            sx={{
                              bgcolor: notification.status === 'APROVADO' ? 
                                'success.main' : 'error.main',
                              '& .MuiChip-icon': {
                                color: 'inherit'
                              }
                            }}
                          />
                        </Box>

                        <Divider sx={{ bgcolor: 'rgba(255,255,255,0.1)' }} />

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="body2" color="grey.400">
                            Valor Total:
                          </Typography>
                          <Typography variant="h6" sx={{ 
                            color: notification.status === 'APROVADO' ? 
                              'success.light' : 'error.light',
                            fontWeight: 'bold'
                          }}>
                            R$ {notification.valor_total}
                          </Typography>
                        </Box>
                      </Stack>
                    </CardContent>
                  </Box>
                </NotificationCard>
              ))}
            </Stack>
          </Slide>
        </Collapse>
      </Box>
    </>
  );
};

export default NotificationPanel;