import React from 'react';
import { AppBar, Box, Container, Stack, Typography, Button, alpha, IconButton, Menu, MenuItem } from '@mui/material';
import { Download, Menu as MenuIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { DirectionsCar as CarIcon } from '@mui/icons-material';


const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="sticky" sx={{ bgcolor: alpha(theme.palette.background.paper, 0.9), backdropFilter: 'blur(10px)' }}>
      <Container maxWidth="lg">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ py: 1 }}
        >
          <Link to="/classificados" style={{ textDecoration: 'none' }}>
            <Box display="flex" alignItems="center" gap={1}>
              <CarIcon color="primary" />
              <Typography variant="h6" component="div" fontWeight="bold" color="primary">
                Torki
              </Typography>
            </Box>
          </Link>

          {isMobile ? (
            // Ícone de menu para dispositivos móveis
            <>
              <IconButton color="inherit" onClick={handleMenuOpen}>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleMenuClose}>Carros</MenuItem>
                <MenuItem onClick={handleMenuClose}>Motos</MenuItem>
                <MenuItem onClick={handleMenuClose}>Caminhões</MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Download />}
                    fullWidth
                  >
                    Baixar App
                  </Button>
                </MenuItem>
              </Menu>
            </>
          ) : (
            // Exibir botões diretamente em telas maiores
            <Stack direction="row" spacing={2}>
              <Button color="inherit">Carros</Button>
              <Button color="inherit">Motos</Button>
              <Button color="inherit">Caminhões</Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Download />}
              >
                Baixar App
              </Button>
            </Stack>
          )}
        </Stack>
      </Container>
    </AppBar>
  );
};

export default Header;
