import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  Snackbar,
} from '@mui/material';
import InputMask from 'react-input-mask';
import axios from 'axios';
import MuiAlert from '@mui/material/Alert';

const steps = ['Informações Pessoais', 'Endereço', 'Confirmação'];

const professions = [
  { value: 'lawyer', label: 'Advogado' },
  { value: 'doctor', label: 'Médico' },
  { value: 'accountant', label: 'Contador' },
  { value: 'realtor', label: 'Corretor de Imóveis' },
  { value: 'broker', label: 'Corretor de Seguro, Vida ou Previdência' },
  { value: 'physicalEducator', label: 'Educador Físico' },
  { value: 'physiotherapist', label: 'Fisioterapeuta' },
  { value: 'others', label: 'Outros' },
];

const AccountJourney = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    document: '',
    phone: '',
    emailContact: '',
    logo: '', // Base64 logo será preenchido aqui
    softDescriptor: 'Torki', // Valor fixo
    professional: '',
    otherProfessional: '',
    inscription: '', // Campo de inscrição profissional
    Address: {
      zipCode: '',
      street: '',
      number: '',
      neighborhood: '',
      city: '',
      state: '',
    },
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const keys = name.split('.');
      setFormData((prev) => ({
        ...prev,
        [keys[0]]: {
          ...prev[keys[0]],
          [keys[1]]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const fetchToken = async () => {
    const url = 'https://api.sandbox.cel.cash/v2/token';
    const headers = {
      'Authorization': 'Basic NTQ3Mzo4M013NXU4OTg4UWo2ZlpxUzRaOEs3THpPbzFqMjhTNzA2UjBCZUZl',
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify({
      grant_type: 'authorization_code',
      scope: 'customers.read customers.write plans.read plans.write transactions.read transactions.write webhooks.write balance.read balance.write cards.read cards.write card-brands.read subscriptions.read subscriptions.write charges.read charges.write boletos.read carnes.read payment-methods.read company.write'
    });

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: body,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  };

  const createSubaccount = async (subaccountData, tokenResponse) => {
    const url = 'https://api.sandbox.cel.cash/v2/company/subaccount';
    const headers = {
      'Authorization': `${tokenResponse.token_type} ${tokenResponse.access_token}`,
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify(subaccountData);

    console.log("body " + body);

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: body,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
        let errorMessage = 'Erro ao criar a conta. Tente novamente.';
    
        // Verificar se existe uma resposta de erro com detalhes
        if (error.response) {
          // Extrair informações do erro
          if (error.response.data && error.response.data.message) {
            errorMessage = `Erro: ${error.response.data.message}`;
          } else if (error.response.status) {
            errorMessage = `Erro ${error.response.status}: Ocorreu um problema ao processar sua solicitação.`;
          }
        } else if (error.message) {
          // Caso não seja erro de resposta HTTP, use a mensagem geral
          errorMessage = `Erro: ${error.message}`;
        }
    
        // Exibir mensagem de erro no Snackbar
        setSnackbarMessage(errorMessage);
        setOpenSnackbar(true);
    
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const tokenResponse = await fetchToken();

      // Clonar os dados do formulário para manipulação
    
    const subaccountData = { 
        ...formData,
        Professional: {
          internalName: formData.professional,  // Define internalName
          inscription: formData.inscription  // Define internalName
        },
      };
  
  

    
    // Remover a formatação dos campos CPF, CNPJ, telefone e CEP
    subaccountData.document = subaccountData.document.replace(/\D/g, ''); // Remove tudo que não é dígito do CPF/CNPJ
    subaccountData.phone = subaccountData.phone.replace(/\D/g, ''); // Remove formatação do telefone
    subaccountData.Address.zipCode = subaccountData.Address.zipCode.replace(/\D/g, ''); // Remove formatação do CEP
    if (subaccountData.responsibleDocument) {
      subaccountData.responsibleDocument = subaccountData.responsibleDocument.replace(/\D/g, '');
    }

      if (subaccountData.professional !== 'others' && formData.inscription) {
        subaccountData.inscription = formData.inscription;
      }

      const createdAccountData = await createSubaccount(subaccountData, tokenResponse);
      setSnackbarMessage('Conta criada com sucesso!');
      setOpenSnackbar(true);

      // Enviar os dados de retorno para o endpoint externo
        await axios.post(
            'https://dojo.stratusport.co/torki/prestadores/jornada_abre_conta_pf.php?action=create',
        createdAccountData,
        { headers: { 'Content-Type': 'application/json' } }
      );
      
    } catch (error) {
      console.error('Error submitting the form', error);
      setSnackbarMessage('Erro ao criar a conta. Tente novamente.');
      setOpenSnackbar(true);
    }
  };

  const handleCepBlur = async () => {
    const cep = formData.Address.zipCode.replace('-', '');
    if (cep.length === 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        if (!response.data.erro) {
          setFormData((prev) => ({
            ...prev,
            Address: {
              ...prev.Address,
              street: response.data.logradouro,
              neighborhood: response.data.bairro,
              city: response.data.localidade,
              state: response.data.uf,
            },
          }));
        } else {
          setSnackbarMessage('CEP não encontrado.');
          setOpenSnackbar(true);
        }
      } catch (error) {
        console.error('Error fetching CEP data', error);
        setSnackbarMessage('Erro ao buscar dados do CEP.');
        setOpenSnackbar(true);
      }
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nome"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Digite seu nome completo"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputMask mask="999.999.999-99" value={formData.document} onChange={handleChange} maskChar="">
                {() => (
                  <TextField
                    fullWidth
                    label="Documento"
                    name="document"
                    required
                    placeholder="CPF"
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputMask mask="(99) 99999-9999" value={formData.phone} onChange={handleChange} maskChar="">
                {() => (
                  <TextField
                    fullWidth
                    label="Telefone"
                    name="phone"
                    required
                    placeholder="(XX) XXXX-XXXX"
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                name="emailContact"
                type="email"
                value={formData.emailContact}
                onChange={handleChange}
                required
                placeholder="exemplo@dominio.com"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Logo (Base64)"
                name="logo"
                value={formData.logo}
                onChange={handleChange}
                placeholder="Opcional"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Profissão</InputLabel>
                <Select
                  name="professional"
                  value={formData.professional}
                  onChange={handleChange}
                >
                  {professions.map((profession) => (
                    <MenuItem key={profession.value} value={profession.value}>
                      {profession.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {formData.professional !== 'others' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Inscription"
                  name="inscription"
                  value={formData.inscription}
                  onChange={handleChange}
                  required
                  placeholder="Número de inscrição profissional"
                />
              </Grid>
            )}
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputMask mask="99999-999" value={formData.Address.zipCode} onChange={handleChange} maskChar="" onBlur={handleCepBlur}>
                {() => (
                  <TextField
                    fullWidth
                    label="CEP"
                    name="Address.zipCode"
                    required
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Rua"
                name="Address.street"
                value={formData.Address.street}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Número"
                name="Address.number"
                value={formData.Address.number}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Bairro"
                name="Address.neighborhood"
                value={formData.Address.neighborhood}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Cidade"
                name="Address.city"
                value={formData.Address.city}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Estado"
                name="Address.state"
                value={formData.Address.state}
                onChange={handleChange}
                required
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Typography variant="h6" align="center">
            Confirme suas informações e clique em Criar Conta para finalizar.
          </Typography>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Container component={Paper} elevation={3} sx={{ padding: '2rem', marginTop: '2rem' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Abertura de Conta
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <form onSubmit={handleSubmit}>
        {renderStepContent(activeStep)}
        <Grid container justifyContent="space-between" sx={{ marginTop: '2rem' }}>
          {activeStep > 0 && (
            <Button onClick={handleBack} variant="outlined" color="primary">
              Voltar
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
          >
            {activeStep === steps.length - 1 ? 'Criar Conta' : 'Próximo'}
          </Button>
        </Grid>
      </form>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <MuiAlert elevation={6} variant="filled" onClose={() => setOpenSnackbar(false)} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};

export default AccountJourney;
