import React from 'react';
import FornecedoresContent from './FornecedoresContent';
import { useParams } from 'react-router-dom';
import theme from '../../Tema';
import { ThemeProvider } from '@mui/material/styles';
import {
  Box,
  CssBaseline
} from '@mui/material';
import Layout from '../Layout';



const Services = () => {
  return (
    
    <Layout>
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <FornecedoresContent />         
      </ThemeProvider>  
      </Layout>
    
  );
};

export default Services;
