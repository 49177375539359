import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useScrollTrigger,
  useTheme,
  ThemeProvider,
  createTheme,
  CssBaseline
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import {
  ChevronRight,
  Menu as MenuIcon,
  Close as CloseIcon,
  AccountBalanceWallet as WalletIcon,
  Search as SearchIcon,
  Business as BusinessIcon,
  DirectionsCar as CarIcon,
  ShoppingCart as CartIcon,
  People as PeopleIcon,
  Newspaper as NewsIcon,
  AttachMoney as MoneyIcon,
  Favorite as HeartIcon
} from '@mui/icons-material';
import { useNavigate,useLocation } from 'react-router-dom';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#F97316',
    },
    secondary: {
      main: '#0A192F',
    },
    background: {
      default: '#0A192F',
      paper: '#112240',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '4rem',
      fontWeight: 700,
      lineHeight: 1.2,
      '@media (max-width:600px)': {
        fontSize: '2.5rem',
      },
    },
    h2: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: 1.2,
      '@media (max-width:600px)': {
        fontSize: '2rem',
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          padding: '10px 24px',
          fontSize: '1rem',
        },
        containedPrimary: {
          '&:hover': {
            transform: 'translateY(-2px)',
            transition: 'transform 0.2s ease-in-out',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          background: alpha('#112240', 0.5),
          backdropFilter: 'blur(10px)',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-8px)',
          },
        },
      },
    },
  },
});

const GradientBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
  position: 'relative',
  overflow: 'hidden',
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'transparent',
  boxShadow: 'none',
  transition: 'all 0.3s ease-in-out',
  '&.scrolled': {
    background: alpha(theme.palette.background.default, 0.95),
    backdropFilter: 'blur(10px)',
  },
}));

const FloatingEffect = styled(Box)({
  position: 'absolute',
  borderRadius: '50%',
  filter: 'blur(50px)',
  animation: 'float 10s infinite ease-in-out',
  '@keyframes float': {
    '0%, 100%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(-20px)' },
  },
});

const LandingPage = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate(); // Hook para navegação
  const location = useLocation();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  const features = [
    {
      icon: <WalletIcon />,
      title: "Banco Digital Automotivo",
      description: "Soluções financeiras especializadas para o mercado automotivo",
      items: ["Pagamentos integrados", "Financiamento facilitado", "Gestão financeira"]
    },
    {
      icon: <SearchIcon />,
      title: "Classificados & Marketplace",
      description: "Seu hub de negócios automotivos",
      items: ["Anúncios verificados", "Comparação de preços", "Avaliações confiáveis"]
    },
    {
      icon: <BusinessIcon />,
      title: "Rede de Negócios",
      description: "Conectando toda a cadeia automotiva",
      items: ["Networking B2B", "Oportunidades exclusivas", "Parcerias estratégicas"]
    }
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {/* Header */}
      <StyledAppBar position="fixed" className={trigger ? 'scrolled' : ''}>
        <Container>
          <Box display="flex" alignItems="center" py={2}>
            <Box display="flex" alignItems="center" gap={1}>
              <CarIcon color="primary" />
              <Typography variant="h6" component="div" fontWeight="bold">
                Torki
              </Typography>
            </Box>
            
            <Box sx={{ flexGrow: 1 }} />
            
            <Box sx={{ display: { xs: 'none', md: 'flex' } }} gap={4}>
              <Button color="inherit" href="#solutions">Soluções</Button>
              <Button color="inherit" href="#marketplace">Marketplace</Button>
              <Button color="inherit" href="/classificados">Classificados</Button>
              <Button color="inherit" href="#network">Rede</Button>
              <Button color="inherit" href="#about">Sobre</Button>
              <Button variant="contained" color="primary" onClick={() => {
              navigate("/login"); // Navega para o link
                setMobileOpen(false); // Fecha o drawer em dispositivos móveis
              }}>
                Acessar Plataforma
              </Button>
            </Box>
            
            <IconButton
              color="inherit"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Container>
      </StyledAppBar>

      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { 
            width: '100%',
            background: theme.palette.background.default,
          },
        }}
      >
        <Box p={2} display="flex" justifyContent="flex-end">
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {['Soluções', 'Marketplace', 'Rede', 'Sobre'].map((text) => (
            <ListItem button key={text} onClick={handleDrawerToggle}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
          <ListItem>
            <Button fullWidth variant="contained" color="primary">
              Acessar Plataforma
            </Button>
          </ListItem>
        </List>
      </Drawer>

      {/* Hero Section */}
      <GradientBox minHeight="100vh" display="flex" alignItems="center">
        {[...Array(5)].map((_, i) => (
          <FloatingEffect
            key={i}
            sx={{
              width: Math.random() * 300 + 100,
              height: Math.random() * 300 + 100,
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              background: alpha(theme.palette.primary.main, 0.1),
            }}
          />
        ))}
        <Container sx={{ position: 'relative', zIndex: 1, py: 8 }}>
          <Grid container spacing={4} justifyContent="center" textAlign="center">
            <Grid item xs={12} md={8}>
              <Typography variant="h1" component="h1" gutterBottom>
                O Primeiro{' '}
                <Box component="span" color="primary.main">
                  Banco Digital
                </Box>{' '}
                do{' '}
                <Box component="span" color="primary.main">
                  Mercado Automotivo
                </Box>
              </Typography>
              <Typography variant="h5" color="text.secondary" paragraph>
                Conectamos todas as pontas do mercado automotivo em um único ecossistema financeiro e comercial.
              </Typography>
              <Box display="flex" gap={2} justifyContent="center" mt={4}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={<ChevronRight />}
                >
                  Comece Agora
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                >
                  Conhecer Soluções
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </GradientBox>

      {/* Features Section */}
      <Box id="solutions" py={8} bgcolor="background.paper">
        <Container>
          <Typography variant="h2" textAlign="center" mb={6}>
            Soluções{' '}
            <Box component="span" color="primary.main">
              Completas
            </Box>{' '}
            para o Mercado
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card>
                  <CardContent sx={{ p: 4 }}>
                    <Box color="primary.main" mb={3}>
                      {feature.icon}
                    </Box>
                    <Typography variant="h5" gutterBottom>
                      {feature.title}
                    </Typography>
                    <Typography color="text.secondary" paragraph>
                      {feature.description}
                    </Typography>
                    <List>
                      {feature.items.map((item, i) => (
                        <ListItem key={i} sx={{ px: 0 }}>
                          <ListItemIcon sx={{ minWidth: 36, color: 'primary.main' }}>
                            <ChevronRight size={20} />
                          </ListItemIcon>
                          <ListItemText primary={item} />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Marketplace Section */}
      <Box id="marketplace" py={8}>
        <Container>
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h2" gutterBottom>
                Marketplace{' '}
                <Box component="span" color="primary.main">
                  Automotivo
                </Box>
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <NewsIcon color={theme.palette.primary.main} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Classificados Verificados"
                    secondary="Anúncios de veículos com garantia de procedência e verificação completa"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CartIcon color={theme.palette.primary.main} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Peças e Serviços"
                    secondary="Encontre tudo que precisa com preços competitivos e entrega garantida"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <MoneyIcon color={theme.palette.primary.main} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Oportunidades de Negócio"
                    secondary="Conectamos fornecedores e compradores com as melhores condições do mercado"
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: 'relative',
                  aspectRatio: '1',
                  borderRadius: 4,
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    inset: 0,
                    background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.2)} 0%, ${alpha(theme.palette.primary.main, 0.1)} 100%)`,
                    animation: 'pulse 2s infinite ease-in-out',
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    inset: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CarIcon size={120} color={theme.palette.primary.main} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Made with Love Section */}
      <Box id="about" py={8} bgcolor="background.paper" textAlign="center">
        <Container maxWidth="md">
          <Box display="flex" justifyContent="center" mb={2}>
            <HeartIcon color={theme.palette.primary.main} size={32} />
          </Box>
          <Typography variant="h3" gutterBottom>
            Feito com Amor em São Paulo
          </Typography>
          <Typography variant="h6" color="text.secondary">
            Nascemos da paixão pelo mercado automotivo e da vontade de revolucionar 
            como as pessoas e empresas se relacionam com seus veículos.
          </Typography>
        </Container>
      </Box>

      {/* Footer */}
      <Box component="footer" bgcolor="background.paper" py={6}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Box display="flex" alignItems="center" gap={1} mb={2}>
                <CarIcon color={theme.palette.primary.main} />
                <Typography variant="h6" fontWeight="bold">
                  Torki
                </Typography>
              </Box>
              <Typography color="text.secondary" paragraph>
                O primeiro banco digital do mercado automotivo brasileiro.
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" gutterBottom>
                Soluções
              </Typography>
              <List dense disablePadding>
                <ListItem disablePadding>
                  <Button color="inherit" sx={{ px: 0 }}>Banco Digital</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" sx={{ px: 0 }}>Marketplace</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" sx={{ px: 0 }}>Classificados</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" sx={{ px: 0 }}>Financiamento</Button>
                </ListItem>
              </List>
            </Grid>
            
            <Grid item xs={12} md={3}>
              <Typography variant="h6" gutterBottom>
                Empresa
              </Typography>
              <List dense disablePadding>
              <ListItem disablePadding onClick={() => {
                  navigate("/nossosvalores"); // Navega para o link                    
                  }}> 
                    Integridade e Ética
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" sx={{ px: 0 }}>Sobre Nós</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" sx={{ px: 0 }}>Carreiras</Button>
                </ListItem>                
                <ListItem disablePadding>
                  <Button color="inherit" sx={{ px: 0 }}>Blog</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" sx={{ px: 0 }}>Imprensa</Button>
                </ListItem>
              </List>
            </Grid>
            
            <Grid item xs={12} md={3}>
              <Typography variant="h6" gutterBottom>
                Suporte
              </Typography>
              <List dense disablePadding>
                <ListItem disablePadding>
                  <Button color="inherit" sx={{ px: 0 }}>Ajuda</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" sx={{ px: 0 }}>Central de Suporte</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" sx={{ px: 0 }}>Contato</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" sx={{ px: 0 }}>Status</Button>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          
          <Box
            sx={{
              mt: 6,
              pt: 6,
              borderTop: 1,
              borderColor: 'divider',
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2
            }}
          >
            <Typography color="text.secondary" variant="body2">
              © {new Date().getFullYear()} Torki. Todos os direitos reservados.
            </Typography>
            
            <Box display="flex" gap={2}>
              <Button color="inherit" size="small">
                Privacidade
              </Button>
              <Button color="inherit" size="small">
                Termos
              </Button>
              <Button color="inherit" size="small">
                Cookies
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;