import React from 'react';
import Sidebar from './dashboard/Sidebar';
import Header from './dashboard/Header';
import SupplierDetailsContent from './SupplierDetailsContent';

import './Dashboard.css';

const App = () => {
  return (
    <div className="app">
      <Sidebar />      
      <div className="main-content">        
        <Header />
        <SupplierDetailsContent />        
        
      </div>      
    </div>
  );
};

export default App;
