  import React, { useState, useEffect } from 'react';
  import { useNavigate,useLocation } from 'react-router-dom';

  import {
    AppBar,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useScrollTrigger,
    useTheme,
    ThemeProvider,
    createTheme,
    CssBaseline,
    LinearProgress,
    Switch,
    TextField,
    Collapse,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    Tooltip,
    Zoom,
    Fade,
    Snackbar,
    MenuItem,
    Alert,
  } from '@mui/material';

  import {
    Shield as ShieldIcon,
    Description as DescriptionIcon,
    Warning as WarningIcon,
    Balance as BalanceIcon,
    ExpandMore as ExpandMoreIcon,
    Check as CheckIcon,
    Report as ReportIcon,
    ArrowForward as ArrowForwardIcon,
    Timeline as TimelineIcon,
    Security as SecurityIcon,
    Lock as LockIcon,
    BarChart as BarChartIcon,
    Assignment as AssignmentIcon,
    ChevronRight,
    Menu as MenuIcon,
    Close as CloseIcon,
    AccountBalanceWallet as WalletIcon,
    Search as SearchIcon,
    Business as BusinessIcon,
    DirectionsCar as CarIcon,
    ShoppingCart as CartIcon,
    People as PeopleIcon,
    Newspaper as NewsIcon,
    AttachMoney as MoneyIcon,
    Favorite as HeartIcon,
  } from '@mui/icons-material';
  import theme from '../Tema.js';


  // Componente de métricas animado
  const AnimatedMetric = ({ value, color, duration = 2000 }) => {
    const [count, setCount] = useState(0);  

    useEffect(() => {
      let start = 0;
      const end = parseInt(value);
      const incrementTime = (duration / end) * 1000;

      const timer = setInterval(() => {
        start += 1;
        setCount(start);
        if (start === end) clearInterval(timer);
      }, incrementTime);

      return () => clearInterval(timer);
    }, [value, duration]);

    return (
      <Typography
        variant="h4"
        sx={{
          position: 'absolute',
          right: 0,
          top: -30,
          color: color,
          transition: 'color 0.3s',
        }}
      >
        {count}%
      </Typography>
    );
  };

  const ComplianceMetrics = () => {
    const theme = useTheme();
    const metrics = [
      { 
        label: 'Conformidade Regulatória',
        value: 98,
        color: '#4CAF50',
        icon: <CheckIcon />,
        description: 'Conformidade com normas e regulamentos do setor'
      },
      { 
        label: 'Treinamentos Realizados',
        value: 95,
        color: '#2196F3',
        icon: <AssignmentIcon />,
        description: 'Colaboradores treinados em compliance'
      },
      { 
        label: 'Riscos Mitigados',
        value: 92,
        color: theme.palette.primary.main,
        icon: <BarChartIcon />,
        description: 'Riscos identificados e tratados'
      },
    ];

    return (
      
      <Grid container spacing={3} sx={{ mb: 8 }}>
        {metrics.map((metric, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card 
              sx={{ 
                height: '100%',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-8px)',
                }
              }}
            >
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                  {metric.icon}
                  <Typography variant="h6">
                    {metric.label}
                  </Typography>
                </Box>
                <Box sx={{ position: 'relative', pt: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    value={metric.value}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: metric.color,
                        transition: 'transform 2s ease-in-out',
                      }
                    }}
                  />
                  <AnimatedMetric value={metric.value} color={metric.color} />
                </Box>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ mt: 2, textAlign: 'center' }}
                >
                  {metric.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  // Componente de formulário de denúncia anônima
  const ReportingForm = ({ onClose }) => {
    const [isAnonymous, setIsAnonymous] = useState(true);
    const [formData, setFormData] = useState({
      type: '',
      description: '',
      date: '',
      location: '',
      evidence: '',
      contact: '',
    });
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
      e.preventDefault();
      // Simular envio
      setTimeout(() => {
        setSubmitted(true);
        setTimeout(onClose, 2000);
      }, 1000);
    };

    if (submitted) {
      return (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <CheckIcon sx={{ fontSize: 60, color: 'success.main', mb: 2 }} />
          <Typography variant="h6" gutterBottom>
            Denúncia Registrada com Sucesso
          </Typography>
          <Typography color="text.secondary">
            Seu protocolo foi gerado e será investigado com sigilo.
          </Typography>
        </Box>
      );
    }

    return (
      <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Typography>Modo Anônimo</Typography>
          <Switch
            checked={isAnonymous}
            onChange={(e) => setIsAnonymous(e.target.checked)}
            color="primary"
          />
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              required
              label="Tipo de Denúncia"
              value={formData.type}
              onChange={(e) => setFormData({...formData, type: e.target.value})}
            >
              <MenuItem value="corruption">Corrupção</MenuItem>
              <MenuItem value="fraud">Fraude</MenuItem>
              <MenuItem value="harassment">Assédio</MenuItem>
              <MenuItem value="ethics">Violação Ética</MenuItem>
              <MenuItem value="other">Outros</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              multiline
              rows={4}
              label="Descrição Detalhada"
              value={formData.description}
              onChange={(e) => setFormData({...formData, description: e.target.value})}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="date"
              label="Data do Ocorrido"
              InputLabelProps={{ shrink: true }}
              value={formData.date}
              onChange={(e) => setFormData({...formData, date: e.target.value})}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Local/Departamento"
              value={formData.location}
              onChange={(e) => setFormData({...formData, location: e.target.value})}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={2}
              label="Evidências (URLs ou descrição)"
              value={formData.evidence}
              onChange={(e) => setFormData({...formData, evidence: e.target.value})}
            />
          </Grid>

          {!isAnonymous && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Contato para Retorno"
                helperText="Seu contato será mantido em sigilo"
                value={formData.contact}
                onChange={(e) => setFormData({...formData, contact: e.target.value})}
              />
            </Grid>
          )}
        </Grid>

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            endIcon={<LockIcon />}
          >
            Enviar com Segurança
          </Button>
        </Box>
      </Box>
    );
  };

  const ComplianceSection = ({ title, icon, content, details }) => {
    const [expanded, setExpanded] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    return (
      <Grid item xs={12} md={6}>
        <Card 
          sx={{ 
            height: '100%',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              transform: 'translateY(-4px)',
              boxShadow: (theme) => `0 4px 20px ${theme.palette.primary.main}40`,
            }
          }}
        >
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {React.cloneElement(icon, { 
                  sx: { 
                    fontSize: 32,
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': { transform: 'scale(1.1)' }
                  }
                })}
                <Typography variant="h5" color="primary">
                  {title}
                </Typography>
              </Box>
              <IconButton
                onClick={() => setExpanded(!expanded)}
                sx={{
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0)',
                  transition: 'transform 0.3s',
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Box>
            <Typography variant="body1" color="text.secondary">
              {content}
            </Typography>
            <Collapse in={expanded}>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  {details}
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => setDialogOpen(true)}
                  sx={{ 
                    mt: 1,
                    '&:hover': {
                      transform: 'translateX(4px)',
                    },
                    transition: 'transform 0.2s'
                  }}
                >
                  Saiba mais
                </Button>
              </Box>
            </Collapse>
          </CardContent>
        </Card>

        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          maxWidth="md"
          TransitionComponent={Zoom}
        >
          <DialogTitle sx={{ color: 'primary.main' }}>{title}</DialogTitle>
          <DialogContent>
            <Typography variant="body1" paragraph>
              {content}
            </Typography>
            <Typography variant="body1">
              {details}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  };

  const Footer = () => {
    const navigate = useNavigate(); // Hook para navegação
  
    return (
      <Box component="footer" bgcolor="background.paper" py={6}>
        <Container>
          <Grid container spacing={4}>
            {/* Logo e Descrição */}
            <Grid item xs={12} md={3}>
              <Box display="flex" alignItems="center" gap={1} mb={2}>
                <CarIcon color="primary" />
                <Typography variant="h6" fontWeight="bold">
                  Torki
                </Typography>
              </Box>
              <Typography color="text.secondary" paragraph>
                O primeiro banco digital do mercado automotivo brasileiro.
              </Typography>
            </Grid>
  
            {/* Soluções */}
            <Grid item xs={12} md={3}>
              <Typography variant="h6" gutterBottom>
                Soluções
              </Typography>
              <List dense disablePadding>
                <ListItem disablePadding>
                  <Button color="inherit" onClick={() => navigate('/banco-digital')}>Banco Digital</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" onClick={() => navigate('/marketplace')}>Marketplace</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" onClick={() => navigate('/classificados')}>Classificados</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" onClick={() => navigate('/financiamento')}>Financiamento</Button>
                </ListItem>
              </List>
            </Grid>
  
            {/* Empresa */}
            <Grid item xs={12} md={3}>
              <Typography variant="h6" gutterBottom>
                Empresa
              </Typography>
              <List dense disablePadding>
                <ListItem disablePadding>
                  <Button color="inherit" onClick={() => navigate('/integridade')}>Integridade e Ética</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" onClick={() => navigate('/sobre-nos')}>Sobre Nós</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" onClick={() => navigate('/carreiras')}>Carreiras</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" onClick={() => navigate('/blog')}>Blog</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" onClick={() => navigate('/imprensa')}>Imprensa</Button>
                </ListItem>
              </List>
            </Grid>
  
            {/* Suporte */}
            <Grid item xs={12} md={3}>
              <Typography variant="h6" gutterBottom>
                Suporte
              </Typography>
              <List dense disablePadding>
                <ListItem disablePadding>
                  <Button color="inherit" onClick={() => navigate('/ajuda')}>Ajuda</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" onClick={() => navigate('/suporte')}>Central de Suporte</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" onClick={() => navigate('/contato')}>Contato</Button>
                </ListItem>
                <ListItem disablePadding>
                  <Button color="inherit" onClick={() => navigate('/status')}>Status</Button>
                </ListItem>
              </List>
            </Grid>
          </Grid>
  
          {/* Footer Bottom */}
          <Box
            sx={{
              mt: 6,
              pt: 6,
              borderTop: 1,
              borderColor: 'divider',
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2
            }}
          >
            <Typography color="text.secondary" variant="body2">
              © {new Date().getFullYear()} Torki. Todos os direitos reservados.
            </Typography>
            
            <Box display="flex" gap={2}>
              <Button color="inherit" size="small" onClick={() => navigate('/privacidade')}>
                Privacidade
              </Button>
              <Button color="inherit" size="small" onClick={() => navigate('/termos')}>
                Termos
              </Button>
              <Button color="inherit" size="small" onClick={() => navigate('/cookies')}>
                Cookies
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    );
  };
  
  

  const CompliancePage = () => {
    const [reportingOpen, setReportingOpen] = useState(false);
    const [notification, setNotification] = useState(null);

    const sections = [
      {
        icon: <ShieldIcon color="primary" />,
        title: "Nossos Valores e Compromissos",
        content: "Na Torki, a integridade é o alicerce de nossas operações. Como fintech líder no mercado automotivo, mantemos os mais altos padrões éticos.",
        details: "Nossa política de compliance é baseada em três pilares fundamentais: transparência, responsabilidade e inovação. Implementamos controles rigorosos e utilizamos tecnologia de ponta para garantir a conformidade em todas as operações."
      },
      {
        icon: <DescriptionIcon color="primary" />,
        title: "Programa de Compliance",
        content: "Nosso programa de compliance é estruturado para prevenir, detectar e responder a possíveis violações de forma eficiente e sistemática.",
        details: "O programa inclui treinamentos regulares, monitoramento contínuo, avaliações de risco periódicas e canais de comunicação efetivos para reportar preocupações."
      },
      {
        icon: <WarningIcon color="primary" />,
        title: "Prevenção à Lavagem de Dinheiro",
        content: "Mantemos um sistema robusto de prevenção à lavagem de dinheiro, com monitoramento em tempo real e análise avançada de transações.",
        details: "Nossa equipe especializada utiliza algoritmos avançados e machine learning para detectar padrões suspeitos e garantir a segurança das operações."
      },
      {
        icon: <BalanceIcon color="primary" />,
        title: "Lei Anticorrupção",
        content: "Seguimos rigorosamente a Lei 12.846/2013 e mantemos tolerância zero a práticas corruptas em qualquer nível da organização.",
        details: "Realizamos due diligence aprofundada em parceiros comerciais e mantemos registros detalhados de todas as interações com agentes públicos."
      }
    ];

    return (    
        <Container maxWidth="lg" sx={{ py: 8 }}>
          <Fade in timeout={1000}>
            <Box sx={{ textAlign: 'center', mb: 8 }}>
              <Typography variant="h2" gutterBottom>
                Compliance e Ética
              </Typography>
              <Typography variant="h5" color="text.secondary" sx={{ mb: 6 }}>
                Comprometidos com a excelência e integridade em todas as operações
              </Typography>
            </Box>
          </Fade>

          {/* <ComplianceMetrics /> */}

          <Grid container spacing={4}>
            {sections.map((section, index) => (
              <ComplianceSection key={index} {...section} />
            ))}
          </Grid>

          <Box sx={{ mt: 8, textAlign: 'center' }}>
            <Card 
              sx={{ 
                p: 4,
                background: 'linear-gradient(135deg, rgba(17, 34, 64, 0.95) 0%, rgba(17, 34, 64, 0.8) 100%)',
                backdropFilter: 'blur(10px)',
              }}
            >
              {/* ... (todo o código anterior permanece igual até a linha do CardContent) ... */}

              <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                  <SecurityIcon color="primary" sx={{ fontSize: 48, mb: 2 }} />
                  <Typography variant="h5" color="primary" gutterBottom>
                    Canal de Denúncias Seguro
                  </Typography>
                  <Typography variant="body1" color="text.secondary" paragraph>
                    Identificou alguma violação ao nosso código de ética ou práticas irregulares?
                    Faça sua denúncia de forma segura e, se preferir, anônima.
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                    <Typography variant="h6" color="primary" sx={{ mb: 1 }}>
                      compliance@torki.com.br | 0800-XXX-XXXX
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Tooltip title="Suas informações são protegidas" arrow>
                        <Button
                          variant="contained"
                          color="primary"
                          endIcon={<LockIcon />}
                          size="large"
                          onClick={() => setReportingOpen(true)}
                          sx={{
                            py: 1.5,
                            px: 4,
                            transition: 'transform 0.2s',
                            '&:hover': {
                              transform: 'scale(1.05)',
                            }
                          }}
                        >
                          Fazer Denúncia
                        </Button>
                      </Tooltip>
                    </Box>
                    <Typography variant="caption" color="text.secondary" sx={{ mt: 2 }}>
                      Garantimos total sigilo e confidencialidade em todas as etapas do processo
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>

          {/* Modal de Denúncia */}
          <Dialog
            open={reportingOpen}
            onClose={() => setReportingOpen(false)}
            maxWidth="md"
            fullWidth
            TransitionComponent={Zoom}
          >
            <DialogTitle>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <LockIcon color="primary" />
                <Typography variant="h6">Canal de Denúncias Seguro</Typography>
              </Box>
            </DialogTitle>
            <DialogContent>
              <ReportingForm onClose={() => setReportingOpen(false)} />
            </DialogContent>
          </Dialog>

          {/* Notificações */}
          <Snackbar
            open={!!notification}
            autoHideDuration={6000}
            onClose={() => setNotification(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              onClose={() => setNotification(null)}
              severity={notification?.type || 'success'}
              sx={{ width: '100%' }}
            >
              {notification?.message}
            </Alert>
          </Snackbar>                  
        </Container>              
    );    

    <Footer/>
  };

  export default CompliancePage;
