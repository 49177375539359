import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Chip,
  IconButton,
  Rating,
  LinearProgress,
} from '@mui/material';
import {
  TrendingUp,
  TrendingDown,
  QuestionAnswer,
  LocalShipping,
  Inventory,
  Star,
  Reply,
} from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import Layout from './Layout';



// Mock data for marketplace dashboard
const mockData = {
  salesMetrics: [
    { month: 'Jan', sales: 32000, orders: 145 },
    { month: 'Feb', sales: 28000, orders: 132 },
    { month: 'Mar', sales: 42000, orders: 187 },
    { month: 'Apr', sales: 35000, orders: 156 },
    { month: 'May', sales: 39000, orders: 168 },
    { month: 'Jun', sales: 45000, orders: 198 },
  ],
  productPerformance: [
    { name: 'Óleo Motor', value: 35 },
    { name: 'Filtros', value: 25 },
    { name: 'Peças', value: 20 },
    { name: 'Acessórios', value: 20 },
  ],
  questions: [
    {
      id: 1,
      product: 'Óleo Motor 5W30 Sintético',
      customer: 'Pedro Alves',
      question: 'Este óleo é compatível com motores flex?',
      time: '2h atrás',
      status: 'pending'
    },
    {
      id: 2,
      product: 'Kit Filtro de Ar K&N',
      customer: '  Silva',
      question: 'Qual a durabilidade média deste filtro?',
      time: '5h atrás',
      status: 'pending'
    },
    {
      id: 3,
      product: 'Pastilha de Freio',
      customer: 'Lucas Santos',
      question: 'Tem disponível para Civic 2020?',
      time: '1d atrás',
      status: 'answered'
    },
  ],
  orders: [
    {
      id: '#12345',
      customer: 'Carlos Mendes',
      items: 3,
      total: 450.90,
      status: 'pending',
      date: '2024-11-02'
    },
    {
      id: '#12344',
      customer: 'Marina Costa',
      items: 1,
      total: 189.90,
      status: 'shipped',
      date: '2024-11-02'
    },
    {
      id: '#12343',
      customer: 'Rafael Dias',
      items: 2,
      total: 324.50,
      status: 'delivered',
      date: '2024-11-01'
    },
  ],
  topProducts: [
    {
      name: 'Óleo Motor 5W30 Sintético',
      sales: 45,
      stock: 89,
      rating: 4.8,
      questions: 5
    },
    {
      name: 'Filtro de Ar Esportivo',
      sales: 32,
      stock: 54,
      rating: 4.5,
      questions: 3
    },
    {
      name: 'Kit Pastilha de Freio',
      sales: 28,
      stock: 35,
      rating: 4.7,
      questions: 2
    },
  ]
};

const COLORS = ['#F97316', '#3B82F6', '#10B981', '#8B5CF6'];

const MarketplaceContent = () => {
  const [selectedTimeframe] = useState('month');

  const GlassCard = ({ children, ...props }) => (
    <Card
      sx={{
        background: alpha('#112240', 0.85),
        backdropFilter: 'blur(10px)',
        borderRadius: 2,
        border: '1px solid rgba(255, 255, 255, 0.05)',
        color: '#E0E0E0', // Light color for text
        ...props.sx
      }}
      {...props}
    >
      {children}
    </Card>
  );

  const getStatusColor = (status) => {
    const colors = {
      pending: '#FCD34D',
      shipped: '#60A5FA',
      delivered: '#34D399',
      answered: '#34D399',
    };
    return colors[status] || '#F97316';
  };

  return (
    <Layout>    
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        minHeight: '100vh',
        background: `linear-gradient(135deg, ${alpha('#0A192F', 0.95)} 0%, ${alpha('#112240', 0.95)} 100%)`,
        color: '#FFFFFF',

      }}
    >      
      <Container maxWidth="xl">
        {/* Metrics Summary */}
        <Grid container spacing={3} sx={{ mb: 4, color: '#B3B3B3' }}>
          {[
            {
              title: 'Vendas no Mês',
              value: 'R$ 45.890,00',
              trend: '+15%',
              icon: <TrendingUp color="success" />,
            },
            {
              title: 'Pedidos Pendentes',
              value: '23',
              trend: '4 novos hoje',
              icon: <LocalShipping color="warning" />,
            },
            {
              title: 'Perguntas',
              value: '12',
              trend: '5 não respondidas',
              icon: <QuestionAnswer color="error" />,
            },
            {
              title: 'Taxa de Conversão',
              value: '4.2%',
              trend: '+0.8%',
              icon: <TrendingUp color="success" />,
            },
          ].map((item) => (
            <Grid item xs={12} sm={6} md={3} key={item.title}>
              <GlassCard>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography color="#B3B3B3">
                      {item.title}
                    </Typography>
                    {item.icon}
                  </Box>
                  <Typography variant="h4" component="div" sx={{ mb: 1 }}>
                    {item.value}
                  </Typography>
                  <Typography color="primary" variant="body2">
                    {item.trend}
                  </Typography>
                </CardContent>
              </GlassCard>
            </Grid>
          ))}
        </Grid>

        {/* Charts */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} md={8}>
            <GlassCard sx={{ height: 400 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Desempenho de Vendas
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={mockData.salesMetrics}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: alpha('#112240', 0.9),
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                      }}
                    />
                    <Legend />
                    <Line yAxisId="left" type="monotone" dataKey="sales" name="Vendas (R$)" stroke="#F97316" />
                    <Line yAxisId="right" type="monotone" dataKey="orders" name="Pedidos" stroke="#3B82F6" />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </GlassCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <GlassCard sx={{ height: 400 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Distribuição de Vendas por Categoria
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      data={mockData.productPerformance}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {mockData.productPerformance.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip
                      contentStyle={{
                        backgroundColor: alpha('#112240', 0.9),
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                      }}
                    />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </CardContent>
            </GlassCard>
          </Grid>
        </Grid>

        {/* Questions and Orders */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <GlassCard>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <QuestionAnswer fontSize="small" />
                  Perguntas Recentes
                </Typography>
                <List>
                  {mockData.questions.map((question) => (
                    <ListItem
                      key={question.id}
                      sx={{
                        mb: 2,
                        borderRadius: 2,
                        bgcolor: alpha('#1E293B', 0.3),
                      }}
                      secondaryAction={
                        <IconButton edge="end" color="#B3B3B3">
                          <Reply />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {question.product}
                            <Chip
                              size="small"
                              label={question.status}
                              sx={{
                                bgcolor: getStatusColor(question.status),
                                color: 'white',
                              }}
                            />
                          </Box>
                        }
                        secondary={
                          <>
                            <Typography component="span" variant="body2" sx={{ display: 'block', color: 'warning.main' }}>
                              {question.customer}: "{question.question}"
                            </Typography>
                            <Typography variant="caption" sx={{ display: 'block', color: '#B3B3B3' }}>
                              {question.time}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </GlassCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <GlassCard>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <LocalShipping fontSize="small" />
                  Pedidos Recentes
                </Typography>
                <List>
                  {mockData.orders.map((order) => (
                    <ListItem
                      key={order.id}
                      sx={{
                        mb: 2,
                        borderRadius: 2,
                        bgcolor: alpha('#1E293B', 0.3),
                      }}
                    >
                      <ListItemText
                        primary={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {order.id}
                            <Chip
                              size="small"
                              label={order.status}
                              sx={{
                                bgcolor: getStatusColor(order.status),
                                color: 'white',
                              }}
                            />
                          </Box>
                        }
                        secondary={
                          <>
                            <Typography component="span" variant="body2" sx={{ display: 'block', color: '#B3B3B3' }}>
                              {order.customer} - {order.items} {order.items > 1 ? 'itens' : 'item'}
                            </Typography>
                            <Typography variant="caption" sx={{ display: 'block', color: '#B3B3B3' }}>
                              R$ {order.total.toFixed(2)} - {order.date}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </GlassCard>
          </Grid>
        </Grid>

        {/* Top Products */}
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <GlassCard>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Inventory fontSize="small" />
                  Produtos em Destaque
                </Typography>
                <List>
                  {mockData.topProducts.map((product) => (
                    <ListItem
                      key={product.name}
                      sx={{
                        mb: 2,
                        borderRadius: 2,
                        bgcolor: alpha('#1E293B', 0.3),
                      }}
                    >
                      <ListItemIcon>
                        <Star sx={{ color: '#F97316' }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography variant="subtitle1">{product.name}</Typography>
                            <Rating value={product.rating} readOnly size="small" />
                          </Box>
                        }
                        secondary={
                          <Box sx={{ mt: 1 }}>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xs={12} sm={6}>
                                <Typography variant="body2" color="#B3B3B3">
                                  Vendas: {product.sales} | Estoque: {product.stock}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography variant="body2" color="#B3B3B3">
                                  Perguntas não respondidas: {product.questions}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="caption" color="primary.main">
                                  Taxa de conversão
                                </Typography>
                                <LinearProgress
                                  variant="determinate"
                                  value={(product.sales / product.stock) * 100}
                                  sx={{
                                    bgcolor: alpha('#F97316', 0.2),
                                    '& .MuiLinearProgress-bar': {
                                      bgcolor: '#F97316',
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </GlassCard>
          </Grid>
        </Grid>
      </Container>
    </Box>
    </Layout>
  );
};

export default MarketplaceContent;