import React, { useState, useEffect } from 'react';
import SearchBar from './components/SearchBar';
import Cart from './Cart';
import { FaCartPlus } from 'react-icons/fa';  
import './CatalogPage.css';
import Footer from './components/Footer';
import Header from './components/Header';

const CatalogPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [cart, setCart] = useState([]);

  // Função para buscar produtos no servidor
  const fetchProducts = async (query) => {
    try {
      const response = await fetch(`https://teste.stratusport.co/festafacil/products.php?search=${query}`, {
        method: 'GET',
      });

      const products = await response.json();

      if (response.ok) {
        setFilteredProducts(products);
      } else {
        alert('Erro ao carregar os produtos.');
      }
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
    }
  };

  // Efeito que dispara a pesquisa quando o termo de busca muda
  useEffect(() => {
    if (searchTerm.length >= 3) {
      fetchProducts(searchTerm); // Só faz a busca quando há pelo menos 3 caracteres
    } else {
      setFilteredProducts([]); // Limpa os resultados se o termo for muito curto
    }
  }, [searchTerm]);

  // Função de mudança do campo de busca
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Função para adicionar produto ao carrinho
  const handleAddToCart = (product) => {
    setCart([...cart, product]);
    alert(`${product.name} adicionado ao orçamento!`);
  };

  // Função para remover produto do carrinho
  const handleRemoveFromCart = (productId) => {
    setCart(cart.filter((item) => item.id !== productId));
  };

  return (
    <div className="catalog-page">
      <Header />
      <main className="catalog-content">
        <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />

        {/* Grid de Produtos */}
        <div className="products-grid">
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <div key={product.id} className="product-card">
                <h3>{product.name}</h3>
                <p>{product.description}</p>
                <p className="price">R$ {product.price}</p>
                <p className="location">{product.location}</p>
                <button className="btn-add" onClick={() => handleAddToCart(product)}>
                  <FaCartPlus /> Adicionar ao Orçamento
                </button>
              </div>
            ))
          ) : (
            <p className="no-results">Nenhum produto encontrado.</p>
          )}
        </div>
      </main>

      {/* Componente Orçamento */}
      <Cart cartItems={cart} removeFromCart={handleRemoveFromCart} />
      <Footer />
    </div>
  );
};

export default CatalogPage;
