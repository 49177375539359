import React from 'react';
import { Box, Chip, Stack, Typography, useMediaQuery } from '@mui/material';
import { VerifiedUser, Shield, Stars, Timer } from '@mui/icons-material';
import { keyframes } from '@emotion/react';
import { useTheme } from '@mui/material/styles';

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 215, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
  }
`;

const shimmerAnimation = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const CertifiedBadge = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'inline-flex',
        position: 'relative',
        alignItems: 'center',
        background: 'linear-gradient(45deg, #FFD700, #FFF8DC, #FFD700)',
        backgroundSize: '200% 100%',
        animation: `${shimmerAnimation} 3s infinite`,
        borderRadius: '16px',
        p: isMobile ? 0.3 : 0.5,
        border: '1px solid #FFD700',
        boxShadow: '0 2px 8px rgba(255, 215, 0, 0.3)',
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 4px 12px rgba(255, 215, 0, 0.4)',
        },
      }}
    >
      <Stack 
        direction={isMobile ? 'column' : 'row'}
        spacing={1} 
        alignItems="center" 
        sx={{ 
          px: isMobile ? 1 : 2,
          py: isMobile ? 0.5 : 1,
          borderRadius: '14px',
          bgcolor: 'rgba(255, 255, 255, 0.9)',
          textAlign: isMobile ? 'center' : 'inherit',
        }}
      >
        <Shield 
          sx={{ 
            color: '#FFD700',
            animation: `${pulseAnimation} 2s infinite`,
            fontSize: isMobile ? 24 : 28,
          }} 
        />
        
        <Stack spacing={isMobile ? 0 : 0.5} alignItems={isMobile ? 'center' : 'flex-start'}>
          <Stack 
            direction="row" 
            spacing={0.5} 
            alignItems="center"
            justifyContent={isMobile ? 'center' : 'flex-start'}
          >
            <Typography 
              variant="subtitle1" 
              sx={{ 
                fontWeight: 'bold',
                color: '#B8860B',
                fontSize: isMobile ? '0.875rem' : '1rem',
                textShadow: '0 1px 2px rgba(0,0,0,0.1)',
              }}
            >
              Veículo Certificado
            </Typography>
            <VerifiedUser 
              sx={{ 
                color: '#FFD700',
                fontSize: isMobile ? 14 : 16,
                animation: `${pulseAnimation} 2s infinite`,
                animationDelay: '1s',
              }} 
            />
          </Stack>
          
          <Stack direction="row" spacing={0.5} alignItems="center" justifyContent={isMobile ? 'center' : 'flex-start'}>
            <Timer sx={{ fontSize: isMobile ? 12 : 14, color: '#DAA520' }} />
            <Typography 
              variant="caption" 
              sx={{ 
                color: '#DAA520',
                fontWeight: 500,
                fontSize: isMobile ? '0.75rem' : '0.875rem',
              }}
            >
              Revisado há menos de 30 dias
            </Typography>
          </Stack>
        </Stack>

        <Chip
          icon={<Stars sx={{ color: '#FFD700 !important' }} />}
          label="Premium"
          size="small"
          sx={{
            bgcolor: 'rgba(255, 215, 0, 0.1)',
            border: '1px solid #FFD700',
            color: '#B8860B',
            fontWeight: 'bold',
            fontSize: isMobile ? '0.75rem' : '0.875rem',
            '& .MuiChip-icon': {
              color: '#FFD700',
              fontSize: isMobile ? 16 : 20,
            },
            mt: isMobile ? 1 : 0,
          }}
        />
      </Stack>
    </Box>
  );
};

export default CertifiedBadge;
