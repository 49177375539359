import React, { useState, useEffect, useRef } from 'react';
import './AlinyAndCart.css';
import AlinyChatbot from './components/AlinyChatbot'; // Importar o componente da Aliny

const AlinyAndCart = () => {
  const [eventDetails, setEventDetails] = useState({
    eventName: '',
    eventLocation: '',
    eventState: '',
    guestCount: 0,
    eventDate: '',
    eventType: '',
    eventDuration: 0,
    budgetItems: [],
  });

  const cartEndRef = useRef(null); // Referência para o final da lista de orçamento

  // Função para adicionar detalhes ao evento
  const addEventDetail = (newDetail) => {
    setEventDetails((prevDetails) => ({
      ...prevDetails,
      ...newDetail,
    }));
  };

  // Função para adicionar itens ao orçamento
  const addToBudgetItems = (item) => {
    setEventDetails((prevDetails) => ({
      ...prevDetails,
      budgetItems: [...prevDetails.budgetItems, item],
    }));
  };

  // Função para remover itens do orçamento
  const removeFromBudgetItems = (indexToRemove) => {
    setEventDetails((prevDetails) => ({
      ...prevDetails,
      budgetItems: prevDetails.budgetItems.filter((_, index) => index !== indexToRemove),
    }));
  };

  // Função para calcular o valor total do orçamento
  const calculateTotal = () => {
    //return eventDetails.budgetItems.reduce((total, item) => total + item.price, 0);
  };

  // Função para rolar automaticamente até o final da lista de orçamento
  const scrollToBottom = () => {
    if (cartEndRef.current) {
      cartEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Efeito que roda o scroll automaticamente quando novos itens são adicionados ao orçamento
  useEffect(() => {
    if (eventDetails.budgetItems.length > 0) {
      scrollToBottom();
    }
  }, [eventDetails.budgetItems]);

  return (
    <div className="aliny-and-cart-container">
      {/* Seção do Orçamento (Barra lateral) */}
      <div className="budget-section">
        <h3>Orçamento Atual</h3>

        <div className="event-summary">
          <div className="event-info">
            <p><strong>Nome do Evento:</strong> {eventDetails.eventName || 'Não informado'}</p>
            <p><strong>Localização:</strong> {eventDetails.eventLocation ? `${eventDetails.eventLocation}, ${eventDetails.eventState}` : 'Não informado'}</p>
            <p><strong>Número de Convidados:</strong> {eventDetails.guestCount || 'Não informado'}</p>
            <p><strong>Data do Evento:</strong> {eventDetails.eventDate || 'Não informado'}</p>
            <p><strong>Tipo de Evento:</strong> {eventDetails.eventType || 'Não informado'}</p>
            <p><strong>Duração do Evento:</strong> {eventDetails.eventDuration || 'Não informado'}</p>
          </div>

          {eventDetails.budgetItems.length > 0 ? (
            <div className="budget-summary">
              <h3>Itens do Orçamento</h3>
              <ul className="budget-items-list">
                {eventDetails.budgetItems.map((item, index) => (
                  <li key={index} className="budget-item">
                    <div className="item-details">
                      <span><strong>{item.name}</strong>: {item.description}</span>
                      <span className="item-price">R${item.price}</span>
                    </div>
                    
                    <button 
                      className="remove-btn" 
                      onClick={() => removeFromBudgetItems(index)}
                    >
                      Remover
                    </button>
                  </li>                  
                ))}            
                <div ref={cartEndRef} /> {/* Referência para o final da lista */}
                <div className="powered-by">
              <small>powered by FestaFacil</small>
            </div>
              </ul>
              <div className="total-amount">
                {/* <strong>Total:</strong> R${calculateTotal()} */}
              </div>

              <div className="action-buttons">
                <button className="clear-btn" onClick={() => setEventDetails({...eventDetails, budgetItems: []})}>Limpar Orçamento</button>
                <button className="finalize-btn">Finalizar Orçamento</button>
              </div>
            </div>
          ) : (
            <p>Seu orçamento ainda está vazio.</p>
          )}
        </div>
      </div>
      {/* Seção da Aliny (Chatbot) */}
      <div className="aliny-section">
        <AlinyChatbot addEventDetail={addEventDetail} addToBudgetItems={addToBudgetItems} />
      </div>
    </div>    
  );
};

export default AlinyAndCart;
