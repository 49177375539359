import React from 'react';
import { Box, Container, Grid, Typography, Stack, IconButton, TextField, Button } from '@mui/material';
import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material';

const Footer = () => (
  <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        {/* Sobre a Torki */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Sobre a Torki
          </Typography>
          <Typography color="text.secondary" paragraph>
            A Torki é a maior plataforma de compra e venda de veículos do Brasil.
            Conectamos compradores e vendedores de forma segura e eficiente.
          </Typography>
          <Stack direction="row" spacing={2}>
            <IconButton color="primary">
              <Facebook />
            </IconButton>
            <IconButton color="primary">
              <Instagram />
            </IconButton>
            <IconButton color="primary">
              <Twitter />
            </IconButton>
            <IconButton color="primary">
              <YouTube />
            </IconButton>
          </Stack>
        </Grid>

        {/* Links Rápidos */}
        <Grid item xs={12} md={2}>
          <Typography variant="h6" gutterBottom>
            Links Rápidos
          </Typography>
          <Stack spacing={1}>
            {['Carros', 'Motos', 'Caminhões', 'Financiamento', 'Seguro'].map((item) => (
              <Typography
                key={item}
                color="text.secondary"
                sx={{ '&:hover': { color: 'primary.main', cursor: 'pointer' } }}
              >
                {item}
              </Typography>
            ))}
          </Stack>
        </Grid>

        {/* Suporte */}
        <Grid item xs={12} md={2}>
          <Typography variant="h6" gutterBottom>
            Suporte
          </Typography>
          <Stack spacing={1}>
            {['FAQ', 'Contato', 'Como funciona', 'Termos de uso', 'Privacidade'].map((item) => (
              <Typography
                key={item}
                color="text.secondary"
                sx={{ '&:hover': { color: 'primary.main', cursor: 'pointer' } }}
              >
                {item}
              </Typography>
            ))}
          </Stack>
        </Grid>

        {/* Newsletter */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Newsletter
          </Typography>
          <Typography color="text.secondary" paragraph>
            Receba as melhores ofertas e novidades no seu email
          </Typography>
          <Stack direction="row" spacing={1}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Seu email"
              fullWidth
            />
            <Button variant="contained">
              Assinar
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <Box sx={{ mt: 8, pt: 4, borderTop: '1px solid', borderColor: 'divider' }}>
        <Typography align="center" color="text.secondary">
          © {new Date().getFullYear()} Torki. Todos os direitos reservados. <p>CNPJ: 36.097.118/0001-27</p>
        </Typography>
      </Box>
    </Container>
  </Box>
);

export default Footer;
