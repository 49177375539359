import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Grid, 
  Card, 
  CardContent, 
  Typography, 
  CircularProgress, 
  Snackbar, 
  IconButton, 
  Chip, 
  Divider, 
  Button, 
  Link, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  TextField 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BusinessIcon from '@mui/icons-material/Business';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useNavigate, useLocation } from 'react-router-dom';
import { alpha } from '@mui/material/styles';

const ServicesContent = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [reScheduleData, setReScheduleData] = useState({ id: null, date: '', time: '' });
  const [token, setToken] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlToken = new URLSearchParams(location.search).get('token'); 
    setToken(urlToken);
    
    if (!urlToken) {
      setError('Token não encontrado.');
      setLoading(false);
      setSnackbarOpen(true);
      return;
    }

    try {
      const decodedToken = customJwtDecode(urlToken);
      const userId = decodedToken.user.id; 
      setUserId(userId);
      
      if (userId) {
        const bffUrl = `${process.env.REACT_APP_API_BASE_URL}/prestadores/monta_dashboard_prestador.php?action=get_maintenance_schedule&prestador_id=${userId}`;
        fetchSchedules(bffUrl);
      }
    } catch (e) {
      console.error('Erro ao decodificar token:', e);
      setError('Erro ao decodificar o token.');
      setLoading(false);
      setSnackbarOpen(true);
    }
  }, [location.search]);

  const customJwtDecode = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(c =>
        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      ).join(''));
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Erro na decodificação do token:', error);
      throw new Error('Invalid token');
    }
  };

  const fetchSchedules = async (url) => {
    try {
      const response = await fetch(url);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();

      if (Array.isArray(data) && data.length > 0) {
        setSchedules(data);
        setError(null);
      } else {
        setSchedules([]);
        setError('Nenhum agendamento encontrado.');
        setSnackbarOpen(true);
      }
    } catch (e) {
      console.error('Erro na chamada da API:', e);
      setError(`Erro ao buscar agendamentos: ${e.message}`);
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const updateScheduleStatus = async (scheduleId, status, newDateTime = null) => {
    try {
      const payload = {
        scheduleId: scheduleId,
        newStatus: status,
        ...(newDateTime && { newDate: newDateTime })
      };

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/prestadores/monta_dashboard_prestador.php?action=updateMaintenanceStatus`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Erro na requisição: ' + response.status);
      }

      const data = await response.json();

      if (data.success) {
        setSchedules(schedules.map(schedule =>
          schedule.schedule_id === scheduleId
            ? { ...schedule, status, scheduled_date: newDateTime || schedule.scheduled_date }
            : schedule
        ));
        setSnackbarOpen(true);
        setError(null);
      } else {
        throw new Error(data.message || 'Erro ao atualizar o status');
      }
    } catch (error) {
      setError('Erro ao atualizar o status: ' + error.message);
      setSnackbarOpen(true);
    }
  };

  const handleReSchedule = () => {
    const { id, date, time } = reScheduleData;
    if (!id || !date || !time) {
      setError('Por favor, selecione uma data e horário válidos para reagendamento.');
      setSnackbarOpen(true);
      return;
    }

    const newDateTime = `${date} ${time}`;
    updateScheduleStatus(id, 'agendado', newDateTime);
    setOpenDialog(false); // Fecha o modal após reagendamento
  };

  const redirectToServiceDetails = (scheduleId) => {
    navigate(`/service-details/${scheduleId}?token=${token}`);
  };

  // Função para abrir o modal
  const handleOpenDialog = (scheduleId) => {
    setReScheduleData({ id: scheduleId, date: '', time: '' });
    setOpenDialog(true);
  };

  return (
    <Box
          sx={{
            minHeight: '100vh',
            background: `linear-gradient(135deg, ${alpha('#0A192F', 0.95)} 0%, ${alpha('#112240', 0.95)} 100%)`,
            py: 4,
            px: 2
          }}
        >
      <Typography variant="h4" gutterBottom sx={{ color: '#1976d2' }}>
        Agenda de Manutenção
      </Typography>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={error || 'Operação realizada com sucesso'}
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
          <CircularProgress size={60} thickness={4} />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {schedules.length > 0 ? (
            schedules.map(schedule => (
              <Grid item xs={12} sm={6} md={4} key={schedule.schedule_id}>
                <Card 
                  sx={{ 
                    borderRadius: 2, 
                    
                    transition: 'all 0.3s ease',
                    '&:hover': { 
                      transform: 'scale(1.00)',
                      
                    },
                    height: '100%'
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" sx={{ 
                      fontWeight: 'bold', 
                      color: '#1976d2', 
                      marginBottom: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}>
                      <DirectionsCarIcon />
                      {schedule.maintenance_type}
                    </Typography>
                    
                    <Divider sx={{ marginY: 2 }} /> 
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <DirectionsCarIcon sx={{ color: '#1976d2' }} />
                        <Link 
                          href={`/loja/servicos/detalhes/${schedule.schedule_id}?token=${token}`} 
                          underline="hover" 
                          sx={{ color: '#1976d2',fontSize: 20 }}
                        >
                          VER DETALHES: {schedule.schedule_id}
                        </Link>
                      </Box>


                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <DirectionsCarIcon sx={{ color: '#1976d2' }} />
                        <Typography variant="body2" sx={{ 
                          fontSize: 33,
                          padding: '4px 8px', 
                          borderRadius: '4px',
                          flex: 1 
                        }}>
                          Placa: {schedule.plate}
                        </Typography>
                      </Box>

                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <DateRangeIcon sx={{ color: '#1976d2' }} />
                        <Typography variant="body2">
                          {new Date(schedule.scheduled_date).toLocaleDateString()} 
                          {` às ${new Date(schedule.scheduled_date).toLocaleTimeString([], { 
                            hour: '2-digit', 
                            minute: '2-digit' 
                          })}`}
                        </Typography>
                      </Box>

                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Chip 
                          label={schedule.status.toUpperCase()} 
                          color={schedule.status === 'confirmado' ? 'success' : 'primary'}
                          sx={{ fontWeight: 'bold' }}
                        />
                      </Box>

                      <Typography variant="body2">
                        <strong>Próxima Distância:</strong> {schedule.next_service_distance} km
                      </Typography>

                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <BusinessIcon sx={{ color: '#1976d2' }} />
                        <Link 
                          href={`/loja/servicos/supplier/${schedule.supplier_id}?token=${token}`} 
                          underline="hover" 
                          sx={{ color: '#1976d2' }}
                        >
                          {schedule.supplier_name}
                        </Link>
                      </Box>

                      <Typography variant="body2">
                        <strong>Avaliação:</strong> {schedule.supplier_rating} ({schedule.supplier_reviews} reviews)
                      </Typography>

                      <FormControl fullWidth>
                        <InputLabel id={`status-label-${schedule.schedule_id}`}>
                          Status
                        </InputLabel>
                        <Select
                          labelId={`status-label-${schedule.schedule_id}`}
                          value={schedule.status}
                          label="Status"
                          onChange={(e) => updateScheduleStatus(schedule.schedule_id, e.target.value)}
                        >
                          <MenuItem value="confirmado">Confirmado</MenuItem>
                          <MenuItem value="agendado">Agendado</MenuItem>
                          <MenuItem value="concluido">Concluido</MenuItem>
                          <MenuItem value="cancelado">Cancelado</MenuItem>
                          <MenuItem value="reagendado">Reagendado</MenuItem>
                          <MenuItem value="aguardando">Aguardando</MenuItem>
                        </Select>
                      </FormControl>

                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        gap: 2,
                        marginTop: 2 
                      }}>
                        <Button 
                          variant="outlined" 
                          color="primary" 
                          startIcon={<ScheduleIcon />}
                          onClick={() => handleOpenDialog(schedule.schedule_id)}
                          fullWidth
                        >
                          Reagendar
                        </Button>
                        <Button 
                          variant="contained" 
                          color="success" 
                          startIcon={<CheckCircleIcon />}
                          onClick={() => updateScheduleStatus(schedule.schedule_id, 'confirmado')}
                          fullWidth
                        >
                          Confirmar
                        </Button>
                      </Box>
                        {/* Adiciona a propaganda "Powered by Torki" */}
                        <Divider sx={{ marginY: 2 }} />
                      <Typography variant="caption" sx={{ color: '#9e9e9e', textAlign: 'center', marginTop: 2 }}>
                        Powered by Torki
                      </Typography>
                    </Box>
                  </CardContent>                 
  
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Box sx={{ 
                textAlign: 'center', 
                padding: 4, 
                backgroundColor: '#ffffff',
                borderRadius: 2,
                boxShadow: 1
              }}>
                <Typography variant="h6" color="text.secondary">
                  Nenhum agendamento encontrado
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  Não existem agendamentos disponíveis no momento.
                </Typography>
              </Box>
            </Grid>
          )}
          
        </Grid>
      )}

      {/* Modal para Reagendamento */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Reagendar Atendimento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, insira a nova data e hora para o reagendamento.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Data"
            type="date"
            fullWidth
            variant="outlined"
            value={reScheduleData.date}
            onChange={(e) => setReScheduleData({ ...reScheduleData, date: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Hora"
            type="time"
            fullWidth
            variant="outlined"
            value={reScheduleData.time}
            onChange={(e) => setReScheduleData({ ...reScheduleData, time: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
          <Button onClick={handleReSchedule}>Reagendar</Button>
        </DialogActions>
          {/* Adiciona a propaganda "Powered by Torki" */}
          <Divider sx={{ marginY: 2 }} />
          <Typography variant="caption" sx={{ color: '#9e9e9e', textAlign: 'center', marginTop: 2 }}>
            Powered by Torki
          </Typography>

        
      </Dialog>    
    </Box>
  );
};

export default ServicesContent;
