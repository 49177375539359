import React from 'react';
import { useParams } from 'react-router-dom';
import theme from '../Tema';
import { ThemeProvider } from '@mui/material/styles';
import IntegridadeContent from './IntegridadeContent';
import {
  Box
} from '@mui/material';
function DetalhesVeiculos() {
  const { idVeiculo } = useParams();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'background.default' }}> 
        <IntegridadeContent/>
      </Box>
    </ThemeProvider>
  );
}

export default DetalhesVeiculos;