import React from 'react';
import Sidebar from './dashboard/Sidebar';
import Header from './dashboard/Header';
import FornecedoresContent from './FornecedoresContent';
// import Footer from './dashboard/Rodape';
import './Dashboard.css';

const App = () => {
  return (
    <div className="app">
      <Sidebar />      
      <div className="main-content">        
        <Header />
        <FornecedoresContent />        
        {/* <Footer /> */}
      </div>      
    </div>
  );
};

export default App;
