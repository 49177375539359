import React from 'react';
import './SearchBar.css';

const SearchBar = ({ searchTerm, onSearchChange }) => {
  return (
    <div className="search-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Buscar produtos e serviços..."
          value={searchTerm}
          onChange={onSearchChange}
          className="search-input"
        />
      </div>
    </div>
  );
};

export default SearchBar;
