// RespostasTicket.js
import React from 'react';
import { Card, CardContent, Typography, Divider } from '@mui/material';

const RespostasTicket = ({ ticket }) => (
  <Card variant="outlined" sx={{ backgroundColor: 'background.paper', borderRadius: 2 }}>
    <CardContent>
      <Typography variant="h6" color="text.primary">
        Resposta ao Cliente
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant="body2" color="text.secondary" mb={1}>
        <strong>Cliente:</strong> {ticket.clientName}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={1}>
        <strong>Assunto:</strong> {ticket.subject}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={1}>
        <strong>Mensagem:</strong> {ticket.message}
      </Typography>
    </CardContent>
  </Card>
);

export default RespostasTicket;
