// SidebarMenu.js
import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, Button } from '@mui/material';
import {
  Dashboard as DashboardIcon,
  CalendarMonth as CalendarIcon,
  Inventory as InventoryIcon,
  ShoppingCart as CartIcon,
  AccountBalance as FinanceIcon,
  People as PeopleIcon,
  DirectionsCar as CarIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { alpha } from '@mui/material/styles';

const menuItems = [
  { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
  { text: 'Agendamentos', icon: <CalendarIcon />, path: '/fornecedor' },
  { text: 'Produtos', icon: <InventoryIcon />, path: '/servicos' },
  { text: 'Marketplace', icon: <CartIcon />, path: '/loja' },
  { text: 'Financeiro', icon: <FinanceIcon />, path: '/financeiro' },
  { text: 'Clientes', icon: <PeopleIcon />, path: '/clientes' },
];

const SidebarMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentToken = new URLSearchParams(location.search).get('token');

  const navigateWithToken = (path) => {
    if (currentToken) {
      navigate(`${path}?token=${currentToken}`);
    } else {
      navigate(path);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("jwt_token");
    navigate("/login");
  };

  return (
    <Box>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
        <CarIcon sx={{ color: 'primary.main' }} />
        <Typography variant="h6" noWrap component="div">
          Torki Partner
        </Typography>
      </Box>
      <List>
        {menuItems.map((item) => (
          <ListItem 
            button 
            key={item.text}
            onClick={() => navigateWithToken(item.path)}
            sx={{
              mx: 1,
              borderRadius: 2,
              mb: 1,
              ...(location.pathname === item.path && {
                bgcolor: 'primary.main',
                '&:hover': {
                  bgcolor: alpha('#F97316', 0.8),
                },
              }),
            }}
          >
            <ListItemIcon sx={{ color: location.pathname === item.path ? 'white' : 'primary.main' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Box sx={{ p: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<LogoutIcon />}
          onClick={handleLogout}
          fullWidth
          sx={{
            color: 'white',
            borderColor: alpha('white', 0.5),
            '&:hover': {
              borderColor: 'white',
              bgcolor: alpha('primary.main', 0.2),
            },
          }}
        >
          Sair
        </Button>
      </Box>
    </Box>
  );
};

export default SidebarMenu;
