import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useLocation } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Card,
  CardContent,
  Grid,
  Chip,
  Button,
  InputAdornment,
  Alert,
  Paper,
  Divider,
  CircularProgress,
  IconButton,
  Tooltip,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import {
  Search as SearchIcon,
  LocationOn as LocationIcon,
  Check as CheckIcon,
  Info as InfoIcon,
  Map as MapIcon
} from '@mui/icons-material';
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Reset leaflet icon paths
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const CadastroServicos = () => {
  const { supplierId } = useParams();
  const [selectedServices, setSelectedServices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [prestador_id, setPrestador_id] = useState(null);
  const [dataSup, setDataSup] = useState(null);
  const location = useLocation();
  const [mapDialogOpen, setMapDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    endereco: "",
    latitude: null,
    longitude: null
  });

  const availableServices = [
    // Manutenção
    { "category": "Manutenção", "name": "Troca de Óleo", "icon": "🛢️", "description": "Substituição do óleo do motor." },
    { "category": "Manutenção", "name": "Abastecimento", "icon": "⛽", "description": "Posto de combustivel." },
    { "category": "Manutenção", "name": "Revisão Geral", "icon": "🛠️", "description": "Checagem completa do veículo." },
    { "category": "Manutenção", "name": "Manutenção de Freios", "icon": "🛑", "description": "Ajustes e substituição de freios." },
    { "category": "Manutenção", "name": "Troca de Pneus", "icon": "🛞", "description": "Substituição de pneus desgastados." },
    { "category": "Manutenção", "name": "Troca de Correia Dentada", "icon": "🔧", "description": "Substituição da correia dentada do motor." },
    { "category": "Manutenção", "name": "Alinhamento e Balanceamento", "icon": "⚖️", "description": "Ajuste da geometria e peso das rodas." },
    { "category": "Manutenção", "name": "Troca de Bateria", "icon": "🔋", "description": "Substituição da bateria do veículo." },
    { "category": "Manutenção", "name": "Troca de Amortecedores", "icon": "🔩", "description": "Substituição do sistema de amortecimento." },
    { "category": "Manutenção", "name": "Troca de Velas", "icon": "⚡", "description": "Substituição das velas de ignição." },
    { "category": "Manutenção", "name": "Manutenção da Embreagem", "icon": "🔄", "description": "Reparo ou troca do sistema de embreagem." },

    // Mecânica
    { "category": "Mecânica", "name": "Troca de Filtros", "icon": "🔄", "description": "Substituição dos filtros de ar e óleo." },
    { "category": "Mecânica", "name": "Reparo de Ar-Condicionado", "icon": "❄️", "description": "Manutenção e conserto do ar-condicionado." },
    { "category": "Mecânica", "name": "Diagnóstico Eletrônico", "icon": "💻", "description": "Análise computadorizada do veículo." },
    { "category": "Mecânica", "name": "Reparo do Motor", "icon": "⚙️", "description": "Conserto e revisão do motor." },
    { "category": "Mecânica", "name": "Reparo da Suspensão", "icon": "🔧", "description": "Manutenção do sistema de suspensão." },
    { "category": "Mecânica", "name": "Reparo do Câmbio", "icon": "⚙️", "description": "Manutenção da caixa de câmbio." },
    { "category": "Mecânica", "name": "Sistema de Arrefecimento", "icon": "🌡️", "description": "Manutenção do sistema de refrigeração." },
    { "category": "Mecânica", "name": "Sistema de Injeção", "icon": "💉", "description": "Limpeza e reparo do sistema de injeção." },

    // Estética
    { "category": "Estética", "name": "Polimento", "icon": "✨", "description": "Polimento da pintura do veículo." },
    { "category": "Estética", "name": "Pintura Automotiva", "icon": "🎨", "description": "Pintura completa ou parcial do veículo." },
    { "category": "Estética", "name": "Reparo de Vidros", "icon": "🪟", "description": "Correção de trincas e arranhões nos vidros." },
    { "category": "Estética", "name": "Instalação de Insulfilm", "icon": "🪟", "description": "Aplicação de película protetora nos vidros." },
    { "category": "Estética", "name": "Reparo de Lataria", "icon": "🔨", "description": "Correção de amassados e danos na lataria." },
    { "category": "Estética", "name": "Cristalização", "icon": "💎", "description": "Proteção e brilho para a pintura." },
    { "category": "Estética", "name": "Martelinho de Ouro", "icon": "🔨", "description": "Remoção de pequenos amassados." },
    { "category": "Estética", "name": "Personalização", "icon": "🎨", "description": "Customização visual do veículo." },
    { "category": "Estética", "name": "Restauração de Faróis", "icon": "💡", "description": "Polimento e restauração de faróis opacos." },

    // Limpeza e Higienização
    { "category": "Limpeza", "name": "Lavagem Completa", "icon": "🚿", "description": "Lavagem externa e interna." },
    { "category": "Higienização", "name": "Higienização Interna", "icon": "🧼", "description": "Limpeza completa do interior." },
    { "category": "Higienização", "name": "Lavagem a Seco", "icon": "🧹", "description": "Limpeza sem uso de água." },
    { "category": "Higienização", "name": "Higienização de Ar-Condicionado", "icon": "🌪️", "description": "Limpeza do sistema de climatização." },
    { "category": "Higienização", "name": "Impermeabilização de Estofados", "icon": "💧", "description": "Proteção contra líquidos nos bancos." },
    { "category": "Higienização", "name": "Limpeza Técnica", "icon": "🧪", "description": "Limpeza profunda com produtos específicos." },

    // Áudio e Eletrônica
    { "category": "Áudio", "name": "Instalação de Som", "icon": "🔊", "description": "Instalação de sistemas de som e multimídia." },
    { "category": "Áudio", "name": "Instalação de Alarme", "icon": "🚨", "description": "Instalação de sistema de segurança." },
    { "category": "Áudio", "name": "Instalação de GPS", "icon": "📍", "description": "Instalação de sistema de navegação." },
    { "category": "Áudio", "name": "Reparo Elétrico", "icon": "⚡", "description": "Conserto de problemas elétricos." },
    { "category": "Áudio", "name": "Instalação de Câmera de Ré", "icon": "📹", "description": "Instalação de câmera para manobras." },

    // Serviços Especiais
    { "category": "Especiais", "name": "Vistoria Veicular", "icon": "📋", "description": "Inspeção técnica do veículo." },
    { "category": "Especiais", "name": "Instalação de GNV", "icon": "⛽", "description": "Conversão para gás natural." },
    { "category": "Especiais", "name": "Laudo Técnico", "icon": "📝", "description": "Avaliação técnica documentada." },
    { "category": "Especiais", "name": "Guincho", "icon": "🚛", "description": "Serviço de reboque." },
    { "category": "Especiais", "name": "Socorro Mecânico", "icon": "🆘", "description": "Atendimento emergencial." }
  ];


  // Group services by category
  const servicesByCategory = availableServices.reduce((acc, service) => {
    if (!acc[service.category]) {
      acc[service.category] = [];
    }
    acc[service.category].push(service);
    return acc;
  }, {});

  const filteredServices = searchQuery
    ? availableServices.filter((service) =>
        service.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : availableServices;

  const handleMarkerDragEnd = (event) => {
    const { lat, lng } = event.target.getLatLng();
    setFormData({
      ...formData,
      latitude: lat,
      longitude: lng,
    });
  };

  const customJwtDecode = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(c =>
        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      ).join(''));
      return JSON.parse(jsonPayload);
    } catch (error) {
      throw new Error('Invalid token');
    }
  };

  useEffect(() => {
    if (!supplierId) {
      setError("supplierId não encontrado na URL");
      return;
    }

    const token = new URLSearchParams(location.search).get('token');
    const decodedToken = customJwtDecode(token);
    setPrestador_id(decodedToken.user.id);

    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/ec.php?id=${supplierId}`);
        if (!response.ok) throw new Error("Erro ao buscar dados do serviço");
        const data = await response.json();
        setDataSup(data.nome);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [supplierId, location.search]);

  const getLatLng = async (endereco) => {
    const apiKey = "13aba2f505474548b5fd66d2ec3a4ab5";
    const geocodeUrl = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(endereco)}&key=${apiKey}`;

    try {
      const response = await axios.get(geocodeUrl);
      if (response.data.results.length > 0) {
        const location = response.data.results[0].geometry;
        setFormData({
          ...formData,
          latitude: location.lat,
          longitude: location.lng,
        });
      } else {
        setError("Endereço não encontrado.");
      }
    } catch (error) {
      setError("Erro ao consultar a API de Geocodificação.");
    }
  };

  const handleServiceToggle = (serviceName) => {
    setSelectedServices(prev =>
      prev.includes(serviceName)
        ? prev.filter(name => name !== serviceName)
        : [...prev, serviceName]
    );
  };

  // Enviar os serviço
  const enviarServicos = async () => {
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const payload = {
        services: selectedServices.map(service => ({
          name: dataSup,
          service_type: service,
          location_lat: formData.latitude,
          location_long: formData.longitude,
          rating: 0,
          reviews: 0,
          prestador_id: prestador_id
        }))
      };

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/suppliers.php`, payload);

      console.log("AQUI MEU " + JSON.stringify(payload));

      if (response.status === 201 || response.status === 207) {
        if (response.data.errors?.length > 0) {
          setError(`Alguns serviços não foram cadastrados: ${response.data.errors.join(', ')}`);
        } else {
          setSuccessMessage("Todos os serviços foram cadastrados com sucesso!");
        }
        setSelectedServices([]);
      }
    } catch (error) {
      setError("Erro ao enviar os serviços.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4, color: "#F97316" }}>
      <Typography variant="h4" gutterBottom >
        Cadastro de Serviços - {dataSup}
      </Typography>

      <Paper sx={{ p: 3, mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
          <TextField
            fullWidth
            label="Endereço da Prestação de Serviço"
            value={formData.endereco}
            onChange={(e) => setFormData({ ...formData, endereco: e.target.value })}
            onBlur={() => getLatLng(formData.endereco)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LocationIcon color="primary" />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            startIcon={<MapIcon />}
            onClick={() => setMapDialogOpen(true)}
            disabled={!formData.latitude || !formData.longitude}
          >
            Ver Mapa
          </Button>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Latitude"
              value={formData.latitude || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Longitude"
              value={formData.longitude || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Paper>

      <TextField
        fullWidth
        label="Buscar serviços"
        variant="outlined"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ mb: 4 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {Object.entries(servicesByCategory).map(([category, services]) => (
        <Box key={category} sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            {category}
          </Typography>
          <Grid container spacing={2}>
            {services
              .filter(service =>
                service.name.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((service) => (
                <Grid item xs={12} sm={6} md={4} key={service.name}>
                  <Card
                    sx={{
                      cursor: 'pointer',
                      transition: 'transform 0.2s',
                      '&:hover': { transform: 'translateY(-4px)' },
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                    onClick={() => handleServiceToggle(service.name)}
                  >
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="h6">
                          {service.icon} {service.name}
                        </Typography>
                        {selectedServices.includes(service.name) && (
                          <CheckIcon color="success" />
                        )}
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        {service.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
      ))}

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4 }}>
        <Typography variant="body1">
          {selectedServices.length} serviços selecionados
        </Typography>
        <Button
          variant="contained"
          disabled={loading || selectedServices.length === 0}
          onClick={enviarServicos}
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          {loading ? "Enviando..." : "Salvar Serviços"}
        </Button>
      </Box>

      <Dialog
        open={mapDialogOpen}
        onClose={() => setMapDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Localização do Estabelecimento</DialogTitle>
        <DialogContent>
          {formData.latitude && formData.longitude && (
            <Box sx={{ height: 400, width: '100%' }}>
              <MapContainer
                center={[formData.latitude, formData.longitude]}
                zoom={15}
                style={{ height: '100%', width: '100%' }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker
                  position={[formData.latitude, formData.longitude]}
                  draggable={true}
                  eventHandlers={{ dragend: handleMarkerDragEnd }}
                >
                  <Popup>
                    Localização de {dataSup}
                  </Popup>
                </Marker>
              </MapContainer>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMapDialogOpen(false)}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CadastroServicos;