import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './ServiceDetails.css';

import Footer from "./dashboard/Rodape";

const ServiceDetails = () => {
  const { scheduleId } = useParams();
  const [serviceDetails, setServiceDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [sendingQuote, setSendingQuote] = useState(false);
  const [quoteSent, setQuoteSent] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    // Faz a consulta para obter os detalhes do serviço usando o scheduleId
    fetch(`${process.env.REACT_APP_API_BASE_URL}/prestadores/monta_dashboard_prestador.php?action=getServiceDetailsByScheduleId&scheduleId=${scheduleId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao buscar os detalhes do serviço.');
        }
        return response.json();
      })
      .then(data => {
        setServiceDetails(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, [scheduleId]);

  const calculateTotal = () => {
    if (!serviceDetails.length) return 0;
    return serviceDetails.reduce((total, detail) => {
      const productCost = detail.quantity * parseFloat(detail.unit_price);
      const laborCost = parseFloat(detail.labor_cost);
      const discount = parseFloat(detail.discount);
      const tax = parseFloat(detail.tax);
      return total + productCost + laborCost - discount + tax;
    }, 0).toFixed(2);
  };

  const calculateEarnings = (total) => {
    const earnings = total * 0.05; // 5% earnings for the service provider
    return earnings.toFixed(2);
  };

  const sendQuoteToClient = () => {
    setSendingQuote(true);
    const totalCost = calculateTotal();
    const dataVencimento = new Date();
    dataVencimento.setDate(dataVencimento.getDate() + 30); // 30 dias a partir da data atual

    const payload = {
      schedule_id: scheduleId,
      data_vencimento: dataVencimento.toISOString().split('T')[0], // Formato: YYYY-MM-DD
      status: 'PENDENTE',
      valor_total: parseFloat(totalCost),
      observacao: `Orçamento para ${serviceDetails[0].maintenance_type}`
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/prestadores/orcamentos.php?action=create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setQuoteSent(true);
        } else {
          setError('Erro ao enviar o orçamento: ' + data.message);
        }
        setSendingQuote(false);
      })
      .catch((error) => {
        setError('Erro ao enviar o orçamento: ' + error.message);
        setSendingQuote(false);
      });
  };

  const goBackToDashboard = () => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    navigate(`/dashboard?token=${token}`);
  };

  if (loading) {
    return (
      <div>
        
        <p>Carregando detalhes do serviço...</p>
        <Footer />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        
        <p className="error-message">{error}</p>
        <Footer />
      </div>
    );
  }

  // Renderiza o conteúdo principal se os detalhes do serviço estiverem disponíveis
  if (!serviceDetails.length) {
    return (
      <div>
        
        <p>Detalhes do serviço não encontrados.</p>
        <Footer />
      </div>
    );
  }

  const generalInfo = serviceDetails[0];
  const totalCost = calculateTotal();
  const earnings = calculateEarnings(totalCost);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      
      <div className="service-details" style={{ flex: 1, padding: '20px', backgroundColor: '#f5f5f5' }}>
        <button className="back-button" onClick={goBackToDashboard}>
          ⬅️ Voltar para o Dashboard
        </button>
        <h2>Detalhes do Serviço - {generalInfo.maintenance_type}</h2>

        <section className="service-info">
          <h3>Informações do Agendamento</h3>
          <p><strong>Placa:</strong> {generalInfo.plate}</p>
          <p><strong>Data Agendada:</strong> {new Date(generalInfo.scheduled_date).toLocaleString()}</p>
          <p><strong>Status:</strong> {generalInfo.status}</p>
          <p><strong>Próximo Serviço em:</strong> {generalInfo.next_service_distance} km</p>
          <p><strong>Fornecedor:</strong> {generalInfo.supplier_name}</p>
          <p><strong>Tipo de Serviço:</strong> {generalInfo.supplier_service_type}</p>
          <p><strong>Avaliação:</strong> ⭐ {generalInfo.supplier_rating} ({generalInfo.supplier_reviews} avaliações)</p>
        </section>

        <section className="details-info">
          <h3>Detalhes do Produto/Serviço</h3>
          <table className="service-details-table">
            <thead>
              <tr>
                <th>Descrição do Produto</th>
                <th>Quantidade</th>
                <th>Preço Unitário</th>
                <th>Custo da Mão de Obra</th>
                <th>Horas de Trabalho</th>
                <th>Data do Serviço</th>
              </tr>
            </thead>
            <tbody>
              {serviceDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.product_description}</td>
                  <td>{detail.quantity}</td>
                  <td>R$ {parseFloat(detail.unit_price).toFixed(2)}</td>
                  <td>R$ {parseFloat(detail.labor_cost).toFixed(2)}</td>
                  <td>{detail.labor_hours} horas</td>
                  <td>{new Date(detail.service_date).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <section className="payment-info">
          <h3>Informações de Pagamento</h3>
          <p><strong>Método de Pagamento:</strong> {generalInfo.payment_method}</p>
          <p><strong>Status do Pagamento:</strong> {generalInfo.payment_status}</p>
          <p><strong>Notas:</strong> {generalInfo.notes}</p>
        </section>

        <section className="calculation-info">
          <h3>Resumo Financeiro</h3>
          <p><strong>Orçamento Total:</strong> R$ {totalCost}</p>
          <p><strong>Ganhos do Prestador:</strong> R$ {earnings}</p>
        </section>

        {generalInfo.status.toLowerCase() === 'agendado' && (
          <section className="quote-action">
            <h3>Ações</h3>
            <button
              className="send-quote-button"
              onClick={sendQuoteToClient}
              disabled={sendingQuote || quoteSent}
            >
              {sendingQuote ? 'Enviando...' : quoteSent ? 'Orçamento Enviado' : 'Enviar Orçamento ao Cliente'}
            </button>
            {error && <p className="error-message">{error}</p>}
          </section>
        )}
      </div>
      <Footer />
    </div>
  );
};

// Componente de Barra de Status
const StatusBar = ({ status }) => {
  const statusSteps = ['agendado', 'confirmado', 'Em andamento', 'concluído', 'pago'];
  const currentIndex = statusSteps.indexOf(status.toLowerCase());

  return (
    <div className="status-bar">
      {statusSteps.map((step, index) => (
        <div
          key={step}
          className={`status-step ${index <= currentIndex ? 'active' : ''}`}
        >
          {step.charAt(0).toUpperCase() + step.slice(1)}
        </div>
      ))}
    </div>
  );
};

export default ServiceDetails;
