import React, { useEffect } from "react";
import {
  AppBar,
  Box,
  Container,
  Typography,
  Button,
  ThemeProvider,
} from "@mui/material";
import { DirectionsCar as CarIcon } from "@mui/icons-material";
import theme from "./Tema";

const LandingPage = () => {
  useEffect(() => {
    // Google Remarketing
    const scriptGoogle = document.createElement("script");
    scriptGoogle.src = "https://www.googletagmanager.com/ns.html?id=GTM-P4XQ6HKZ";
    document.head.appendChild(scriptGoogle);
  
    scriptGoogle.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      gtag("js", new Date());
      gtag("config", "GTM-P4XQ6HKZ");
    };


      // Facebook Pixel
    const scriptFb = document.createElement("script");
    scriptFb.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', 'YOUR_PIXEL_ID');
      fbq('track', 'PageView');
    `;
    document.head.appendChild(scriptFb);


  
    // Fetch Facebook Pixel ID from a secure source (e.g., environment variable)
    // and call fbq('init', fetchedPixelId);
  
  }, []);
  

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: "background.default", minHeight: "100vh" }}>
        {/* Hero Section */}
        <Box py={10} textAlign="center" sx={{ bgcolor: "background.paper" }}>
          <Container>
            <Typography variant="h2" color="primary" gutterBottom>
              Bem-vindo à Torki!
            </Typography>
            <Typography variant="h5" color="textSecondary" paragraph>
              Uma plataforma completa e inovadora para o mercado de veículos.
            </Typography>
            <Button variant="contained" color="primary" size="large">
              Notifique-me
            </Button>
          </Container>
        </Box>

        {/* Features Section */}
        <Box py={8} sx={{ bgcolor: "background.default" }}>
          <Container>
            <Typography variant="h3" textAlign="center" mb={4}>
              Principais Recursos
            </Typography>
            <Box display="flex" justifyContent="space-around">
              <Box textAlign="center">
                <CarIcon fontSize="large" color="textprimary" />
                <Typography variant="h6">Compra e Venda</Typography>
                <Typography variant="body2" color="textsecondary">
                  Facilite a compra e venda de veículos com segurança.
                </Typography>
              </Box>
              <Box textAlign="center">
                <CarIcon fontSize="large" color="primary" />
                <Typography variant="h6">Manutenção</Typography>
                <Typography variant="body2">
                  Serviços de manutenção e revisão no seu veículo.
                </Typography>
              </Box>
              <Box textAlign="center">
                <CarIcon fontSize="large" color="primary" />
                <Typography variant="h6">Seguros</Typography>
                <Typography variant="body2">
                  Proteja seu veículo com seguros personalizados.
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>

        {/* Footer */}
        <Box py={4} textAlign="center" sx={{ bgcolor: "background.paper" }}>
          <Container>
            <Typography variant="body2" color="textSecondary">
              &copy; 2023 Torki. Todos os direitos reservados.
            </Typography>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;
