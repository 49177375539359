// TicketLead.js
import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Sidebar from './Sidebar';
import Header from './Header';
import TicketList from './TicketList';
import QuickTicketForm from './QuickTicketForm';
import NewTicketModal from './NewTicketModal';
import AtendimentoTicket from './ticketstype/AtendimentoTicket';
import RespostasTicket from './ticketstype/RespostasTicket';
import VendasTicket from './ticketstype/VendasTicket';
import FinanceiroTicket from './ticketstype/FinanceiroTicket';

const TicketLead = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleTicketSelect = (ticket) => {
    setSelectedTicket(ticket);
  };

  const renderSelectedTicket = () => {
    if (!selectedTicket) return <QuickTicketForm />;

    switch (selectedTicket.type) {
      case 'atendimento':
        return <AtendimentoTicket ticket={selectedTicket} />;
      case 'respostas':
        return <RespostasTicket ticket={selectedTicket} />;
      case 'vendas':
        return <VendasTicket ticket={selectedTicket} />;
      case 'financeiro':
        return <FinanceiroTicket ticket={selectedTicket} />;
      default:
        return <QuickTicketForm />;
    }
  };

  return (
    <Box display="flex" minHeight="100vh" bgcolor="background.default">
      <Sidebar />
      <Box flex={1} p={3} bgcolor="background.default">
        <Header onNewTicketClick={handleOpenModal} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <TicketList onTicketSelect={handleTicketSelect} />
          </Grid>
          <Grid item xs={12} md={4}>
            {renderSelectedTicket()}
          </Grid>
        </Grid>
        <NewTicketModal open={openModal} onClose={handleCloseModal} />
      </Box>
    </Box>
  );
};

export default TicketLead;
