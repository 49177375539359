import React from 'react';
import CadastroPrestadorContent from './CadastroPrestadorContent';
import { useParams } from 'react-router-dom';
import theme from '../../Tema';
import { ThemeProvider } from '@mui/material/styles';
import Layout from "../Layout";
import {
  Box
} from '@mui/material';



const CadastroPrestador = () => {
  return (
    <Layout>
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}> 
      <CadastroPrestadorContent />           
      </Box>
    </ThemeProvider>
    </Layout>
  );
};

export default CadastroPrestador;

