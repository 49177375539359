// Sidebar.js
import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';

const Sidebar = () => (
  <Box width={220} bgcolor="background.paper" p={2} sx={{ height: '100vh' }}>
    <Typography variant="h5" color="primary.contrastText" mb={3}>
      TICKET LEAD
    </Typography>
    <List>
      {['Dashboard', 'Users', 'Tickets', 'Officials', 'Site Settings'].map((text) => (
        <ListItem button key={text}>
          <ListItemText primary={text} primaryTypographyProps={{ color: 'text.primary' }} />
        </ListItem>
      ))}
    </List>
  </Box>
);

export default Sidebar;
