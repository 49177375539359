import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Snackbar,
  IconButton,
  Divider,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/prestadores/`;


const ServiceDetailsOrcamento = () => {
  const { schedule_id } = useParams();  // Pega o schedule_id da URL
  const [activeStep, setActiveStep] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(null);

  const steps = ['Itens do Serviço', 'Mão de Obra', 'Resumo do Orçamento'];

  const [items, setItems] = useState([]);
  const [laborCost, setLaborCost] = useState(0);
  const [dataCriacao, setDataCriacao] = useState(new Date().toISOString().slice(0, 10));
  const [dataVencimento, setDataVencimento] = useState('');

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);
  const handleCloseSnackbar = () => setSnackbarOpen(false);
  

  const handleAddItem = () => {
    setItems([...items, { product_description: '', quantity: 1, unit_price: 0 }]);
  };

  const handleRemoveItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setItems(updatedItems);
  };

  const calculateTotal = () => {
    const itemTotal = items.reduce((acc, item) => acc + item.quantity * item.unit_price, 0);
    return itemTotal + parseFloat(laborCost || 0);
  };

  const handleSave = async () => {
    const totalValue = calculateTotal();
    const orcamentoPayload = {
      schedule_id,
      data_criacao: dataCriacao,
      data_vencimento: dataVencimento,
      status: 'pendente',
      valor_total: totalValue,
      observacao: 'Orçamento consolidado'
    };
  
    const detailsPayload = items.map(item => ({
      schedule_id,
      product_description: item.product_description,
      quantity: item.quantity,
      unit_price: item.unit_price,
      labor_description: 'Custo total de mão de obra',
      labor_hours: 0,
      labor_cost: parseFloat(laborCost || 0),
      service_date: new Date().toISOString(),
      discount: 0,
      tax: 0,
      notes: 'Detalhes do item'
    }));
  
    try {
      // Envia o orçamento consolidado
      const orcamentoResponse = await fetch(`${BASE_URL}prestadores/orcamentos.php?action=create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(orcamentoPayload)
      });
  
      if (!orcamentoResponse.ok) throw new Error('Erro ao enviar o orçamento');
  
      // Envia os detalhes dos itens do orçamento
      const itemsResponse = await fetch(`${BASE_URL}prestadores/itens_orcamento.php?action=create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(detailsPayload)
      });
  
      if (!itemsResponse.ok) throw new Error('Erro ao enviar os itens do orçamento');
  
      setSnackbarOpen(true);
      setError(null);
    } catch (error) {
      console.error(error);
      setError('Erro ao salvar orçamento: ' + error.message);
      setSnackbarOpen(true);
    } 
  };
  

  return (
    <Box sx={{ padding: 4, minHeight: '100vh', backgroundColor: '#f9fafb' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
        Cadastro de Orçamento de Serviços <small>(Powered by Torki)</small>
      </Typography>

      <Stepper activeStep={activeStep} alternativeLabel sx={{ marginBottom: 4 }}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={error || 'Orçamento salvo com sucesso!'}
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      {activeStep === 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: 3, boxShadow: 3, borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h6" color="text.primary">
                  Itens do Serviço <small>(Powered by Torki)</small>
                </Typography>
                <Divider sx={{ my: 2 }} />

                {items.map((item, index) => (
                  <Box key={index} sx={{ marginBottom: 2 }}>
                    <TextField
                      label="Descrição do Produto"
                      name="product_description"
                      value={item.product_description}
                      onChange={(e) => handleItemChange(index, 'product_description', e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Quantidade"
                      name="quantity"
                      type="number"
                      value={item.quantity}
                      onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                      margin="normal"
                      sx={{ width: '45%', marginRight: 2 }}
                    />
                    <TextField
                      label="Preço Unitário"
                      name="unit_price"
                      type="number"
                      value={item.unit_price}
                      onChange={(e) => handleItemChange(index, 'unit_price', e.target.value)}
                      margin="normal"
                      sx={{ width: '45%' }}
                    />
                    <Tooltip title="Remover item" arrow>
                      <IconButton color="secondary" onClick={() => handleRemoveItem(index)} sx={{ marginTop: '16px' }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ))}

                <Button variant="outlined" color="primary" onClick={handleAddItem} startIcon={<AddIcon />} sx={{ marginTop: 2 }}>
                  Adicionar Item
                </Button>

                <Button variant="contained" color="primary" onClick={handleNext} sx={{ marginTop: 2 }}>
                  Próximo
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {activeStep === 1 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: 3, boxShadow: 3, borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h6" color="text.primary">
                  Custo de Mão de Obra <small>(Powered by Torki)</small>
                </Typography>
                <Divider sx={{ my: 2 }} />

                <TextField
                  label="Custo Total de Mão de Obra"
                  name="labor_cost"
                  type="number"
                  value={laborCost}
                  onChange={(e) => setLaborCost(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Data de Criação do Orçamento"
                  name="data_criacao"
                  type="date"
                  value={dataCriacao}
                  onChange={(e) => setDataCriacao(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Data de Vencimento do Orçamento"
                  name="data_vencimento"
                  type="date"
                  value={dataVencimento}
                  onChange={(e) => setDataVencimento(e.target.value)}
                  fullWidth
                  margin="normal"
                />

                <Button variant="contained" color="primary" onClick={handleNext} sx={{ marginTop: 2 }}>
                  Próximo
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {activeStep === 2 && (
        <Grid container justifyContent="center" alignItems="center" direction="column">
          <Typography variant="h5" color="text.primary" gutterBottom>
            <CheckCircleIcon fontSize="large" color="success" /> Resumo do Orçamento <small>(Powered by Torki)</small>
          </Typography>
          
          <Divider sx={{ width: '80%', my: 2 }} />
          <Typography variant="body1" gutterBottom>
            <strong>Itens do Serviço:</strong>
          </Typography>

          {items.map((item, index) => (
            <Typography key={index} variant="body2" gutterBottom>
              {item.product_description} - {item.quantity} x R${item.unit_price} = R${(item.quantity * item.unit_price).toFixed(2)}
            </Typography>
          ))}

          <Box sx={{ width: '80%', my: 2 }}>
            <Divider />
          </Box>

          <Box sx={{ width: '80%', backgroundColor: '#e3f2fd', padding: 2, borderRadius: 1 }}>
            <Typography variant="body1" gutterBottom>
              <strong>Consolidado de Orçamento</strong> <small>(Powered by Torki)</small>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Subtotal dos Itens:</strong> R${items.reduce((acc, item) => acc + item.quantity * item.unit_price, 0).toFixed(2)}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Custo de Mão de Obra:</strong> R${parseFloat(laborCost || 0).toFixed(2)}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Data de Criação:</strong> {dataCriacao}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Data de Vencimento:</strong> {dataVencimento}
            </Typography>
            <Typography variant="h6" color="primary" gutterBottom sx={{ mt: 2 }}>
              <strong>Valor Total do Orçamento:</strong> R${calculateTotal().toFixed(2)}
            </Typography>
          </Box>

          <Button variant="contained" color="success" onClick={handleSave} sx={{ mt: 2 }}>
            Confirmar e Enviar
          </Button>
          <Button variant="outlined" color="primary" onClick={handleBack} sx={{ mt: 2 }}>
            Voltar
          </Button>
        </Grid>
      )}
    </Box>
  );
};

export default ServiceDetailsOrcamento;
