import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import './Budgets.css';
import Header from "./components/Header";
import Footer from "./components/Footer";

import {jwtDecode} from 'jwt-decode'; // Certifique-se de que o jwt-decode está instalado

const Budgets = () => {
  const [budgets, setBudgets] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenInUrl = queryParams.get('token');


  useEffect(() => {
    const fetchBudgets = async () => {
      try {
        // Passo 1: Capturar o token da URL
        const queryParams = new URLSearchParams(window.location.search);
        const tokenInUrl = queryParams.get('token');
    
        if (!tokenInUrl) {
          alert('Token não encontrado na URL');
          return;
        }
    
        // Passo 2: Decodificar o token e extrair o user_id
        const decodedToken = jwtDecode(tokenInUrl);
        const userId = decodedToken.data.id; // Certifique-se de que o user_id está dentro de data.id
                
        // Passo 3: Enviar o user_id ao PHP via query string
        const response = await fetch(`https://teste.stratusport.co/festafacil/budgets.php?user_id=${userId}`, {
          method: 'GET',
        });
    
        const result = await response.json();
    
        if (response.ok) {
          setBudgets(result);
        } else {
          alert('Erro ao carregar orçamentos.');
        }
      } catch (error) {
        console.error('Erro ao buscar orçamentos:', error);
        alert('Erro ao carregar orçamentos.');
      }
    };    

    fetchBudgets();
  }, []);

  const handleDetailsClick = (budgetId) => {
    // Redirecionar para a página de detalhes do orçamento
    navigate(`/budget-details/${budgetId}?token=${tokenInUrl}`);
  };

  const handleRemoveClick = async (budgetId) => {
    const confirmed = window.confirm("Tem certeza de que deseja excluir este orçamento?");
    
    if (confirmed) {
      try {
        // Fazer a requisição DELETE para o endpoint
        const response = await fetch(`https://teste.stratusport.co/festafacil/budgets.php`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ budget_id: budgetId }),
        });
  
        const result = await response.json();
  
        if (response.ok) {
          alert('Orçamento excluído com sucesso!');
          // Aqui você pode atualizar a lista de orçamentos removendo o orçamento excluído
          // Exemplo: setBudgets(budgets.filter(budget => budget.id !== budgetId));
        } else {
          alert(`Erro ao excluir orçamento: ${result.message}`);
        }
      } catch (error) {
        console.error('Erro ao excluir orçamento:', error);
        alert('Ocorreu um erro ao tentar excluir o orçamento. Tente novamente.');
      }
    }
  };
  
  return (    
    <div className="budgets-container">     
      <Header />       
      <h2></h2>
      <div className="budgets-list">
        {budgets.length === 0 ? (
          <p className="no-budgets-message">Nenhum orçamento encontrado. Crie seu primeiro orçamento!</p>
        ) : (
          budgets.map((budget) => (
            <div key={budget.id} className="budget-card">
              <div className="budget-info">
                <p><strong>Data do Evento:</strong> {budget.event_date}</p>
                <p><strong>Localidade:</strong> {budget.location}</p>
              </div>
              <div className="budget-total">
                <p className="label">Valor Total</p>
                <span className="total-amount-sucesso">R$ {budget.total_price}</span>
              </div>
              <div className="budget-actions">
              <button className="btn-edit" onClick={() => handleDetailsClick(budget.id)}>
                  Detalhar
                </button>
                <button className="btn-delete" onClick={() => handleRemoveClick(budget.id)}>Excluir</button>
              </div>

              {/* Rodapé discreto com "Powered by FestaFácil" */}
              <div className="powered-by-festafacil">
                <p>Powered by <strong>FestaFácil</strong></p>
              </div>
            </div>
          ))
        )}
      </div>
      <Footer/>
    </div>
  );
};

export default Budgets;
