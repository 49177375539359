import React, { useState, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import { 
  Box, 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Container,
  Grid,
  IconButton,
  Badge,
  Avatar,
  Chip,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Alert,
  LinearProgress
} from '@mui/material';
import {
  Menu as MenuIcon,
  Notifications,
  Help,
  Stars,
  TrendingUp,
  BuildCircle,
  Support,
  WhatsApp,
  Email,
  DirectionsCar,
  Dashboard,
  Store,
  ShoppingCart,
  LocalShipping,
  People,
  Assessment,
  Settings,
  Diamond,
  MonetizationOn,
  Speed,
  EmojiEvents,
  Bolt,
  Celebration
} from '@mui/icons-material';
import { jwtDecode } from "jwt-decode"; // Importar a biblioteca para decodificar JWT
import FloatingMenu from './FloatingMenu';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showCelebration, setShowCelebration] = useState(false);
  const [nomePrestador, setNomePrestador] = useState(false);
  const [tipoParceiro, settipoParceiro] = useState(false);
  const drawerWidth = 280;

  const menuItems = [
    { text: 'Dashboard', icon: <Dashboard />, active: true, notifications: 2 },
    { text: 'Minha Loja', icon: <Store />, notifications: 0 },
    { text: 'Pedidos', icon: <ShoppingCart />, notifications: 5 },
    { text: 'Entregas', icon: <LocalShipping />, notifications: 1 },
    { text: 'Clientes', icon: <People />, notifications: 0 },
    { text: 'Relatórios', icon: <Assessment />, notifications: 0 },
    { text: 'Configurações', icon: <Settings />, notifications: 0 },
  ];

  

  useEffect(() => {
    // Show celebration animation periodically
    const token = new URLSearchParams(window.location.search).get('token'); // Supondo que o token esteja na URL
    let sellerId = '';

    if (token) {
      const decoded = jwtDecode(token);
      setNomePrestador(decoded.user.name); // Obtendo o user.id do JWT
      settipoParceiro(decoded.user.tipo_parceiro); // Obtendo o user.tipo_parceiro do JWT
    }

    const timer = setInterval(() => {
      setShowCelebration(true);
      setTimeout(() => setShowCelebration(false), 3000);
    }, 15000);
    return () => clearInterval(timer);
  }, []);

  const partnerMetrics = [
    { label: 'Taxa de Conversão', value: '4.8%', trend: '+0.5%', color: '#10B981' },
    { label: 'Nível de Serviço', value: '98%', trend: '+2%', color: '#F97316' },
    { label: 'Vendas do Mês', value: 'R$ 45.2K', trend: '+12%', color: '#6366F1' }
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* Premium Partner Banner */}
      <Box 
        sx={{ 
          bgcolor: (theme) => `linear-gradient(90deg, ${alpha('#F97316', 0.15)} 0%, ${alpha('#F97316', 0.05)} 100%)`,
          position: 'relative',
          overflow: 'hidden',
          py: 1.5,
          borderBottom: '1px solid rgba(249, 115, 22, 0.2)'
        }}
      >
        <Container>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <EmojiEvents sx={{ color: '#F97316', animation: showCelebration ? 'bounce 1s infinite' : 'none' }} />
              <Box>
                <Typography variant="body2" sx={{ color: '#0A192F', fontWeight: 500 }}>
                  Você está entre os TOP 5% parceiros Torki! 
                </Typography>
                <Typography variant="caption" sx={{ color: 'gray' }}>
                  Desbloqueie benefícios exclusivos aumentando suas vendas
                </Typography>
              </Box>
            </Box>
            <Button 
              variant="contained" 
              size="small"
              sx={{ 
                bgcolor: '#F97316',
                '&:hover': { bgcolor: '#EA580C' },
                animation: showCelebration ? 'pulse 2s infinite' : 'none'
              }}
              startIcon={<Diamond />}
            >
              Upgrade Premium
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Main Header */}
      <AppBar 
        position="sticky" 
        sx={{ 
          bgcolor: alpha('#0A192F', 0.98),
          backdropFilter: 'blur(20px)',
          boxShadow: 'none',
          borderBottom: '1px solid rgba(255, 255, 255, 0.05)'
        }}
      >
        <Toolbar sx={{ py: 1 }}>
          <IconButton
            color="inherit"
            edge="start"
            onClick={() => setDrawerOpen(true)}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexGrow: 1 }}>
            <Box sx={{ position: 'relative' }}>
              <DirectionsCar sx={{ color: 'primary.main', fontSize: 40 }} />
              <Bolt sx={{ 
                position: 'absolute', 
                right: -8, 
                top: -8, 
                color: '#F97316', 
                fontSize: 20,
                animation: 'pulse 2s infinite'
              }} />
            </Box>
            <Box>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Torki Partner
              </Typography>
              <Typography variant="caption" sx={{ color: 'gray' }}>
                Sua Central de Negócios
              </Typography>
            </Box>
          </Box>

          {/* Partner Metrics */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            {partnerMetrics.map((metric) => (
              <Box key={metric.label} sx={{ textAlign: 'right', display: { xs: 'none', lg: 'block' } }}>
                <Typography variant="caption" sx={{ color: 'gray' }}>
                  {metric.label}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Typography variant="h6" sx={{ color: metric.color }}>
                    {metric.value}
                  </Typography>
                  <Typography variant="caption" sx={{ color: metric.color }}>
                    {metric.trend} <TrendingUp sx={{ fontSize: 12 }} />
                  </Typography>
                </Box>
              </Box>
            ))}

            <Chip 
              icon={<Speed sx={{ color: '#F97316' }} />}
              label={tipoParceiro}
              sx={{ 
                bgcolor: alpha('#F97316', 0.1),
                color: '#F97316',
                borderColor: alpha('#F97316', 0.3),
                display: { xs: 'none', md: 'flex' }
              }}
              variant="outlined"
            />

            <IconButton color="inherit">
              <Badge badgeContent={8} color="primary">
                <Notifications />
              </Badge>
            </IconButton>

            <IconButton color="inherit">
              <Help />
            </IconButton>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Avatar sx={{ 
                bgcolor: 'primary.main',
                border: '2px solid #F97316'
              }}>P</Avatar>
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Typography variant="body2">{nomePrestador}</Typography>
                <Typography variant="caption" sx={{ color: '#F97316' }}>
                {tipoParceiro}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { 
            width: drawerWidth,
            bgcolor: '#0A192F',
            borderRight: '1px solid rgba(255, 255, 255, 0.05)'
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 4 }}>
            <DirectionsCar sx={{ color: 'primary.main', fontSize: 32 }} />
            <Typography variant="h6">Torki Partner</Typography>
          </Box>
          <List>
            {menuItems.map((item) => (
              <ListItem 
                button 
                key={item.text}
                sx={{
                  borderRadius: 2,
                  mb: 1,
                  position: 'relative',
                  ...(item.active && {
                    bgcolor: 'primary.main',
                    '&:hover': {
                      bgcolor: alpha('#F97316', 0.8),
                    },
                  }),
                }}
              >
                <ListItemIcon sx={{ color: item.active ? 'white' : 'primary.main' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
                {item.notifications > 0 && (
                  <Chip
                    size="small"
                    label={item.notifications}
                    sx={{ 
                      bgcolor: '#F97316',
                      color: 'white',
                      height: 20,
                      minWidth: 20,
                      fontSize: '0.75rem'
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

const Footer = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const benefits = [
    {
      icon: <MonetizationOn sx={{ fontSize: 48, color: '#F97316' }} />,
      title: 'Maximize seus Lucros',
      desc: 'Até 300% mais rentabilidade com nossas ferramentas exclusivas'
    },
    {
      icon: <BuildCircle sx={{ fontSize: 48, color: '#F97316' }} />,
      title: 'Gestão Inteligente',
      desc: 'IA avançada para otimizar suas operações e aumentar vendas'
    },
    {
      icon: <Support sx={{ fontSize: 48, color: '#F97316' }} />,
      title: 'Suporte Premium 24/7',
      desc: 'Equipe dedicada para impulsionar seu crescimento'
    }
  ];

  return (
    <Box 
      sx={{ 
        bgcolor: alpha('#0A192F', 0.98),
        color: 'white',
        borderTop: '1px solid rgba(255, 255, 255, 0.05)',
        mt: 8,
        py: 8
      }}
    >
      <Container>
        {/* Partner Benefits */}
        <Grid container spacing={6} sx={{ mb: 8 }}>
          {benefits.map((benefit, index) => (
            <Grid item xs={12} md={4} key={benefit.title}>
              <Box 
                sx={{ 
                  textAlign: 'center',
                  p: 3,
                  borderRadius: 4,
                  bgcolor: alpha('#F97316', 0.05),
                  height: '100%',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    bgcolor: alpha('#F97316', 0.1),
                  }
                }}
              >
                {benefit.icon}
                <Typography variant="h6" sx={{ mt: 2, mb: 1, color: '#F97316' }}>
                  {benefit.title}
                </Typography>
                <Typography variant="body2" color="gray">
                  {benefit.desc}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Growth Metrics */}
        <Box sx={{ mb: 6, p: 4, borderRadius: 4, bgcolor: alpha('#F97316', 0.05) }}>
          <Typography variant="h5" sx={{ mb: 3, textAlign: 'center', color: '#F97316' }}>
            Crescimento Torki Partners 2024
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h3" sx={{ color: '#F97316', mb: 1 }}>
                  15K+
                </Typography>
                <Typography variant="body2" color="gray">
                  Parceiros Ativos
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h3" sx={{ color: '#F97316', mb: 1 }}>
                  R$ 120M
                </Typography>
                <Typography variant="body2" color="gray">
                  Volume Mensal
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h3" sx={{ color: '#F97316', mb: 1 }}>
                  98%
                </Typography>
                <Typography variant="body2" color="gray">
                  Satisfação
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 4 }}>
            <Typography variant="body2" color="gray" sx={{ mb: 1 }}>
              Meta 2024
            </Typography>
            <LinearProgress 
              variant="determinate" 
              value={progress}
              sx={{
                height: 8,
                borderRadius: 4,
                bgcolor: alpha('#F97316', 0.2),
                '& .MuiLinearProgress-bar': {
                  bgcolor: '#F97316'
                }
              }}
            />
          </Box>
        </Box>

        {/* Support Section */}
        <Grid container spacing={3} alignItems="center" sx={{ mb: 6 }}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
              <Support sx={{ fontSize: 48, color: '#F97316' }} />
              <Box>
                <Typography variant="h6" sx={{ mb: 1, color: '#F97316' }}>
                  Suporte Especializado
                </Typography>
                <Typography variant="body2" color="gray">
                  Nossa equipe premium está disponível 24/7 para impulsionar seu sucesso
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: { md: 'flex-end' } }}>
              <Button
                variant="contained"
                startIcon={<WhatsApp />}
                sx={{ 
                  borderRadius: 2,
                  bgcolor: '#F97316',
                  '&:hover': {
                    bgcolor: '#EA580C'
                  }
                }}
              >
                Suporte VIP
              </Button>
              <Button
                variant="outlined"
                startIcon={<Email />}
                sx={{ 
                  borderRadius: 2,
                  borderColor: '#F97316',
                  color: '#F97316',
                  '&:hover': {
                    borderColor: '#EA580C',
                    bgcolor: alpha('#F97316', 0.1)
                  }
                }}
              >
                Contato
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ mb: 1, borderColor: 'rgba(255, 255, 255, 0.05)' }} />

        {/* Bottom Section */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <DirectionsCar sx={{ color: '#F97316', fontSize: 32 }} />
              <Typography variant="h6">Torki Partner</Typography>
            </Box>
            <Typography variant="body2" color="gray" sx={{ mb: 3 }}>
              Plataforma líder em soluções automotivas, conectando parceiros de sucesso a milhões de clientes em todo o Brasil.
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {['App Store', 'Play Store', 'Web App'].map((platform) => (
                <Chip
                  key={platform}
                  label={platform}
                  size="small"
                  sx={{
                    bgcolor: alpha('#F97316', 0.1),
                    color: '#F97316',
                    '&:hover': {
                      bgcolor: alpha('#F97316', 0.2)
                    }
                  }}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={4}>
              {[
                {
                  title: 'Recursos',
                  items: ['Academia Torki', 'Central de Ajuda', 'Blog do Parceiro', 'Calculadora de Lucros']
                },
                {
                  title: 'Empresa',
                  items: ['Sobre nós', 'Carreiras', 'Imprensa', 'Responsabilidade']
                },
                {
                  title: 'Legal',
                  items: ['Termos de Uso', 'Privacidade', 'Compliance', 'Segurança']
                }
              ].map((section) => (
                <Grid item xs={12} sm={4} key={section.title}>
                  <Typography variant="subtitle2" sx={{ color: '#F97316', mb: 2 }}>
                    {section.title}
                  </Typography>
                  <List dense disablePadding>
                    {section.items.map((item) => (
                      <ListItem key={item} sx={{ py: 0.5 }}>
                        <Typography 
                          variant="body2" 
                          color="gray"
                          sx={{ 
                            cursor: 'pointer',
                            '&:hover': { color: '#F97316' }
                          }}
                        >
                          {item}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Box sx={{ mt: 6, pt: 4, borderTop: '1px solid rgba(255, 255, 255, 0.05)', textAlign: 'center' }}>
          <Typography variant="body2" color="gray">
            © {new Date().getFullYear()} Torki. Todos os direitos reservados.
          </Typography>
        </Box>
      </Container>

      {/* Global Styles */}
      <style jsx global>{`
        @keyframes pulse {
          0% { transform: scale(1); }
          50% { transform: scale(1.1); }
          100% { transform: scale(1); }
        }
        @keyframes bounce {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }
      `}</style>
    </Box>
  );
};

const PartnerLayout = ({ children }) => {
  return (
    <Box sx={{ 
      minHeight: '100vh',
      bgcolor: '#F8FAFC',
      '& *::selection': {
        bgcolor: alpha('#F97316', 0.2),
        color: '#0A192F'
      }
    }}>
      <Header />
      <FloatingMenu />
      <Box sx={{ flex: 1 }}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default PartnerLayout;