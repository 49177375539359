import React from "react";
import { useParams } from "react-router-dom";
import theme from "../../Tema";
import { ThemeProvider } from "@mui/material/styles";
import { Box, CssBaseline } from "@mui/material";
import Layout from "../Layout";
import ServiceDetailsContent from './ServiceDetailsContent';

import NotificationPanel from '../../components/NotificationQueue';

const Services = () => {
  return (
    <Layout>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NotificationPanel />

        <ServiceDetailsContent />
      </ThemeProvider>
    </Layout>
  );
};

export default Services;
