import React, { useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import axios from 'axios';
import Layout from './Layout';
import { alpha } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  MenuItem,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Typography,
  ThemeProvider,
  createTheme,
  CssBaseline
} from '@mui/material';
import {
  Inventory,
  CategoryOutlined,
  Factory,
  Settings,
  Warehouse,
  Business,
  CalendarToday,
  ChevronLeft,
  ChevronRight
} from '@mui/icons-material';

// Steps configuration with icons
const steps = [
  { label: 'Identificação', icon: <Inventory /> },
  { label: 'Categoria e Valor', icon: <CategoryOutlined /> },
  { label: 'Fabricante', icon: <Factory /> },
  { label: 'Especificações', icon: <Settings /> },
  { label: 'Estoque', icon: <Warehouse /> },
  { label: 'Fornecedores', icon: <Business /> },
  { label: 'Datas e Observações', icon: <CalendarToday /> }
];

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#F97316',
    },
    secondary: {
      main: '#0A192F',
    },
    background: {
      default: '#0A192F',
      paper: '#112240',
    },
  },
});

const ProductRegister = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState({
    productCode: '',
    description: '',
    name: '',
    barcode: '',
    category: '',
    subcategory: '',
    unitCost: '',
    salePrice: '',
    taxes: '',
    brand: '',
    model: '',
    dimensions: '',
    material: '',
    compatibility: '',
    otherSpecs: '',
    stockQuantity: '',
    stockLocation: '',
    reorderPoint: '',
    supplier: '',
    supplierContact: '',
    paymentTerms: '',
    registrationDate: '',
    expirationDate: '',
    notes: '',
  });

  useEffect(() => {
    axios.get('https://dojo.stratusport.co/torki/marketplace/cat.json')
      .then(response => setCategories(response.data.categories))
      .catch(error => console.error('Erro ao carregar categorias:', error));
  }, []);

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    const category = categories.find(cat => cat.name === selectedCategory);
    setSubcategories(category ? category.subcategories : []);
    setProductData(prev => ({ ...prev, category: selectedCategory, subcategory: '' }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData(prev => ({ ...prev, [name]: value }));
  };

  const validateCurrentStep = () => {
    const validations = {
      0: () => productData.productCode && productData.description && productData.barcode && productData.name,
      1: () => productData.category && productData.subcategory && productData.unitCost && productData.salePrice,
      2: () => productData.brand && productData.model,
      3: () => true,
      4: () => productData.stockQuantity,
      5: () => true,
      6: () => true
    };
    return validations[activeStep]();
  };

  const handleNext = () => {
    if (validateCurrentStep()) {
      setActiveStep(prev => Math.min(prev + 1, steps.length - 1));
    } else {
      alert('Por favor, preencha todos os campos obrigatórios antes de continuar.');
    }
  };

  const handleBack = () => setActiveStep(prev => Math.max(prev - 1, 0));

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = new URLSearchParams(window.location.search).get('token');
      const sellerId = token ? jwtDecode(token).user.id : '';

      const formData = {
        ...Object.fromEntries(
          Object.entries(productData).map(([key, value]) => [key, value || ""])
        ),
        price: productData.salePrice || "",
        original_price: productData.unitCost || "",
        category_id: productData.category || "",
        seller_id: sellerId,
        subcategory_id: productData.subcategory || "",
        images: "",
      };

      await axios.post('https://dojo.stratusport.co/torki/marketplace/produtos.php', formData, {
        headers: { 'Content-Type': 'application/json' }
      });

      alert('Produto cadastrado com sucesso!');
      setProductData({});
      setActiveStep(0);
    } catch (error) {
      console.error('Erro ao cadastrar produto:', error);
      alert('Erro ao cadastrar produto. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>Identificação</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Código do Produto"
                  name="productCode"
                  value={productData.productCode}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Código de Barras"
                  name="barcode"
                  value={productData.barcode}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Nome"
                  name="name"
                  value={productData.name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Descrição Detalhada"
                  name="description"
                  value={productData.description}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={4}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Número de Série"
                  name="serial_number"
                  value={productData.serial_number}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="País de Origem"
                  name="country_of_origin"
                  value={productData.country_of_origin}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>Categoria e Valor</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  select
                  label="Categoria"
                  name="category"
                  value={productData.category}
                  onChange={handleCategoryChange}
                  fullWidth
                  required
                >
                  {categories.map((option) => (
                    <MenuItem key={option.name} value={option.name}>{option.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  label="Subcategoria"
                  name="subcategory"
                  value={productData.subcategory}
                  onChange={handleChange}
                  fullWidth
                  required
                  disabled={!subcategories.length}
                >
                  {subcategories.map((sub) => (
                    <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Custo Unitário"
                  name="unitCost"
                  value={productData.unitCost}
                  onChange={handleChange}
                  fullWidth
                  required
                  type="number"
                  InputProps={{ startAdornment: <span>R$</span> }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Preço de Venda"
                  name="salePrice"
                  value={productData.salePrice}
                  onChange={handleChange}
                  fullWidth
                  required
                  type="number"
                  InputProps={{ startAdornment: <span>R$</span> }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Impostos (%)"
                  name="taxes"
                  value={productData.taxes}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  InputProps={{
                    endAdornment: <span>%</span>,
                    inputProps: { min: 0, max: 100 }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Nível de Popularidade"
                  name="popularity"
                  value={productData.popularity}
                  onChange={handleChange}
                  type="number"
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0, max: 100 }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Nível de Criticidade"
                  name="criticality_level"
                  value={productData.criticality_level}
                  onChange={handleChange}
                  select
                  fullWidth
                >
                  <MenuItem value="low">Baixo</MenuItem>
                  <MenuItem value="medium">Médio</MenuItem>
                  <MenuItem value="high">Alto</MenuItem>
                  <MenuItem value="critical">Crítico</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>Fabricante</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Marca"
                  name="brand"
                  value={productData.brand}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Modelo"
                  name="model"
                  value={productData.model}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Certificações"
                  name="certifications"
                  value={productData.certifications}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Período de Garantia"
                  name="warranty_period"
                  value={productData.warranty_period}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        );
      case 3:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>Especificações Técnicas</Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Dimensões"
                  name="dimensions"
                  value={productData.dimensions}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Peso"
                  name="weight"
                  value={productData.weight}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Unidade de Medida"
                  name="unit_measure"
                  value={productData.unit_measure}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Dimensões da Embalagem"
                  name="package_dimensions"
                  value={productData.package_dimensions}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Material"
                  name="material"
                  value={productData.material}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Compatibilidade"
                  name="compatibility"
                  value={productData.compatibility}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Outras Especificações"
                  name="otherSpecs"
                  value={productData.otherSpecs}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </Box>
        );
      case 4:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>Estoque</Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Quantidade em Estoque"
                  name="stockQuantity"
                  value={productData.stockQuantity}
                  onChange={handleChange}
                  fullWidth
                  required
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Localização"
                  name="stockLocation"
                  value={productData.stockLocation}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Ponto de Reposição"
                  name="reorderPoint"
                  value={productData.reorderPoint}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 }
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        );
      case 5:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>Fornecedores</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Fornecedor Principal"
                  name="supplier"
                  value={productData.supplier}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Contato do Fornecedor"
                  name="supplierContact"
                  value={productData.supplierContact}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Condições de Pagamento"
                  name="paymentTerms"
                  value={productData.paymentTerms}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={2}
                />
              </Grid>
            </Grid>
          </Box>
        );
      case 6:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>Datas e Observações</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Data de Cadastro"
                  name="registrationDate"
                  type="date"
                  value={productData.registrationDate}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Data de Validade"
                  name="expirationDate"
                  type="date"
                  value={productData.expirationDate}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Observações"
                  name="notes"
                  value={productData.notes}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Atributos Customizados"
                  name="custom_attributes"
                  value={typeof productData.custom_attributes === 'object' ? JSON.stringify(productData.custom_attributes) : productData.custom_attributes}
                  onChange={handleChange}
                  helperText="Insira atributos em formato JSON: {'chave': 'valor'}"
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </Box>
        );
      default:
        return null;
    }
  };
  
  

  return (
    <Layout>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            minHeight: '100vh',
            background: `linear-gradient(135deg, ${alpha('#0A192F', 0.95)} 0%, ${alpha('#112240', 0.95)} 100%)`,
            py: 4,
            px: 2
          }}
        >
          <Container maxWidth="lg">
            <Card
              sx={{
                background: alpha('#112240', 0.85),
                backdropFilter: 'blur(10px)',
                borderRadius: 2,
                border: '1px solid rgba(255, 255, 255, 0.05)',
                mb: 4
              }}
            >
              <CardContent>
                <Typography variant="h4" align="center" gutterBottom color="primary" sx={{ mb: 4 }}>
                  Cadastro de Produto
                </Typography>
                
                <Stepper 
                  activeStep={activeStep} 
                  alternativeLabel
                  sx={{
                    mb: 4,
                    '& .MuiStepLabel-root .Mui-completed': {
                      color: theme.palette.primary.main
                    },
                    '& .MuiStepLabel-label': {
                      color: '#E0E0E0'
                    }
                  }}
                >
                  {steps.map(({ label, icon }) => (
                    <Step key={label}>
                      <StepLabel 
                        StepIconComponent={() => (
                          <Box
                            sx={{
                              width: 40,
                              height: 40,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: '50%',
                              bgcolor: alpha(theme.palette.primary.main, 0.1),
                              color: theme.palette.primary.main
                            }}
                          >
                            {icon}
                          </Box>
                        )}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <form onSubmit={handleSubmit}>
                  {/* Form steps rendered here - keeping existing step content */}
                  {renderStepContent(activeStep)}

                  <Box sx={{ mt: 4 }}>
                    {/* Navigation buttons */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        startIcon={<ChevronLeft />}
                        sx={{
                          color: '#E0E0E0',
                          '&:hover': {
                            bgcolor: alpha('#F97316', 0.1)
                          }
                        }}
                      >
                        Voltar
                      </Button>
                      {activeStep === steps.length - 1 ? (
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={loading}
                          sx={{
                            px: 4,
                            '&:hover': {
                              transform: 'translateY(-2px)',
                              transition: 'transform 0.2s'
                            }
                          }}
                        >
                          {loading ? 'Finalizando...' : 'Finalizar Cadastro'}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          endIcon={<ChevronRight />}
                          sx={{
                            px: 4,
                            '&:hover': {
                              transform: 'translateY(-2px)',
                              transition: 'transform 0.2s'
                            }
                          }}
                        >
                          Próximo
                        </Button>
                      )}
                    </Box>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Container>
        </Box>
      </ThemeProvider>
    </Layout>
  );
};

export default ProductRegister;
