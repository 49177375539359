export function customJwtDecode(token) {
  console.log("Token recebido para decodificação:", token);
  
  if (!token || typeof token !== 'string') {
    console.error('Token inválido ou ausente.');
    return null;
  }

  try {
    // Divide o token em header, payload e assinatura
    const parts = token.split('.');
    if (parts.length !== 3) {
      console.error('Token JWT malformado. Deve conter três partes.');
      return null;
    }

    const [headerB64, payloadB64] = parts;

    // Decodifica a parte do payload usando base64
    const decodedPayload = JSON.parse(
      atob(payloadB64.replace(/-/g, '+').replace(/_/g, '/'))
    );

    console.log("Payload decodificado:", decodedPayload);
    return decodedPayload;
  } catch (error) {
    console.error('Erro ao decodificar o JWT:', error);
    return null;
  }
}
