import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./BudgetDetails.css";
import Header from "./components/Header";
import Footer from "./components/Footer";

const BudgetDetails = () => {
  const { budgetId } = useParams();
  const [budgetDetails, setBudgetDetails] = useState(null);

  useEffect(() => {
    const fetchBudgetDetails = async () => {
      try {
        const response = await fetch(
          `https://teste.stratusport.co/festafacil/budget-details.php?id=${budgetId}`,
          {
            method: "GET",
          }
        );

        const result = await response.json();

        if (response.ok) {
          setBudgetDetails(result);
        } else {
          alert("Erro ao carregar detalhes do orçamento.");
        }
      } catch (error) {
        console.error("Erro ao buscar detalhes do orçamento:", error);
        alert("Erro ao buscar detalhes do orçamento.");
      }
    };

    fetchBudgetDetails();
  }, [budgetId]);

  return (
    <div className="budget-details-container">
      <Header />
      <div className="budget-details-card">
        <h2 className="card-title">Detalhes do Orçamento</h2>
        {budgetDetails ? (
          <div className="budget-details">
            <div className="budget-summary">
              <div className="summary-item">
                <span className="icon">📅</span>
                <p>
                  <strong>Data do Evento:</strong> {budgetDetails.event_date}
                </p>
              </div>
              <div className="summary-item">
                <span className="icon">📍</span>
                <p>
                  <strong>Localidade:</strong> {budgetDetails.location}
                </p>
              </div>
              <div className="summary-item">
                <span className="icon">⏳</span>
                <p>
                  <strong>Duração:</strong> {budgetDetails.duration} horas
                </p>
              </div>
              <div className="summary-item">
                <span className="icon">👥</span>
                <p>
                  <strong>Total de Convidados:</strong>{" "}
                  {budgetDetails.amount_people}
                </p>
              </div>
            </div>

            <div className="total-price-wrapper">
              <p>
                <strong>💰 Valor Total:</strong>{" "}
                <span className="total-price">
                  R$ {budgetDetails.total_price}
                </span>
              </p>
            </div>

            <h3 className="section-title">Itens do Orçamento</h3>
            <div className="items-section">
              {Array.isArray(budgetDetails.items) &&
              budgetDetails.items.length > 0 ? (
                budgetDetails.items.map((item) => (
                  <div key={item.id} className="item-card">
                    <p>
                      <strong>{item.name}</strong>
                    </p>
                    <p>Quantidade: {item.quantity}</p>
                    <p>
                      <strong>Preço Total:</strong>{" "}
                      <span className="item-total-price">
                        R$ {item.total_item_price}
                      </span>
                    </p>

                    {Array.isArray(item.quotations) &&
                      item.quotations.length > 0 && (
                        <div className="quotations-section">
                          <h4>Cotações dos Fornecedores</h4>
                          <div className="quotations-list">
                            {item.quotations.map((quotation) => (
                              <div
                                key={quotation.id}
                                className="quotation-card"
                              >
                                <div className="quotation-info">
                                  <h5>{quotation.supplier_name}</h5>
                                  <p>
                                    <strong>Preço Cotado:</strong>{" "}
                                    <span className="quoted-price">
                                      R$ {quotation.quoted_price}
                                    </span>
                                  </p>
                                  <p>{quotation.conditions}</p>
                                  {quotation.notes && (
                                    <p>
                                      <em>{quotation.notes}</em>
                                    </p>
                                  )}
                                </div>
                                <div className="quotation-actions">
                                  <button className="btn-accept">
                                    Aceitar
                                  </button>
                                  <button className="btn-contact">
                                    Contatar
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="powered-by">
                            <p>
                              Powered by <strong>FestaFácil</strong>
                            </p>
                          </div>
                        </div>
                      )}
                  </div>
                ))
              ) : (
                <p>Nenhum item encontrado.</p>
              )}
            </div>
          </div>
        ) : (
          <p>Carregando detalhes...</p>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default BudgetDetails;
