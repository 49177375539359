import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { customJwtDecode } from '../../utils/jwtDecode';



const SupplierList = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [groupedSuppliers, setGroupedSuppliers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const bffUrl = `${process.env.REACT_APP_API_BASE_URL}/prestadores/fornecedor.php`;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (!token) {
      setError('Token não encontrado.');
      setLoading(false);
      return;
    }

    try {
      const decodedToken = customJwtDecode(token);
      const userId = decodedToken.user.id;

      if (!userId) {
        throw new Error('ID do usuário não encontrado no token.');
      }

      fetch(`${bffUrl}?action=get_supplier&user_id=${userId}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Erro ao buscar fornecedores.');
          }
          return response.json();
        })
        .then(data => {
          setSuppliers(data);
          groupByServiceType(data);
          setLoading(false);
        })
        .catch(error => {
          setError(error.message);
          setLoading(false);
        });
    } catch (e) {
      setError('Erro ao decodificar o token: ' + e.message);
      setLoading(false);
    }
  }, [location]);

  const groupByServiceType = (suppliers) => {
    if (Array.isArray(suppliers) && suppliers.length > 0) {
      const grouped = suppliers.reduce((acc, supplier) => {
        const { service_type = 'N/A' } = supplier;
        if (!acc[service_type]) {
          acc[service_type] = [];
        }
        acc[service_type].push(supplier);
        return acc;
      }, {});
      setGroupedSuppliers(grouped);
    } else {
      // Trata o caso em que o `suppliers` não é um array válido
      setGroupedSuppliers({
        'N/A': []
      });
    }
  };

  const formatAddress = (lat, lon) => {
    const latitude = parseFloat(lat);
    const longitude = parseFloat(lon);
    return `Lat: ${latitude.toFixed(4)}, Long: ${longitude.toFixed(4)}`;
  };

  const viewSupplierDetails = (supplierId) => {
    const queryParams = new URLSearchParams(location.search);
    navigate(`/supplier/${supplierId}?token=${queryParams.get('token')}`);
  };

  const deleteSupplier = (supplierId) => {
    if (!window.confirm('Tem certeza que deseja excluir este fornecedor?')) {
      return;
    }

    fetch(`${bffUrl}?action=delete&id=${supplierId}`, {
      method: 'DELETE',
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setSuppliers(prevSuppliers => prevSuppliers.filter(s => s.id !== supplierId));
          setError('');
        } else {
          setError('Erro ao excluir o fornecedor: ' + data.message);
        }
      })
      .catch(error => {
        setError('Erro ao excluir o fornecedor: ' + error.message);
      });
  };

  const handleAddSupplier = () => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    navigate(`/cadastro?token=${token}`);
  };

  if (loading) {
    return (
      <div className="page-container">
        <div className="loading-message">Carregando fornecedores...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="page-container">
        <div className="error-message">{error}</div>
      </div>
    );
  }

  if (!Object.keys(groupedSuppliers).length) {
    return (
      <div className="page-container">
        <div className="no-data-message">Nenhum fornecedor encontrado.</div>
      </div>
    );
  }

  return (
    <div className="page-container">
      
      <div className="supplier-list">
        <h2 className="page-title">Lista de Fornecedores</h2>
        
        {/* Botão para adicionar fornecedor */}
        <button onClick={handleAddSupplier} className="add-supplier-button">
          Adicionar Fornecedor
        </button>

        {Object.entries(groupedSuppliers).map(([serviceType, suppliers]) => (
          <div key={serviceType} className="supplier-category">
            <h3 className="category-title">{serviceType}</h3>
            <table className="supplier-table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Endereço</th>
                  <th>Avaliação</th>
                  <th className="actions-column">Ações</th>
                </tr>
              </thead>
              <tbody>
                {suppliers.map((supplier) => (
                  <tr key={supplier.id}>
                    <td>{supplier.name}</td>
                    <td>{formatAddress(supplier.location_lat, supplier.location_long)}</td>
                    <td>⭐ {supplier.rating} ({supplier.reviews} avaliações)</td>
                    <td className="actions-column">
                      <div className="action-buttons">
                        <button
                          onClick={() => viewSupplierDetails(supplier.id)}
                          className="view-details-button"
                        >
                          Ver Detalhes
                        </button>
                        <button
                          onClick={() => deleteSupplier(supplier.id)}
                          className="delete-button"
                        >
                          Excluir
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
      
    </div>
  );
};

export default SupplierList;
