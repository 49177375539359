// Header.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const Header = ({ onNewTicketClick }) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
    <Typography variant="h4" color="text.primary">
      Welcome! John Smith
    </Typography>
    <Button variant="contained" color="primary" onClick={onNewTicketClick}>
      New Ticket
    </Button>
  </Box>
);

export default Header;
